.logo {
  svg {
    height: 4em;
  }
}

.logo.logo-white {
  svg {
    height: 2.25em;
    padding: 5px 0 0 20px;
    .cls-1 {
      fill: #fff;
    }
  }
}
