/* Angular Material  */

md-dialog.md-default-theme, md-dialog {
  border-radius: 0;
}

md-input-container {
  text-align: start;
}

// Backdrop
md-backdrop {
  z-index: 70 !important;
}

// Toolbar
md-toolbar {
  height: 40px;
  min-height: 40px;
}

// Bottom Sheet
md-bottom-sheet.md-grid {
  z-index: 999;
}

// Checkbox
md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: rgba(95, 95, 95, 0.85);
}

md-checkbox.grid-checkbox > .md-container {
    top: 85%;
    left: 7px;
}

// Dialog
md-dialog {
    border-radius: 0;
}

// Input
md-input-container.grid-input {
    padding: 0;
    margin: 0;
    top: 12px;
}

// Sidenav
md-sidenav {
  z-index: 70 !important;
}

// Switch
md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
    background: rgb(63, 81, 181);
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
    background-color: rgba(63, 81, 181, 0.5);
}

md-switch.md-default-theme.md-checked .md-ink-ripple, md-switch.md-checked .md-ink-ripple {
    color: rgb(63, 81, 181);
}

// Tabs
md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: rgb(63, 81, 181);
    background: rgb(63, 81, 181);
}

// Toast
md-toast {
  z-index: 99999 !important;
}

// Tooltip
md-tooltip {
  border-radius: 0;
}

// Whiteframe
md-whiteframe {
    background: #fff;
}
