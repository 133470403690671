#print {

  .right-border {
    border-right: 1px solid #ddd;
  }

  .print-container {
    margin: 20px;
    padding: 80px 40px;
    max-width: 1700px;
  }
  .chart {
    width: 50%;
  }
  .bill-contact {
    text-align: right;
    margin: 0;
  }
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: fixed;
  }
  table.full-width {
    width: 100%;
  }
  table caption {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid #ddd;
    background: #efefef;
    border-bottom: none;
    margin: 0;
    padding: 5px;
  }
  table tr {
    background: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }
  table th,
  table td {
    padding: .625em;
    text-align: center;
  }
  table.side-label tr th {
    border-right: 1px solid #ddd;
  }
  table tbody td {
    background: #fff;
  }
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 960px) {
    .bill-contact {
      text-align: left;
    }
    table.side-label, .chart {
      width: 100%;
    }
    table.fluid {
      border: 0;
      caption {
        font-size: 1.3em;
      }
      thead {
        display: none;
      }
      tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
      }
      td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
      }
      td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      td:last-child {
        border-bottom: 0;
      }
    }
  }
}

#pdfexport {
  ul {
    list-style: none;
  }
  ul:first-child {
    padding-left: 0;
  }
}

// chartist {
//   svg {
//     width: 100%;
//   }
// }
