#invoice {
    padding: 0;
    overflow: auto;

    .invoice-container {
        padding: 64px;

        .card {
            width: 1020px;
            min-width: 1020px;
            max-width: 1020px;
            padding: 88px;
            overflow: hidden;
            background: #FFFFFF;

            .header {

                .ids {
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.54);

                    .detail {
                        width: 160px;
                    }

                    .seller {
                        margin-bottom: 80px;

                        .logo {
                            width: 156px;
                            height: 156px;
                            font-size: 115px;
                        }
                    }

                    .client {

                        .label {
                            width: 156px;
                            font-size: 24px;
                            font-weight: 300;
                        }
                    }

                    .divider {
                        width: 1px;
                        margin: 0 48px;
                        background-color: rgba(0, 0, 0, 0.12);
                        height: 144px;
                    }
                }

                .summary {
                    font-size: 15px;

                    .label {
                        color: rgba(0, 0, 0, 0.54);
                        text-align: right;
                        padding-right: 16px;
                    }

                    .value {
                        color: rgba(0, 0, 0, 1);
                    }

                    .code {
                        font-size: 35px;
                        font-weight: 300;

                        td {
                            padding-bottom: 32px;
                        }
                    }
                }
            }

            .content {

                .invoice-table {
                    margin-top: 96px;
                    font-size: 15px;

                    .title {
                        font-size: 17px;
                    }

                    .detail {
                        margin-top: 8px;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.54);
                        max-width: 360px;
                    }
                }

                .invoice-table-footer {
                    margin: 32px 0 96px 0;

                    tr {

                        td {
                            text-align: right;
                            font-size: 17px;
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.54);
                            border-bottom: none;
                            padding: 8px 8px;

                            &:first-child {
                                text-align: left;
                            }
                        }

                        &.discount {

                            td {
                                padding-bottom: 32px;
                            }
                        }

                        &.total {

                            td {
                                padding: 32px 8px;
                                border-top: 1px solid rgba(0, 0, 0, 0.12);
                                font-size: 35px;
                                font-weight: 300;
                                color: rgba(0, 0, 0, 1);
                            }
                        }
                    }
                }
            }

            .footer {

                .note {
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 24px;
                }

                // IE10 fix
                .logo, .small-note {
                    -ms-flex: 0 1 auto;
                }

                .logo {
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    font-size: 17px;
                    font-weight: 500;
                    margin-right: 24px;
                    border-radius: 2px;
                    overflow: hidden;
                }

                .small-note {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                    line-height: 18px;
                }
            }
        }
    }
}

/* PRINT STYLES */
@media print {

    /* Invoice Specific Styles */
    #invoice {

        .invoice-container {
            padding: 0;

            .card {
                width: 100%;
                min-width: 0;
                background: none;
                padding: 0;
                box-shadow: none;

                .header {

                    .ids {

                        .detail {
                            width: 120pt;
                        }

                        .seller {
                            margin-bottom: 8pt;

                            .logo {
                                width: 60pt;
                                height: 60pt;
                                font-size: 40pt;
                            }
                        }

                        .client {

                            .label {
                                width: 60pt;
                                font-size: 16pt;
                            }
                        }

                        .divider {
                            margin: 0 12pt;
                            height: 100pt;
                        }
                    }

                    .summary {
                        font-size: 10pt;

                        .code {
                            font-size: 18pt;

                            td {
                                padding-bottom: 10pt;
                            }
                        }
                    }
                }

                .content {

                    .invoice-table {
                        margin-top: 16pt;

                        thead {

                            tr {

                                th {
                                    font-size: 10pt;
                                    max-width: 60pt;

                                    &:first-child {
                                        padding-left: 0;
                                    }

                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }

                        tbody {

                            tr {

                                td {

                                    &:first-child {
                                        padding-left: 0;
                                    }

                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }

                        .title {
                            font-size: 10pt;
                        }

                        .detail {
                            margin-top: 4pt;
                            font-size: 9pt;
                            max-width: none;
                        }
                    }

                    .invoice-table-footer {
                        margin: 16pt 0;

                        tr {

                            td {
                                font-size: 13pt;
                                padding: 4pt 4pt;

                                &:first-child {
                                    text-align: left;
                                    padding-left: 0;
                                }

                                &:last-child {
                                    padding-right: 0;
                                }
                            }

                            &.discount {

                                td {
                                    padding-bottom: 16pt;
                                }
                            }

                            &.total {

                                td {
                                    padding: 16pt 4pt 0 4pt;
                                    font-size: 16pt;

                                    &:first-child {
                                        padding-left: 0;
                                    }

                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .footer {

                    .note {
                        font-size: 10pt;
                        margin-bottom: 8pt;
                    }

                    .logo {
                        font-size: 14pt;
                        margin-right: 8pt;
                    }

                    .small-note {
                        font-size: 8pt;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
