﻿html, body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    overflow: hidden;
    background-color: #eee;
}

ul {
  list-style-type: none;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.invisible {
  color: rgba(0, 0, 0, 0);
}

.pin-bar {
  background-color: rgba(87, 91, 101, 0.5); 
  width: 100%; 
  color: #fff; 
  padding: 5px 0; 
  cursor: pointer;
}

.disable-clean {
  background: none;
  background-image: none !important;
  border-width: 0 0 1px 0 !important;
  border-style: solid !important;
  color: rgba(0,0,0,0.87) !important;
  border-color: rgba(0,0,0,0.12) !important;
}

md-divider {
  border-top-width: 2px;
}

.decimal-align {
  width: 20%;
  text-align: right;
}

.decimal-align-bill {
  width: 70%;
  text-align: right;
}

@media screen and (max-width: 960px) {
  .decimal-align {
    width: 50%;
  }
  .decimal-align-bill {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .decimal-align {
    width: 75%;
  }
}

@media screen and (max-width: 580px) {
  .decimal-align {
    width: 100%;
  }
}

.notice-message {
  font-size: 1.2em;
  padding-bottom: 20px;
}

.small-font {
  font-size: 0.8em;
}

.menu-button {
  padding: 0 30px 0 30px !important;
  i {
    margin-top: 10px;
    margin-right: 5px;
  }
}

.alert-menu {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.flex-locked-grid {
  .k-grid-header {
    padding-right: 0 !important;
  }
  .k-grid-content {
    overflow-y: auto;
  }
  .k-grid-header-locked, .k-grid-content-locked {
    width: 10% !important;
    min-width: 175px;
  }

  .k-grid-header-wrap table, .k-grid-content table {
    width: 100% !important;
    min-width: 700px;
  }
}

footer {
    padding: 0 20px;
}

.k-editor .k-editable-area {
  padding: 20px;
}

//angular-loading-bar
#loading-bar .bar {
  background: rgba(255, 255, 255, 0.4);
  height: 5px;
}


.md-light-bg {
  background-color: #888;
}

.md-primary-bg {
  background-color: rgb(45, 50, 62);
}

.md-accent-bg {
  background-color: rgb(63, 81, 181);
}

#main-content {
  background-color: #fff;
}

.inner-content {
    padding: 25px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Necessary for Angular's ng-cloak to work
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
