html, body {
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
}

[tabindex='-1']:focus {
  outline: none;
}

.inset {
  padding: 10px;
}

button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="reset"][disabled],
input[type="submit"][disabled],
html input[type="button"][disabled],
button[disabled] {
  cursor: default;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px;
}

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none;
}

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%;
}

.md-ripple.md-ripple-placed {
  transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-ripple.md-ripple-scaled {
  transform: translate(-50%, -50%) scale(1);
}

.md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
  opacity: 0.20;
}

.md-padding {
  padding: 8px;
}

.md-margin {
  margin: 8px;
}

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.md-scroll-mask > .md-scroll-mask-bar {
  display: block;
  position: absolute;
  background-color: #fafafa;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 65;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3);
}

@media (min-width: 960px) {
  .md-padding {
    padding: 16px;
  }
}

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px;
}

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px;
}

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px;
}

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px;
}

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px;
}

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px;
}

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em;
}

.md-button {
  letter-spacing: 0.010em;
}

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

select,
button,
textarea,
input {
  font-size: 100%;
}

@keyframes md-autocomplete-list-out {
  0% {
    animation-timing-function: linear;
  }
  50% {
    opacity: 0;
    height: 40px;
    animation-timing-function: ease-in;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

@keyframes md-autocomplete-list-in {
  0% {
    opacity: 0;
    height: 0;
    animation-timing-function: ease-out;
  }
  50% {
    opacity: 0;
    height: 40px;
  }
  100% {
    opacity: 1;
    height: 40px;
  }
}

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px;
}

md-autocomplete[disabled] input {
  cursor: default;
}

md-autocomplete[md-floating-label] {
  border-radius: 0;
  background: transparent;
  height: auto;
}

md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 26px;
}

md-autocomplete[md-floating-label] md-input-container.md-input-has-messages {
  padding-bottom: 2px;
}

md-autocomplete[md-floating-label] md-autocomplete-wrap {
  height: auto;
}

md-autocomplete[md-floating-label] button {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
}

md-autocomplete md-autocomplete-wrap {
  display: block;
  position: relative;
  overflow: visible;
  height: 40px;
}

md-autocomplete md-autocomplete-wrap.md-menu-showing {
  z-index: 51;
}

md-autocomplete md-autocomplete-wrap md-progress-linear {
  position: absolute;
  bottom: -2px;
  left: 0;
}

md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: 40px;
  right: 2px;
  left: 2px;
  width: auto;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: none;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
  transition: none;
  height: 3px;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
  transition: opacity 0.15s linear;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
  opacity: 1;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
  transition: opacity 0.15s linear;
}

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
  opacity: 0;
}

md-autocomplete input:not(.md-input) {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
}

md-autocomplete input:not(.md-input)::-ms-clear {
  display: none;
}

md-autocomplete button {
  position: relative;
  line-height: 20px;
  text-align: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  background: transparent;
  margin: auto 5px;
}

md-autocomplete button:after {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-autocomplete button:focus {
  outline: none;
}

md-autocomplete button:focus:after {
  transform: scale(1);
  opacity: 1;
}

md-autocomplete button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0.9);
}

md-autocomplete button md-icon path {
  stroke-width: 0;
}

md-autocomplete button.ng-enter {
  transform: scale(0);
  transition: transform 0.15s ease-out;
}

md-autocomplete button.ng-enter.ng-enter-active {
  transform: scale(1);
}

md-autocomplete button.ng-leave {
  transition: transform 0.15s ease-out;
}

md-autocomplete button.ng-leave.ng-leave-active {
  transform: scale(0);
}

@media screen and (-ms-high-contrast: active) {
  md-autocomplete input {
    border: 1px solid #fff;
  }
  md-autocomplete li:focus {
    color: #fff;
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  height: 225.5px;
  max-height: 225.5px;
  z-index: 100;
}

.md-virtual-repeat-container.md-not-found {
  height: 48px;
}

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0;
}

.md-autocomplete-suggestions li {
  font-size: 14px;
  overflow: hidden;
  padding: 0 15px;
  line-height: 48px;
  height: 48px;
  transition: background 0.15s linear;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.md-autocomplete-suggestions li:focus {
  outline: none;
}

.md-autocomplete-suggestions li:not(.md-not-found-wrapper) {
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff;
  }
}

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
}

md-backdrop.md-menu-backdrop {
  position: fixed !important;
  z-index: 99;
}

md-backdrop.md-select-backdrop {
  z-index: 81;
  transition-duration: 0;
}

md-backdrop.md-dialog-backdrop {
  z-index: 79;
}

md-backdrop.md-bottom-sheet-backdrop {
  z-index: 69;
}

md-backdrop.md-sidenav-backdrop {
  z-index: 59;
}

md-backdrop.md-click-catcher {
  position: absolute;
}

md-backdrop.md-opaque {
  opacity: .48;
}

md-backdrop.md-opaque.ng-enter {
  opacity: 0;
}

md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
  opacity: .48;
}

md-backdrop.md-opaque.ng-leave {
  opacity: .48;
  transition: opacity 400ms;
}

md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
  opacity: 0;
}

button.md-button::-moz-focus-inner {
  border: 0;
}

.md-button {
  border-radius: 3px;
  box-sizing: border-box;
  color: currentColor;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  background: transparent;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-button:focus {
  outline: none;
}

.md-button:hover, .md-button:focus {
  text-decoration: none;
}

.md-button.ng-hide, .md-button.ng-leave {
  transition: none;
}

.md-button.md-cornered {
  border-radius: 0;
}

.md-button.md-icon {
  padding: 0;
  background: none;
}

.md-button.md-raised:not([disabled]) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-button.md-icon-button {
  margin: 0 6px;
  height: 40px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
}

.md-button.md-icon-button .md-ripple-container {
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.md-button.md-fab {
  z-index: 20;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-property: background-color, box-shadow, transform;
}

.md-button.md-fab.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}

.md-button.md-fab.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}

.md-button.md-fab.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}

.md-button.md-fab.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}

.md-button.md-fab .md-ripple-container {
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.md-button.md-fab.md-mini {
  line-height: 40px;
  width: 40px;
  height: 40px;
}

.md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
  transition: none;
}

.md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.md-button .md-ripple-container {
  border-radius: 3px;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0);
}

.md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
.md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
.md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
  transform: translate3d(0, 41px, 0);
}

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0);
}

.md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
.md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
.md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
  transform: translate3d(0, -43px, 0);
}

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%;
}

.md-button-group > .md-button {
  flex: 1;
  display: block;
  overflow: hidden;
  width: 0;
  border-width: 1px 0px 1px 1px;
  border-radius: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.md-button-group > .md-button:first-child {
  border-radius: 2px 0px 0px 2px;
}

.md-button-group > .md-button:last-child {
  border-right-width: 1px;
  border-radius: 0px 2px 2px 0px;
}

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff;
  }
}

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform;
}

md-bottom-sheet.md-has-header {
  padding-top: 0;
}

md-bottom-sheet.ng-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

md-bottom-sheet.ng-enter-active {
  opacity: 1;
  display: block;
  transform: translate3d(0, 80px, 0) !important;
}

md-bottom-sheet.ng-leave-active {
  transform: translate3d(0, 100%, 0) !important;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-bottom-sheet .md-subheader {
  background-color: transparent;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  line-height: 56px;
  padding: 0;
  white-space: nowrap;
}

md-bottom-sheet md-inline-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  fill: #444;
}

md-bottom-sheet md-list-item {
  display: flex;
  outline: none;
}

md-bottom-sheet md-list-item:hover {
  cursor: pointer;
}

md-bottom-sheet.md-list md-list-item {
  padding: 0;
  align-items: center;
  height: 48px;
}

md-bottom-sheet.md-list md-list-item div.md-icon-container {
  display: inline-block;
  height: 24px;
  margin-right: 32px;
}

md-bottom-sheet.md-grid {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0;
}

md-bottom-sheet.md-grid md-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all 0.5s;
  align-items: center;
}

md-bottom-sheet.md-grid md-list-item {
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
  height: 96px;
  margin-top: 8px;
  margin-bottom: 8px;
  /* Mixin for how many grid items to show per row */
}

@media screen and (max-width: 960px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 33.33333%;
    max-width: 33.33333%;
  }
  md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
    align-items: flex-start;
  }
  md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
    align-items: flex-end;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 16.66667%;
    max-width: 16.66667%;
  }
}

@media screen and (min-width: 1920px) {
  md-bottom-sheet.md-grid md-list-item {
    flex: 1 1 14.28571%;
    max-width: 14.28571%;
  }
}

md-bottom-sheet.md-grid md-list-item .md-list-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  padding-bottom: 16px;
}

md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}

md-bottom-sheet.md-grid md-list-item .md-icon-container {
  display: inline-block;
  box-sizing: border-box;
  height: 48px;
  width: 48px;
  margin: 0 0;
}

md-bottom-sheet.md-grid md-list-item .md-grid-text {
  font-weight: 400;
  line-height: 16px;
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
  width: 64px;
  text-align: center;
  text-transform: none;
  padding-top: 8px;
}

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff;
  }
}

md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

md-card md-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
}

md-card md-card-header:first-child md-card-avatar {
  margin-right: 12px;
}

md-card md-card-header:last-child md-card-avatar {
  margin-left: 12px;
}

md-card md-card-header md-card-avatar {
  width: 40px;
  height: 40px;
}

md-card md-card-header md-card-avatar .md-user-avatar,
md-card md-card-header md-card-avatar md-icon {
  border-radius: 50%;
}

md-card md-card-header md-card-avatar md-icon {
  padding: 8px;
}

md-card md-card-header md-card-avatar + md-card-header-text {
  max-height: 40px;
}

md-card md-card-header md-card-avatar + md-card-header-text .md-title {
  font-size: 14px;
}

md-card md-card-header md-card-header-text {
  display: flex;
  flex: 1;
  flex-direction: column;
}

md-card md-card-header md-card-header-text .md-subhead {
  font-size: 14px;
}

md-card > img,
md-card > :not(md-card-content) img {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
}

md-card md-card-title {
  padding: 24px 16px 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
}

md-card md-card-title + md-card-content {
  padding-top: 0;
}

md-card md-card-title md-card-title-text {
  flex: 1;
  flex-direction: column;
  display: flex;
}

md-card md-card-title md-card-title-text .md-subhead {
  padding-top: 0;
  font-size: 14px;
}

md-card md-card-title md-card-title-text:only-child .md-subhead {
  padding-top: 12px;
}

md-card md-card-title md-card-title-media {
  margin-top: -8px;
}

md-card md-card-title md-card-title-media .md-media-sm {
  height: 80px;
  width: 80px;
}

md-card md-card-title md-card-title-media .md-media-md {
  height: 112px;
  width: 112px;
}

md-card md-card-title md-card-title-media .md-media-lg {
  height: 152px;
  width: 152px;
}

md-card md-card-content {
  display: block;
  padding: 16px;
}

md-card md-card-content > p:first-child {
  margin-top: 0;
}

md-card md-card-content > p:last-child {
  margin-bottom: 0;
}

md-card md-card-content .md-media-xl {
  height: 240px;
  width: 240px;
}

md-card .md-actions, md-card md-card-actions {
  margin: 8px;
}

md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
  margin: 2px 0;
}

md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
  margin-top: 0;
}

md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
  margin-bottom: 0;
}

md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
  margin-top: 6px;
  margin-bottom: 6px;
}

md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
  margin: 0 4px;
}

md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
  margin-left: 0;
}

md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
  margin-right: 0;
}

md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
  margin-left: 6px;
  margin-right: 6px;
}

md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
  margin-left: 12px;
}

md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
  margin-right: 12px;
}

md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

md-card md-card-footer {
  margin-top: auto;
  padding: 16px;
}

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff;
  }
}

.md-inline-form md-checkbox {
  margin: 19px 0 18px;
}

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: inherit;
  margin-right: 16px;
}

html[dir=rtl] md-checkbox {
  margin-left: 16px;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox {
  margin-left: 16px;
  unicode-bidi: embed;
}

md-checkbox bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-checkbox {
  margin-right: inherit;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox {
  margin-right: inherit;
  unicode-bidi: embed;
}

md-checkbox bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-checkbox:last-of-type {
  margin-left: inherit;
  margin-right: 0;
}

html[dir=rtl] md-checkbox:last-of-type {
  margin-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox:last-of-type {
  margin-left: 0;
  unicode-bidi: embed;
}

md-checkbox:last-of-type bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox:last-of-type bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-checkbox:last-of-type {
  margin-right: inherit;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox:last-of-type {
  margin-right: inherit;
  unicode-bidi: embed;
}

md-checkbox:last-of-type bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox:last-of-type bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-checkbox.md-focused:not([disabled]) .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
  background-color: rgba(0, 0, 0, 0.12);
}

md-checkbox .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
}

html[dir=rtl] md-checkbox .md-container {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox .md-container {
  left: auto;
  unicode-bidi: embed;
}

md-checkbox .md-container bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox .md-container bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-checkbox .md-container {
  right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox .md-container {
  right: 0;
  unicode-bidi: embed;
}

md-checkbox .md-container bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox .md-container bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-checkbox .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}

md-checkbox .md-container:after {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

md-checkbox .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}

md-checkbox.md-align-top-left > div.md-container {
  top: 12px;
}

md-checkbox .md-icon {
  box-sizing: border-box;
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
}

md-checkbox.md-checked .md-icon {
  border: none;
}

md-checkbox[disabled] {
  cursor: default;
}

md-checkbox.md-checked .md-icon:after {
  box-sizing: border-box;
  transform: rotate(45deg);
  position: absolute;
  left: 6.66667px;
  top: 2.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: '';
}

md-checkbox .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  user-select: text;
  margin-left: 30px;
  margin-right: 0;
}

html[dir=rtl] md-checkbox .md-label {
  margin-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox .md-label {
  margin-left: 0;
  unicode-bidi: embed;
}

md-checkbox .md-label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox .md-label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-checkbox .md-label {
  margin-right: 30px;
  unicode-bidi: embed;
}

body[dir=rtl] md-checkbox .md-label {
  margin-right: 30px;
  unicode-bidi: embed;
}

md-checkbox .md-label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-checkbox .md-label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

md-content[md-scroll-y] {
  overflow-y: auto;
  overflow-x: hidden;
}

md-content[md-scroll-x] {
  overflow-x: auto;
  overflow-y: hidden;
}

md-content.autoScroll {
  -webkit-overflow-scrolling: auto;
}

.md-contact-chips .md-chips .md-chip {
  padding: 0 25px 0 0;
}

.md-contact-chips .md-chips .md-chip .md-contact-avatar {
  float: left;
}

.md-contact-chips .md-chips .md-chip .md-contact-avatar img {
  height: 32px;
  border-radius: 16px;
}

.md-contact-chips .md-chips .md-chip .md-contact-name {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
}

.md-contact-suggestion {
  height: 56px;
}

.md-contact-suggestion img {
  height: 40px;
  border-radius: 20px;
  margin-top: 8px;
}

.md-contact-suggestion .md-contact-name {
  margin-left: 8px;
  width: 120px;
}

.md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-contact-chips-suggestions li {
  height: 100%;
}

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle;
}

.md-chips:after {
  content: '';
  display: table;
  clear: both;
}

.md-chips:not(.md-readonly) {
  cursor: text;
}

.md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
  padding-right: 22px;
}

.md-chips:not(.md-readonly) .md-chip:not(.md-readonly) .md-chip-content {
  padding-right: 4px;
}

.md-chips .md-chip {
  cursor: default;
  border-radius: 16px;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: 8px 8px 0 0;
  padding: 0 12px 0 12px;
  float: left;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}

.md-chips .md-chip .md-chip-content {
  display: block;
  float: left;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-chips .md-chip .md-chip-content:focus {
  outline: none;
}

.md-chips .md-chip .md-chip-remove-container {
  position: absolute;
  right: 0;
  line-height: 22px;
}

.md-chips .md-chip .md-chip-remove {
  text-align: center;
  width: 32px;
  height: 32px;
  min-width: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  position: relative;
}

.md-chips .md-chip .md-chip-remove md-icon {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.md-chips .md-chip-input-container {
  display: block;
  line-height: 32px;
  margin: 8px 8px 0 0;
  padding: 0;
  float: left;
}

.md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type="email"], .md-chips .md-chip-input-container input[type="number"], .md-chips .md-chip-input-container input[type="tel"], .md-chips .md-chip-input-container input[type="url"], .md-chips .md-chip-input-container input[type="text"] {
  border: 0;
  height: 32px;
  line-height: 32px;
  padding: 0;
}

.md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type="email"]:focus, .md-chips .md-chip-input-container input[type="number"]:focus, .md-chips .md-chip-input-container input[type="tel"]:focus, .md-chips .md-chip-input-container input[type="url"]:focus, .md-chips .md-chip-input-container input[type="text"]:focus {
  outline: none;
}

.md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
  background: transparent;
  height: 32px;
}

.md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
}

.md-chips .md-chip-input-container md-autocomplete input {
  position: relative;
}

.md-chips .md-chip-input-container input {
  border: 0;
  height: 32px;
  line-height: 32px;
  padding: 0;
}

.md-chips .md-chip-input-container input:focus {
  outline: none;
}

.md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
  height: 32px;
}

.md-chips .md-chip-input-container md-autocomplete {
  box-shadow: none;
}

.md-chips .md-chip-input-container md-autocomplete input {
  position: relative;
}

.md-chips .md-chip-input-container:not(:first-child) {
  margin: 8px 8px 0 0;
}

.md-chips .md-chip-input-container input {
  background: transparent;
  border-width: 0;
}

.md-chips md-autocomplete button {
  display: none;
}

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff;
  }
  .md-chip-input-container md-autocomplete {
    border: none;
  }
}

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none;
}

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px;
}

.md-calendar-scroll-mask .md-virtual-repeat-scroller {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
  display: none;
}

.md-calendar-scroll-mask .md-virtual-repeat-offsetter {
  width: 100%;
}

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px;
}

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
}

.md-calendar-date:first-child {
  padding-left: 16px;
}

.md-calendar-date:last-child {
  padding-right: 16px;
}

.md-calendar-date.md-calendar-date-disabled {
  cursor: default;
}

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
  cursor: pointer;
}

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px;
}

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

.md-calendar-day-header th {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  font-weight: normal;
  height: 40px;
}

.md-calendar-day-header th:first-child {
  padding-left: 16px;
}

.md-calendar-day-header th:last-child {
  padding-right: 16px;
}

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

.md-calendar tr:last-child td {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.md-calendar:first-child {
  border-top: 1px solid transparent;
}

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 18px;
  margin-right: -18px;
  vertical-align: middle;
}

.md-inline-form md-datepicker {
  margin-top: 12px;
}

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none;
}

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
}

.md-datepicker-input::-ms-clear {
  display: none;
}

.md-datepicker-input-container {
  position: relative;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto;
  margin-left: 12px;
}

.md-datepicker-input-container.md-datepicker-focused {
  border-bottom-width: 2px;
}

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-datepicker-calendar-pane.md-pane-open {
  transform: scale(1);
}

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  background: transparent;
  pointer-events: none;
  cursor: text;
}

.md-datepicker-input-mask-opaque {
  position: absolute;
  right: 0;
  left: 120px;
  height: 100%;
}

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1);
}

.md-pane-open .md-datepicker-calendar {
  opacity: 1;
}

.md-datepicker-calendar md-calendar:focus {
  outline: none;
}

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-25%) translateX(45%);
}

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 100%;
  width: 36px;
  position: absolute;
}

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent;
}

md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none;
}

.md-datepicker-open .md-datepicker-input-container {
  margin-left: -12px;
  border: none;
}

.md-datepicker-open .md-datepicker-input {
  margin-left: 24px;
  height: 40px;
}

.md-datepicker-open .md-datepicker-triangle-button {
  display: none;
}

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none;
}

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s;
}

.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0);
}

.md-dialog-is-showing {
  max-height: 100%;
}

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden;
}

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}

md-dialog.md-transition-in {
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 0) scale(1);
}

md-dialog.md-transition-out {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 100%) scale(0.2);
}

md-dialog > form {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

md-dialog .md-dialog-content {
  padding: 24px;
}

md-dialog md-dialog-content {
  order: 1;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
  margin-top: 0;
}

md-dialog md-dialog-content:focus {
  outline: none;
}

md-dialog md-dialog-content .md-subheader {
  margin: 0;
}

md-dialog md-dialog-content .md-subheader.sticky-clone {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

md-dialog md-dialog-content.sticky-container {
  padding: 0;
}

md-dialog md-dialog-content.sticky-container > div {
  padding: 24px;
  padding-top: 0;
}

md-dialog md-dialog-content .md-dialog-content-body {
  width: 100%;
}

md-dialog .md-actions, md-dialog md-dialog-actions {
  display: flex;
  order: 2;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-right: 8px;
  padding-left: 16px;
  min-height: 52px;
  overflow: hidden;
}

md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 0;
  margin-top: 8px;
}

md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
  border-top-width: 1px;
  border-top-style: solid;
}

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff;
  }
}

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0;
}

md-divider[md-inset] {
  margin-left: 80px;
}

.layout-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
}

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */
}

md-fab-speed-dial.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}

md-fab-speed-dial.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}

md-fab-speed-dial.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}

md-fab-speed-dial.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}

md-fab-speed-dial:not(.md-hover-full) {
  pointer-events: none;
}

md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
  pointer-events: auto;
}

md-fab-speed-dial:not(.md-hover-full).md-is-open {
  pointer-events: auto;
}

md-fab-speed-dial .md-css-variables {
  z-index: 20;
}

md-fab-speed-dial.md-is-open .md-fab-action-item {
  align-items: center;
}

md-fab-speed-dial md-fab-actions {
  display: flex;
  height: auto;
}

md-fab-speed-dial md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-fab-speed-dial.md-down {
  flex-direction: column;
}

md-fab-speed-dial.md-down md-fab-trigger {
  order: 1;
}

md-fab-speed-dial.md-down md-fab-actions {
  flex-direction: column;
  order: 2;
}

md-fab-speed-dial.md-up {
  flex-direction: column;
}

md-fab-speed-dial.md-up md-fab-trigger {
  order: 2;
}

md-fab-speed-dial.md-up md-fab-actions {
  flex-direction: column-reverse;
  order: 1;
}

md-fab-speed-dial.md-left {
  flex-direction: row;
}

md-fab-speed-dial.md-left md-fab-trigger {
  order: 2;
}

md-fab-speed-dial.md-left md-fab-actions {
  flex-direction: row-reverse;
  order: 1;
}

md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-fab-speed-dial.md-right {
  flex-direction: row;
}

md-fab-speed-dial.md-right md-fab-trigger {
  order: 1;
}

md-fab-speed-dial.md-right md-fab-actions {
  flex-direction: row;
  order: 2;
}

md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
  visibility: hidden;
}

md-fab-speed-dial.md-fling .md-fab-action-item {
  opacity: 1;
}

md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
  opacity: 0;
  transition-duration: 0s;
}

md-fab-speed-dial.md-scale .md-fab-action-item {
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 0.14286s;
}

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */
}

md-fab-toolbar.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}

md-fab-toolbar.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}

md-fab-toolbar.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}

md-fab-toolbar.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}

md-fab-toolbar .md-fab-toolbar-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  height: 68px;
}

md-fab-toolbar md-fab-trigger {
  position: absolute;
  z-index: 20;
}

md-fab-toolbar md-fab-trigger button {
  overflow: visible !important;
}

md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
  display: block;
  position: absolute;
  z-index: 21;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-fab-toolbar md-fab-trigger md-icon {
  position: relative;
  z-index: 22;
  opacity: 1;
  transition: all 200ms ease-in;
}

md-fab-toolbar.md-left md-fab-trigger {
  right: 0;
}

md-fab-toolbar.md-left .md-toolbar-tools {
  flex-direction: row-reverse;
}

md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-right: 0.6rem;
}

md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
  margin-left: -0.8rem;
}

md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
  margin-right: 8px;
}

md-fab-toolbar.md-right md-fab-trigger {
  left: 0;
}

md-fab-toolbar.md-right .md-toolbar-tools {
  flex-direction: row;
}

md-fab-toolbar md-toolbar {
  background-color: transparent !important;
  pointer-events: none;
  z-index: 23;
}

md-fab-toolbar md-toolbar .md-toolbar-tools {
  padding: 0 20px;
  margin-top: 3px;
}

md-fab-toolbar md-toolbar .md-fab-action-item {
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 0.15s;
}

md-fab-toolbar.md-is-open md-fab-trigger > button {
  box-shadow: none;
}

md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
  opacity: 0;
}

md-fab-toolbar.md-is-open .md-fab-action-item {
  opacity: 1;
  transform: scale(1);
}

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative;
}

md-grid-list md-grid-tile,
md-grid-list md-grid-tile > figure,
md-grid-list md-grid-tile-header,
md-grid-list md-grid-tile-footer {
  box-sizing: border-box;
}

md-grid-list md-grid-tile {
  display: block;
  position: absolute;
}

md-grid-list md-grid-tile figure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
}

md-grid-list md-grid-tile md-grid-tile-header,
md-grid-list md-grid-tile md-grid-tile-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  color: #fff;
  background: rgba(0, 0, 0, 0.18);
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
}

md-grid-list md-grid-tile md-grid-tile-header h3,
md-grid-list md-grid-tile md-grid-tile-header h4,
md-grid-list md-grid-tile md-grid-tile-footer h3,
md-grid-list md-grid-tile md-grid-tile-footer h4 {
  font-weight: 400;
  margin: 0 0 0 16px;
}

md-grid-list md-grid-tile md-grid-tile-header h3,
md-grid-list md-grid-tile md-grid-tile-footer h3 {
  font-size: 14px;
}

md-grid-list md-grid-tile md-grid-tile-header h4,
md-grid-list md-grid-tile md-grid-tile-footer h4 {
  font-size: 12px;
}

md-grid-list md-grid-tile md-grid-tile-header {
  top: 0;
}

md-grid-list md-grid-tile md-grid-tile-footer {
  bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff;
  }
  md-grid-tile-footer {
    border-top: 1px solid #fff;
  }
}

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

md-icon svg {
  pointer-events: none;
  display: block;
}

md-icon[md-font-icon] {
  line-height: 1;
  width: auto;
}

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */
}

md-input-container:after {
  content: '';
  display: table;
  clear: both;
}

md-input-container.md-block {
  display: block;
}

md-input-container .md-errors-spacer {
  float: right;
  min-height: 24px;
  min-width: 1px;
}

html[dir=rtl] md-input-container .md-errors-spacer {
  float: left;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container .md-errors-spacer {
  float: left;
  unicode-bidi: embed;
}

md-input-container .md-errors-spacer bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container .md-errors-spacer bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container > md-icon {
  position: absolute;
  top: 5px;
  left: 2px;
  right: auto;
}

html[dir=rtl] md-input-container > md-icon {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container > md-icon {
  left: auto;
  unicode-bidi: embed;
}

md-input-container > md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container > md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container > md-icon {
  right: 2px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container > md-icon {
  right: 2px;
  unicode-bidi: embed;
}

md-input-container > md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container > md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container textarea,
md-input-container input[type="text"],
md-input-container input[type="password"],
md-input-container input[type="datetime"],
md-input-container input[type="datetime-local"],
md-input-container input[type="date"],
md-input-container input[type="month"],
md-input-container input[type="time"],
md-input-container input[type="week"],
md-input-container input[type="number"],
md-input-container input[type="email"],
md-input-container input[type="url"],
md-input-container input[type="search"],
md-input-container input[type="tel"],
md-input-container input[type="color"] {
  /* remove default appearance from all input/textarea */
  -moz-appearance: none;
  -webkit-appearance: none;
}

md-input-container input[type="date"],
md-input-container input[type="datetime-local"],
md-input-container input[type="month"],
md-input-container input[type="time"],
md-input-container input[type="week"] {
  min-height: 26px;
}

md-input-container textarea {
  resize: none;
  overflow: hidden;
}

md-input-container textarea.md-input {
  min-height: 26px;
  -ms-flex-preferred-size: auto;
}

md-input-container label:not(.md-container-ignore) {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: auto;
}

html[dir=rtl] md-input-container label:not(.md-container-ignore) {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container label:not(.md-container-ignore) {
  left: auto;
  unicode-bidi: embed;
}

md-input-container label:not(.md-container-ignore) bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-container-ignore) bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container label:not(.md-container-ignore) {
  right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container label:not(.md-container-ignore) {
  right: 0;
  unicode-bidi: embed;
}

md-input-container label:not(.md-container-ignore) bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-container-ignore) bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon {
  padding-left: 36px;
  padding-right: 0;
}

html[dir=rtl] md-input-container.md-has-icon {
  padding-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-has-icon {
  padding-left: 0;
  unicode-bidi: embed;
}

md-input-container.md-has-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container.md-has-icon {
  padding-right: 36px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-has-icon {
  padding-right: 36px;
  unicode-bidi: embed;
}

md-input-container.md-has-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon > label {
  left: 36px;
  right: auto;
}

html[dir=rtl] md-input-container.md-has-icon > label {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-has-icon > label {
  left: auto;
  unicode-bidi: embed;
}

md-input-container.md-has-icon > label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon > label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container.md-has-icon > label {
  right: 36px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-has-icon > label {
  right: 36px;
  unicode-bidi: embed;
}

md-input-container.md-has-icon > label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-has-icon > label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore),
md-input-container .md-placeholder {
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 3px;
  padding-right: 0;
  z-index: 1;
  transform: translate3d(0, 28px, 0) scale(1);
  transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
  transform-origin: left top;
}

html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
md-input-container .md-placeholder {
  padding-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
md-input-container .md-placeholder {
  padding-left: 0;
  unicode-bidi: embed;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
md-input-container .md-placeholder bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
md-input-container .md-placeholder bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
md-input-container .md-placeholder {
  padding-right: 3px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
md-input-container .md-placeholder {
  padding-right: 3px;
  unicode-bidi: embed;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
md-input-container .md-placeholder bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
md-input-container .md-placeholder bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
md-input-container .md-placeholder {
  transform-origin: right top;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
md-input-container .md-placeholder {
  transform-origin: right top;
  unicode-bidi: embed;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
md-input-container .md-placeholder bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
md-input-container .md-placeholder bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container .md-placeholder {
  position: absolute;
  top: 0;
  opacity: 0;
  transition-property: opacity, transform;
  transform: translate3d(0, 30px, 0);
}

md-input-container.md-input-focused .md-placeholder {
  opacity: 1;
  transform: translate3d(0, 24px, 0);
}

md-input-container.md-input-has-value .md-placeholder {
  transition: none;
  opacity: 0;
}

md-input-container:not(.md-input-has-value) input:not(:focus),
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
  color: transparent;
}

md-input-container .md-input {
  order: 2;
  display: block;
  margin-top: 0;
  background: none;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left;
}

html[dir=rtl] md-input-container .md-input {
  float: right;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container .md-input {
  float: right;
  unicode-bidi: embed;
}

md-input-container .md-input bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container .md-input bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container .md-input:focus {
  outline: none;
}

md-input-container .md-input:invalid {
  outline: none;
  box-shadow: none;
}

md-input-container .md-input.md-no-flex {
  flex: none !important;
}

md-input-container .md-char-counter {
  text-align: right;
  padding-right: 2px;
  padding-left: 0;
}

html[dir=rtl] md-input-container .md-char-counter {
  text-align: left;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container .md-char-counter {
  text-align: left;
  unicode-bidi: embed;
}

md-input-container .md-char-counter bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container .md-char-counter bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container .md-char-counter {
  padding-right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container .md-char-counter {
  padding-right: 0;
  unicode-bidi: embed;
}

md-input-container .md-char-counter bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container .md-char-counter bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container .md-char-counter {
  padding-left: 2px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container .md-char-counter {
  padding-left: 2px;
  unicode-bidi: embed;
}

md-input-container .md-char-counter bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container .md-char-counter bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container ng-messages, md-input-container data-ng-messages, md-input-container x-ng-messages,
md-input-container [ng-messages], md-input-container [data-ng-messages], md-input-container [x-ng-messages] {
  position: relative;
  order: 4;
  overflow: hidden;
  clear: left;
}

html[dir=rtl] md-input-container ng-messages, html[dir=rtl] md-input-container data-ng-messages, html[dir=rtl] md-input-container x-ng-messages, html[dir=rtl]
md-input-container [ng-messages], html[dir=rtl] md-input-container [data-ng-messages], html[dir=rtl] md-input-container [x-ng-messages] {
  clear: right;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container ng-messages, body[dir=rtl] md-input-container data-ng-messages, body[dir=rtl] md-input-container x-ng-messages, body[dir=rtl]
md-input-container [ng-messages], body[dir=rtl] md-input-container [data-ng-messages], body[dir=rtl] md-input-container [x-ng-messages] {
  clear: right;
  unicode-bidi: embed;
}

md-input-container ng-messages bdo[dir=rtl], md-input-container data-ng-messages bdo[dir=rtl], md-input-container x-ng-messages bdo[dir=rtl],
md-input-container [ng-messages] bdo[dir=rtl], md-input-container [data-ng-messages] bdo[dir=rtl], md-input-container [x-ng-messages] bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container ng-messages bdo[dir=ltr], md-input-container data-ng-messages bdo[dir=ltr], md-input-container x-ng-messages bdo[dir=ltr],
md-input-container [ng-messages] bdo[dir=ltr], md-input-container [data-ng-messages] bdo[dir=ltr], md-input-container [x-ng-messages] bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container ng-messages.ng-enter ng-message, md-input-container ng-messages.ng-enter data-ng-message, md-input-container ng-messages.ng-enter x-ng-message,
md-input-container ng-messages.ng-enter [ng-message], md-input-container ng-messages.ng-enter [data-ng-message], md-input-container ng-messages.ng-enter [x-ng-message],
md-input-container ng-messages.ng-enter [ng-message-exp], md-input-container ng-messages.ng-enter [data-ng-message-exp], md-input-container ng-messages.ng-enter [x-ng-message-exp], md-input-container data-ng-messages.ng-enter ng-message, md-input-container data-ng-messages.ng-enter data-ng-message, md-input-container data-ng-messages.ng-enter x-ng-message,
md-input-container data-ng-messages.ng-enter [ng-message], md-input-container data-ng-messages.ng-enter [data-ng-message], md-input-container data-ng-messages.ng-enter [x-ng-message],
md-input-container data-ng-messages.ng-enter [ng-message-exp], md-input-container data-ng-messages.ng-enter [data-ng-message-exp], md-input-container data-ng-messages.ng-enter [x-ng-message-exp], md-input-container x-ng-messages.ng-enter ng-message, md-input-container x-ng-messages.ng-enter data-ng-message, md-input-container x-ng-messages.ng-enter x-ng-message,
md-input-container x-ng-messages.ng-enter [ng-message], md-input-container x-ng-messages.ng-enter [data-ng-message], md-input-container x-ng-messages.ng-enter [x-ng-message],
md-input-container x-ng-messages.ng-enter [ng-message-exp], md-input-container x-ng-messages.ng-enter [data-ng-message-exp], md-input-container x-ng-messages.ng-enter [x-ng-message-exp],
md-input-container [ng-messages].ng-enter ng-message,
md-input-container [ng-messages].ng-enter data-ng-message,
md-input-container [ng-messages].ng-enter x-ng-message,
md-input-container [ng-messages].ng-enter [ng-message],
md-input-container [ng-messages].ng-enter [data-ng-message],
md-input-container [ng-messages].ng-enter [x-ng-message],
md-input-container [ng-messages].ng-enter [ng-message-exp],
md-input-container [ng-messages].ng-enter [data-ng-message-exp],
md-input-container [ng-messages].ng-enter [x-ng-message-exp], md-input-container [data-ng-messages].ng-enter ng-message, md-input-container [data-ng-messages].ng-enter data-ng-message, md-input-container [data-ng-messages].ng-enter x-ng-message,
md-input-container [data-ng-messages].ng-enter [ng-message], md-input-container [data-ng-messages].ng-enter [data-ng-message], md-input-container [data-ng-messages].ng-enter [x-ng-message],
md-input-container [data-ng-messages].ng-enter [ng-message-exp], md-input-container [data-ng-messages].ng-enter [data-ng-message-exp], md-input-container [data-ng-messages].ng-enter [x-ng-message-exp], md-input-container [x-ng-messages].ng-enter ng-message, md-input-container [x-ng-messages].ng-enter data-ng-message, md-input-container [x-ng-messages].ng-enter x-ng-message,
md-input-container [x-ng-messages].ng-enter [ng-message], md-input-container [x-ng-messages].ng-enter [data-ng-message], md-input-container [x-ng-messages].ng-enter [x-ng-message],
md-input-container [x-ng-messages].ng-enter [ng-message-exp], md-input-container [x-ng-messages].ng-enter [data-ng-message-exp], md-input-container [x-ng-messages].ng-enter [x-ng-message-exp] {
  opacity: 0;
  margin-top: -100px;
}

md-input-container ng-message, md-input-container data-ng-message, md-input-container x-ng-message,
md-input-container [ng-message], md-input-container [data-ng-message], md-input-container [x-ng-message],
md-input-container [ng-message-exp], md-input-container [data-ng-message-exp], md-input-container [x-ng-message-exp],
md-input-container .md-char-counter {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 1;
  margin-top: 0;
  padding-top: 5px;
}

md-input-container ng-message:not(.md-char-counter), md-input-container data-ng-message:not(.md-char-counter), md-input-container x-ng-message:not(.md-char-counter),
md-input-container [ng-message]:not(.md-char-counter), md-input-container [data-ng-message]:not(.md-char-counter), md-input-container [x-ng-message]:not(.md-char-counter),
md-input-container [ng-message-exp]:not(.md-char-counter), md-input-container [data-ng-message-exp]:not(.md-char-counter), md-input-container [x-ng-message-exp]:not(.md-char-counter),
md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 5px;
  padding-left: 0;
}

html[dir=rtl] md-input-container ng-message:not(.md-char-counter), html[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), html[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), html[dir=rtl]
md-input-container [ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), html[dir=rtl]
md-input-container [ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), html[dir=rtl]
md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container ng-message:not(.md-char-counter), body[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), body[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), body[dir=rtl]
md-input-container [ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), body[dir=rtl]
md-input-container [ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), body[dir=rtl]
md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 0;
  unicode-bidi: embed;
}

md-input-container ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=rtl],
md-input-container [ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=rtl],
md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl],
md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=ltr],
md-input-container [ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=ltr],
md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr],
md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container ng-message:not(.md-char-counter), html[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), html[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), html[dir=rtl]
md-input-container [ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), html[dir=rtl]
md-input-container [ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), html[dir=rtl]
md-input-container .md-char-counter:not(.md-char-counter) {
  padding-left: 5px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container ng-message:not(.md-char-counter), body[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), body[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), body[dir=rtl]
md-input-container [ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), body[dir=rtl]
md-input-container [ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), body[dir=rtl]
md-input-container .md-char-counter:not(.md-char-counter) {
  padding-left: 5px;
  unicode-bidi: embed;
}

md-input-container ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=rtl],
md-input-container [ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=rtl],
md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl],
md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=ltr],
md-input-container [ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=ltr],
md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr],
md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
  opacity: 0;
  margin-top: -100px;
}

md-input-container .md-auto-hide .md-input-message-animation:not(.ng-animate) {
  opacity: 0;
  margin-top: -100px;
}

md-input-container .md-input-message-animation.ng-enter {
  opacity: 0;
  margin-top: -100px;
}

md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: translate3d(0, 6px, 0) scale(0.75);
}

md-input-container.md-input-has-value label {
  transition: none;
}

md-input-container.md-input-focused .md-input,
md-input-container .md-input.ng-invalid.ng-dirty {
  padding-bottom: 0;
  border-width: 0 0 2px 0;
}

md-input-container .md-input[disabled],
[disabled] md-input-container .md-input {
  background-position: 0 bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  margin-bottom: -1px;
}

md-input-container.md-icon-float {
  transition: margin-top 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-input-container.md-icon-float > label {
  pointer-events: none;
  position: absolute;
}

md-input-container.md-icon-float > md-icon {
  top: 2px;
  left: 2px;
  right: auto;
}

html[dir=rtl] md-input-container.md-icon-float > md-icon {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-float > md-icon {
  left: auto;
  unicode-bidi: embed;
}

md-input-container.md-icon-float > md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-float > md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container.md-icon-float > md-icon {
  right: 2px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-float > md-icon {
  right: 2px;
  unicode-bidi: embed;
}

md-input-container.md-icon-float > md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-float > md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-float.md-input-focused label, md-input-container.md-icon-float.md-input-has-value label {
  transform: translate3d(0, 6px, 0) scale(0.75);
  transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.5s;
}

md-input-container.md-icon-right {
  padding-right: 36px;
  padding-left: 36px;
}

html[dir=rtl] md-input-container.md-icon-right {
  padding-right: 36px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-right {
  padding-right: 36px;
  unicode-bidi: embed;
}

md-input-container.md-icon-right bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-right bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container.md-icon-right {
  padding-left: 36px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-right {
  padding-left: 36px;
  unicode-bidi: embed;
}

md-input-container.md-icon-right bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-right bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-right .md-errors-spacer + md-icon {
  margin: 0;
  right: 2px;
  left: auto;
}

html[dir=rtl] md-input-container.md-icon-right .md-errors-spacer + md-icon {
  right: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-right .md-errors-spacer + md-icon {
  right: auto;
  unicode-bidi: embed;
}

md-input-container.md-icon-right .md-errors-spacer + md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-right .md-errors-spacer + md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-input-container.md-icon-right .md-errors-spacer + md-icon {
  left: 2px;
  unicode-bidi: embed;
}

body[dir=rtl] md-input-container.md-icon-right .md-errors-spacer + md-icon {
  left: 2px;
  unicode-bidi: embed;
}

md-input-container.md-icon-right .md-errors-spacer + md-icon bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-input-container.md-icon-right .md-errors-spacer + md-icon bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff;
  }
}

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
}

.md-open-menu-container md-menu-divider {
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: 100%;
}

.md-open-menu-container md-menu-content > * {
  opacity: 0;
}

.md-open-menu-container:not(.md-clickable) {
  pointer-events: none;
}

.md-open-menu-container.md-active {
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 200ms;
}

.md-open-menu-container.md-active > md-menu-content > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 200ms;
  transition-delay: 100ms;
}

.md-open-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms;
}

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto;
}

md-menu-content.md-dense {
  max-height: 208px;
}

md-menu-content.md-dense md-menu-item {
  height: 32px;
  min-height: 0px;
}

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
}

md-menu-item > * {
  width: 100%;
  margin: auto 0;
  padding-left: 16px;
  padding-right: 16px;
}

md-menu-item > a.md-button {
  display: flex;
}

md-menu-item > .md-button {
  border-radius: 0;
  margin: auto 0;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  text-align: start;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-block;
  align-items: baseline;
  align-content: flex-start;
  width: 100%;
}

md-menu-item > .md-button md-icon {
  margin: auto 16px auto 0;
}

md-menu-item > .md-button p {
  display: inline-block;
  margin: auto;
}

md-menu-item > .md-button span {
  margin-top: auto;
  margin-bottom: auto;
}

md-menu-item > .md-button .md-ripple-container {
  border-radius: inherit;
}

.md-menu {
  padding: 8px 0;
}

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0;
}

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px;
  }
  md-menu-content[width="3"] {
    min-width: 168px;
  }
  md-menu-content[width="4"] {
    min-width: 224px;
  }
  md-menu-content[width="5"] {
    min-width: 280px;
  }
  md-menu-content[width="6"] {
    min-width: 336px;
  }
  md-menu-content[width="7"] {
    min-width: 392px;
  }
}

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px;
  }
  md-menu-content[width="3"] {
    min-width: 192px;
  }
  md-menu-content[width="4"] {
    min-width: 256px;
  }
  md-menu-content[width="5"] {
    min-width: 320px;
  }
  md-menu-content[width="6"] {
    min-width: 384px;
  }
  md-menu-content[width="7"] {
    min-width: 448px;
  }
}

md-list {
  display: block;
  padding: 8px 0px 8px 0px;
}

md-list .md-subheader {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 1.2em;
}

md-list-item {
  position: relative;
}

md-list-item.md-proxy-focus.md-focused .md-no-style {
  transition: background-color 0.15s linear;
}

md-list-item.md-no-proxy,
md-list-item .md-no-style {
  position: relative;
  padding: 0px 16px;
  flex: 1 1 auto;
}

md-list-item.md-no-proxy.md-button,
md-list-item .md-no-style.md-button {
  font-size: inherit;
  height: inherit;
  text-align: left;
  text-transform: none;
  width: 100%;
  white-space: normal;
  flex-direction: inherit;
  align-items: inherit;
  border-radius: 0;
}

md-list-item.md-no-proxy.md-button > .md-ripple-container,
md-list-item .md-no-style.md-button > .md-ripple-container {
  border-radius: 0;
}

md-list-item.md-no-proxy:focus,
md-list-item .md-no-style:focus {
  outline: none;
}

md-list-item.md-with-secondary {
  position: relative;
}

md-list-item.md-clickable:hover {
  cursor: pointer;
}

md-list-item md-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

md-list-item md-divider[md-inset] {
  left: 96px;
  width: calc(100% - 96px);
  margin: 0;
}

md-list-item, md-list-item .md-list-item-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  height: auto;
}

md-list-item > div.md-primary > md-icon:not(.md-avatar-icon),
md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon),
md-list-item > md-icon:first-child:not(.md-avatar-icon),
md-list-item > md-icon.md-secondary:not(.md-avatar-icon), md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
  width: 24px;
  margin-top: 16px;
  margin-bottom: 12px;
  box-sizing: content-box;
}

md-list-item > div.md-primary > md-checkbox,
md-list-item > div.md-secondary > md-checkbox,
md-list-item > md-checkbox,
md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
md-list-item .md-list-item-inner > md-checkbox,
md-list-item .md-list-item-inner md-checkbox.md-secondary {
  align-self: center;
}

md-list-item > div.md-primary > md-checkbox .md-label,
md-list-item > div.md-secondary > md-checkbox .md-label,
md-list-item > md-checkbox .md-label,
md-list-item md-checkbox.md-secondary .md-label, md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
md-list-item .md-list-item-inner > md-checkbox .md-label,
md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
  display: none;
}

md-list-item > md-icon:first-child:not(.md-avatar-icon), md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: 32px;
}

md-list-item > md-checkbox, md-list-item .md-list-item-inner > md-checkbox {
  width: 24px;
  margin-left: 3px;
  margin-right: 29px;
  margin-top: 16px;
}

md-list-item .md-avatar, md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar, md-list-item .md-list-item-inner .md-avatar-icon {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border-radius: 50%;
  box-sizing: content-box;
}

md-list-item .md-avatar, md-list-item .md-list-item-inner .md-avatar {
  width: 40px;
  height: 40px;
}

md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar-icon {
  padding: 8px;
}

md-list-item md-checkbox.md-secondary,
md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary,
md-list-item .md-list-item-inner md-switch.md-secondary {
  margin-top: 0;
  margin-bottom: 0;
}

md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary {
  margin-right: 0;
}

md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-switch.md-secondary {
  margin-right: -6px;
}

md-list-item button.md-button.md-secondary-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container {
  background-color: transparent;
  align-self: center;
  border-radius: 50%;
  margin: 0px;
  min-width: 0px;
}

md-list-item button.md-button.md-secondary-container .md-ripple,
md-list-item button.md-button.md-secondary-container .md-ripple-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple,
md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple-container {
  border-radius: 50%;
}

md-list-item button.md-button.md-secondary-container.md-icon-button, md-list-item .md-list-item-inner button.md-button.md-secondary-container.md-icon-button {
  margin-right: -12px;
}

md-list-item .md-secondary-container,
md-list-item .md-secondary, md-list-item .md-list-item-inner .md-secondary-container,
md-list-item .md-list-item-inner .md-secondary {
  position: absolute;
  top: 50%;
  right: 16px;
  margin: 0 0 0 16px;
  transform: translate3d(0, -50%, 0);
}

md-list-item > .md-button.md-secondary-container > .md-secondary, md-list-item .md-list-item-inner > .md-button.md-secondary-container > .md-secondary {
  margin-left: 0;
  position: static;
}

md-list-item > p, md-list-item > .md-list-item-inner > p, md-list-item .md-list-item-inner > p, md-list-item .md-list-item-inner > .md-list-item-inner > p {
  flex: 1;
  margin: 0;
}

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style,
md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  align-items: flex-start;
  justify-content: center;
}

md-list-item.md-2-line .md-list-item-text,
md-list-item.md-2-line > .md-no-style .md-list-item-text,
md-list-item.md-3-line .md-list-item-text,
md-list-item.md-3-line > .md-no-style .md-list-item-text {
  flex: 1;
  margin: auto;
  text-overflow: ellipsis;
}

md-list-item.md-2-line .md-list-item-text.md-offset,
md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset,
md-list-item.md-3-line .md-list-item-text.md-offset,
md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
  margin-left: 56px;
}

md-list-item.md-2-line .md-list-item-text h3,
md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
md-list-item.md-3-line .md-list-item-text h3,
md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  margin: 0 0 0px 0;
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

md-list-item.md-2-line .md-list-item-text h4,
md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
md-list-item.md-3-line .md-list-item-text h4,
md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
  font-size: 14px;
  letter-spacing: 0.010em;
  margin: 3px 0 1px 0;
  font-weight: 400;
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

md-list-item.md-2-line .md-list-item-text p,
md-list-item.md-2-line > .md-no-style .md-list-item-text p,
md-list-item.md-3-line .md-list-item-text p,
md-list-item.md-3-line > .md-no-style .md-list-item-text p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  margin: 0 0 0 0;
  line-height: 1.6em;
}

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style {
  height: auto;
  min-height: 72px;
}

md-list-item.md-2-line.md-long-text,
md-list-item.md-2-line > .md-no-style.md-long-text {
  margin: 1.6em;
}

md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon,
md-list-item.md-2-line > .md-no-style > .md-avatar,
md-list-item.md-2-line > .md-no-style .md-avatar-icon {
  margin-top: 12px;
}

md-list-item.md-2-line > md-icon:first-child,
md-list-item.md-2-line > .md-no-style > md-icon:first-child {
  align-self: flex-start;
}

md-list-item.md-2-line .md-list-item-text,
md-list-item.md-2-line > .md-no-style .md-list-item-text {
  flex: 1;
}

md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  height: auto;
  min-height: 88px;
}

md-list-item.md-3-line.md-long-text,
md-list-item.md-3-line > .md-no-style.md-long-text {
  margin: 1.6em;
}

md-list-item.md-3-line > md-icon:first-child,
md-list-item.md-3-line > .md-avatar,
md-list-item.md-3-line > .md-no-style > md-icon:first-child,
md-list-item.md-3-line > .md-no-style > .md-avatar {
  margin-top: 16px;
}

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px;
}

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2;
}

md-menu-bar .md-menu {
  display: inline-block;
  padding: 0;
  position: relative;
}

md-menu-bar button {
  font-size: 14px;
  padding: 0 10px;
  margin: 0;
  border: 0;
  background-color: transparent;
  height: 40px;
}

md-menu-bar md-backdrop.md-menu-backdrop {
  z-index: -2;
}

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
  position: relative;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
  position: absolute;
  padding: 0;
  width: 24px;
  top: 6px;
  left: 24px;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
  padding: 0 32px 0 64px;
}

md-menu-content.md-menu-bar-menu.md-dense .md-button {
  min-height: 0;
  height: 32px;
  display: flex;
}

md-menu-content.md-menu-bar-menu.md-dense .md-button span {
  flex-grow: 1;
}

md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
  flex-grow: 0;
  align-self: flex-end;
  margin: 0 8px;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
  margin: 8px 0;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  text-align: left;
  text-align: start;
}

md-menu-content.md-menu-bar-menu.md-dense .md-menu {
  padding: 0;
}

md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
  position: relative;
  margin: 0;
  width: 100%;
  text-transform: none;
  font-weight: normal;
  border-radius: 0px;
  padding-left: 16px;
}

md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
  display: block;
  content: '\25BC';
  position: absolute;
  top: 0px;
  speak: none;
  transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
  right: 28px;
}

md-progress-circular {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  z-index: 2;
}

md-progress-circular .md-spinner-wrapper {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
}

md-progress-circular .md-spinner-wrapper .md-inner {
  width: 100px;
  height: 100px;
  position: relative;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top-width: 10px;
  border-top-style: solid;
  box-sizing: border-box;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-left, md-progress-circular .md-spinner-wrapper .md-inner .md-right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-left .md-half-circle, md-progress-circular .md-spinner-wrapper .md-inner .md-right .md-half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border-width: 10px;
  border-style: solid;
  border-bottom-color: transparent;
  border-radius: 50%;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-left {
  left: 0;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-left .md-half-circle {
  left: 0;
  border-right-color: transparent;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-right {
  right: 0;
}

md-progress-circular .md-spinner-wrapper .md-inner .md-right .md-half-circle {
  right: 0;
  border-left-color: transparent;
}

md-progress-circular .md-mode-indeterminate .md-spinner-wrapper {
  animation: outer-rotate 2.91667s linear infinite;
}

md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner {
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-left .md-half-circle, md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-right .md-half-circle {
  animation-iteration-count: infinite;
  animation-duration: 1.3125s;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-left .md-half-circle {
  animation-name: left-wobble;
}

md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-right .md-half-circle {
  animation-name: right-wobble;
}

md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper {
  animation: none;
}

md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner {
  animation: none;
}

md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner .md-left .md-half-circle {
  animation-name: none;
}

md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner .md-right .md-half-circle {
  animation-name: none;
}

md-progress-circular .md-spinner-wrapper.ng-hide {
  animation: none;
}

md-progress-circular .md-spinner-wrapper.ng-hide .md-inner {
  animation: none;
}

md-progress-circular .md-spinner-wrapper.ng-hide .md-inner .md-left .md-half-circle {
  animation-name: none;
}

md-progress-circular .md-spinner-wrapper.ng-hide .md-inner .md-right .md-half-circle {
  animation-name: none;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

md-progress-linear .md-container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
}

md-progress-linear .md-container .md-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}

md-progress-linear .md-container .md-dashed:before {
  content: "";
  display: none;
  position: absolute;
  margin-top: 0;
  height: 5px;
  width: 100%;
  background-color: transparent;
  background-size: 10px 10px !important;
  background-position: 0px -23px;
}

md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
  transition: transform 0.2s linear;
}

md-progress-linear .md-container.md-mode-query .md-bar1 {
  display: none;
}

md-progress-linear .md-container.md-mode-query .md-bar2 {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
}

md-progress-linear .md-container.md-mode-determinate .md-bar1 {
  display: none;
}

md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
  animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite;
}

md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
  animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite;
}

md-progress-linear .md-container.ng-hide {
  animation: none;
}

md-progress-linear .md-container.ng-hide .md-bar1 {
  animation-name: none;
}

md-progress-linear .md-container.ng-hide .md-bar2 {
  animation-name: none;
}

md-progress-linear .md-container.md-mode-buffer {
  background-color: transparent !important;
  transition: all 0.2s linear;
}

md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
  display: block;
  animation: buffer 3s infinite linear;
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    background-position: -200px -23px;
  }
}

@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear;
  }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
  }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
  }
  100% {
    transform: scaleX(0.1);
  }
}

@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.16667%;
    animation-timing-function: linear;
  }
  20% {
    left: -105.16667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
  }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
  }
  100% {
    left: 95.44444%;
  }
}

@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
  }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
  }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
  }
  100% {
    transform: scaleX(0.1);
  }
}

@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.88889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
  }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
  }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
  }
  100% {
    left: 117.38889%;
  }
}

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

md-radio-button[disabled] {
  cursor: default;
}

md-radio-button[disabled] .md-container {
  cursor: default;
}

md-radio-button .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  left: 0;
  right: auto;
}

html[dir=rtl] md-radio-button .md-container {
  left: auto;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-button .md-container {
  left: auto;
  unicode-bidi: embed;
}

md-radio-button .md-container bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-button .md-container bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-radio-button .md-container {
  right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-button .md-container {
  right: 0;
  unicode-bidi: embed;
}

md-radio-button .md-container bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-button .md-container bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-radio-button .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
}

md-radio-button .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}

md-radio-button.md-align-top-left > div.md-container {
  top: 12px;
}

md-radio-button .md-off {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  transition: border-color ease 0.28s;
}

md-radio-button .md-on {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform ease 0.28s;
  transform: scale(0);
}

md-radio-button.md-checked .md-on {
  transform: scale(0.5);
}

md-radio-button .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-left: 30px;
  margin-right: 0;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  width: auto;
}

html[dir=rtl] md-radio-button .md-label {
  margin-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-button .md-label {
  margin-left: 0;
  unicode-bidi: embed;
}

md-radio-button .md-label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-button .md-label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-radio-button .md-label {
  margin-right: 30px;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-button .md-label {
  margin-right: 30px;
  unicode-bidi: embed;
}

md-radio-button .md-label bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-button .md-label bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-radio-button .circle {
  border-radius: 50%;
}

md-radio-group md-radio-button:not(:first-child) {
  margin-top: 16px;
}

md-radio-group.layout-row md-radio-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 16px;
}

html[dir=rtl] md-radio-group.layout-row md-radio-button {
  margin-left: 16px;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-group.layout-row md-radio-button {
  margin-left: 16px;
  unicode-bidi: embed;
}

md-radio-group.layout-row md-radio-button bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-group.layout-row md-radio-button bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-radio-group.layout-row md-radio-button {
  margin-right: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-radio-group.layout-row md-radio-button {
  margin-right: 0;
  unicode-bidi: embed;
}

md-radio-group.layout-row md-radio-button bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-radio-group.layout-row md-radio-button bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-radio-group.layout-row md-radio-button:last-of-type {
  margin-left: 0;
  margin-right: 0;
}

md-radio-group:focus {
  outline: none;
}

md-radio-group.md-focused .md-checked .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

.md-inline-form md-radio-group {
  margin: 18px 0 19px;
}

.md-inline-form md-radio-group md-radio-button {
  display: inline-block;
  height: 30px;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff;
  }
}

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
}

.md-select-menu-container:not(.md-clickable) {
  pointer-events: none;
}

.md-select-menu-container md-progress-circular {
  display: table;
  margin: 24px auto !important;
}

.md-select-menu-container.md-active {
  display: block;
  opacity: 1;
}

.md-select-menu-container.md-active md-select-menu {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 150ms;
}

.md-select-menu-container.md-active md-select-menu > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 150ms;
  transition-delay: 100ms;
}

.md-select-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms;
}

md-input-container > md-select {
  margin: 0;
  order: 2;
}

md-select {
  display: flex;
  margin: 20px 0 26px 0;
}

md-select[disabled] .md-select-value {
  background-position: 0 bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  margin-bottom: -1px;
}

md-select:focus {
  outline: none;
}

md-select[disabled]:hover {
  cursor: default;
}

md-select:not([disabled]):hover {
  cursor: pointer;
}

md-select:not([disabled]).ng-invalid.ng-dirty .md-select-value {
  border-bottom: 2px solid;
  padding-bottom: 0;
}

md-select:not([disabled]):focus .md-select-value {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 0;
}

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: transparent;
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1;
}

.md-select-value .md-text {
  display: inline;
}

.md-select-value *:first-child {
  flex: 1 0 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform: translate3d(0, 2px, 0);
}

.md-select-value .md-select-icon {
  display: block;
  align-items: flex-end;
  text-align: end;
  width: 24px;
  margin: 0 4px;
  transform: translate3d(0, 1px, 0);
}

.md-select-value .md-select-icon:after {
  display: block;
  content: '\25BC';
  position: relative;
  top: 2px;
  speak: none;
  transform: scaleY(0.6) scaleX(1);
}

.md-select-value.md-select-placeholder {
  display: flex;
  order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 2px;
  z-index: 1;
}

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1);
}

md-select-menu.md-reverse {
  flex-direction: column-reverse;
}

md-select-menu:not(.md-overflow) md-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

html[dir=rtl] md-select-menu {
  transform-origin: right top;
  unicode-bidi: embed;
}

body[dir=rtl] md-select-menu {
  transform-origin: right top;
  unicode-bidi: embed;
}

md-select-menu bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-select-menu bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-select-menu md-content {
  min-width: 136px;
  min-height: 48px;
  max-height: 256px;
  overflow-y: auto;
}

md-select-menu > * {
  opacity: 0;
}

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0 16px 0 16px;
  height: 48px;
}

md-option[disabled] {
  cursor: default;
}

md-option:focus {
  outline: none;
}

md-option .md-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

md-optgroup {
  display: block;
}

md-optgroup label {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px;
  font-weight: 500;
}

md-optgroup md-option {
  padding-left: 32px;
  padding-right: 32px;
}

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent;
  }
  md-select-menu {
    border: 1px solid #fff;
  }
}

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

md-sidenav ul {
  list-style: none;
}

md-sidenav.md-closed {
  display: none;
}

md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
  display: flex;
  transition: 0.2s ease-in all;
}

md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
  position: static;
  display: flex;
  transform: translate3d(0, 0, 0);
}

md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-right, md-sidenav.md-locked-open-remove.md-closed {
  position: static;
  display: flex;
  transform: translate3d(0, 0, 0);
}

md-sidenav.md-locked-open-remove-active {
  transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 0;
  min-width: 0;
}

md-sidenav.md-closed.md-locked-open-add {
  width: 0;
  min-width: 0;
  transform: translate3d(0%, 0, 0);
}

md-sidenav.md-closed.md-locked-open-add-active {
  transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 320px;
  min-width: 320px;
  transform: translate3d(0%, 0, 0);
}

.md-sidenav-backdrop.md-locked-open {
  display: none;
}

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0);
}

.md-sidenav-left.md-closed, md-sidenav.md-closed {
  transform: translate3d(-100%, 0, 0);
}

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate3d(-100%, 0, 0);
}

.md-sidenav-right.md-closed {
  transform: translate3d(0%, 0, 0);
}

@media screen and (min-width: 600px) {
  md-sidenav {
    max-width: 400px;
  }
}

@media screen and (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px);
  }
}

@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff;
  }
  .md-sidenav-right {
    border-left: 1px solid #fff;
  }
}

@keyframes sliderFocusThumb {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

md-slider {
  height: 48px;
  position: relative;
  display: block;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */
}

md-slider *, md-slider *:after {
  box-sizing: border-box;
}

md-slider .md-slider-wrapper {
  position: relative;
}

md-slider .md-track-container {
  width: 100%;
  position: absolute;
  top: 23px;
  height: 2px;
}

md-slider .md-track {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}

md-slider .md-track-fill {
  transition: width 0.05s linear;
}

md-slider .md-track-ticks {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}

md-slider .md-track-ticks canvas {
  width: 100%;
}

md-slider .md-thumb-container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: left 0.1s linear;
}

md-slider .md-thumb {
  z-index: 1;
  position: absolute;
  left: -19px;
  top: 5px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  transform: scale(0.5);
  transition: all 0.1s linear;
}

md-slider .md-thumb:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border-width: 3px;
  border-style: solid;
}

md-slider .md-sign {
  /* Center the children (slider-thumb-text) */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -14px;
  top: -20px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  transform: scale(0.4) translate3d(0, 70px, 0);
  transition: all 0.2s ease-in-out;
  /* The arrow pointing down under the sign */
}

md-slider .md-sign:after {
  position: absolute;
  content: '';
  left: 0px;
  border-radius: 16px;
  top: 19px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top-width: 16px;
  border-top-style: solid;
  opacity: 0;
  transform: translate3d(0, -8px, 0);
  transition: all 0.2s ease-in-out;
}

md-slider .md-sign .md-thumb-text {
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
}

md-slider .md-focus-thumb {
  position: absolute;
  left: -24px;
  top: 0px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: none;
  opacity: 0;
  background-color: #C0C0C0;
  animation: sliderFocusThumb 0.4s linear;
}

md-slider .md-focus-ring {
  position: absolute;
  left: -24px;
  top: 0px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  transform: scale(0);
  transition: all 0.2s linear;
  opacity: 0.26;
}

md-slider .md-disabled-thumb {
  position: absolute;
  left: -22px;
  top: 2px;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  transform: scale(0.35);
  border-width: 6px;
  border-style: solid;
  display: none;
}

md-slider.md-min .md-thumb:after {
  background-color: white;
}

md-slider.md-min .md-sign {
  opacity: 0;
}

md-slider:focus {
  outline: none;
}

md-slider.md-dragging .md-thumb-container,
md-slider.md-dragging .md-track-fill {
  transition: none;
}

md-slider:not([md-discrete]) {
  /* Hide the sign and ticks in non-discrete mode */
}

md-slider:not([md-discrete]) .md-track-ticks,
md-slider:not([md-discrete]) .md-sign {
  display: none;
}

md-slider:not([md-discrete]):not([disabled]):hover .md-thumb {
  transform: scale(0.6);
}

md-slider:not([md-discrete]):not([disabled]):focus .md-focus-thumb, md-slider:not([md-discrete]):not([disabled]).md-active .md-focus-thumb {
  display: block;
}

md-slider:not([md-discrete]):not([disabled]):focus .md-focus-ring, md-slider:not([md-discrete]):not([disabled]).md-active .md-focus-ring {
  transform: scale(1);
}

md-slider:not([md-discrete]):not([disabled]):focus .md-thumb, md-slider:not([md-discrete]):not([disabled]).md-active .md-thumb {
  transform: scale(0.85);
}

md-slider[md-discrete] {
  /* Hide the focus thumb in discrete mode */
}

md-slider[md-discrete] .md-focus-thumb,
md-slider[md-discrete] .md-focus-ring {
  display: none;
}

md-slider[md-discrete]:not([disabled]):focus .md-sign,
md-slider[md-discrete]:not([disabled]):focus .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

md-slider[disabled] .md-track-fill {
  display: none;
}

md-slider[disabled] .md-sign {
  display: none;
}

md-slider[disabled] .md-thumb {
  transform: scale(0.35);
}

md-slider[disabled] .md-disabled-thumb {
  display: block;
}

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff;
  }
}

.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0);
}

.md-sticky-clone[sticky-state="active"] {
  transform: translate3d(0, 0, 0);
}

.md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) .md-subheader-inner {
  animation: subheaderStickyHoverIn 0.3s ease-out both;
}

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16);
  }
}

@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin;
}

.md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
  margin: 0;
}

.md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
  z-index: 2;
}

.md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state="active"] {
  margin-top: -2px;
}

.md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
  animation: subheaderStickyHoverOut 0.3s ease-out both;
}

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative;
}

.md-subheader .md-subheader-inner {
  display: block;
  padding: 16px;
}

.md-subheader .md-subheader-content {
  display: block;
  z-index: 1;
  position: relative;
}

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px;
}

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px;
}

html[dir=rtl] md-switch {
  margin-left: 16px;
  unicode-bidi: embed;
}

body[dir=rtl] md-switch {
  margin-left: 16px;
  unicode-bidi: embed;
}

md-switch bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-switch bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-switch {
  margin-right: inherit;
  unicode-bidi: embed;
}

body[dir=rtl] md-switch {
  margin-right: inherit;
  unicode-bidi: embed;
}

md-switch bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-switch bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-switch:last-of-type {
  margin-left: inherit;
  margin-right: 0;
}

html[dir=rtl] md-switch:last-of-type {
  margin-left: 0;
  unicode-bidi: embed;
}

body[dir=rtl] md-switch:last-of-type {
  margin-left: 0;
  unicode-bidi: embed;
}

md-switch:last-of-type bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-switch:last-of-type bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

html[dir=rtl] md-switch:last-of-type {
  margin-right: inherit;
  unicode-bidi: embed;
}

body[dir=rtl] md-switch:last-of-type {
  margin-right: inherit;
  unicode-bidi: embed;
}

md-switch:last-of-type bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

md-switch:last-of-type bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

md-switch[disabled] {
  cursor: default;
}

md-switch[disabled] .md-container {
  cursor: default;
}

md-switch .md-container {
  cursor: grab;
  width: 36px;
  height: 24px;
  position: relative;
  user-select: none;
  margin-right: 8px;
  float: left;
}

md-switch:not([disabled]) .md-dragging,
md-switch:not([disabled]).md-dragging .md-container {
  cursor: grabbing;
}

md-switch.md-focused:not([disabled]) .md-thumb:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

md-switch.md-focused:not([disabled]):not(.md-checked) .md-thumb:before {
  background-color: rgba(0, 0, 0, 0.12);
}

md-switch .md-label {
  border-color: transparent;
  border-width: 0;
  float: left;
}

md-switch .md-bar {
  left: 1px;
  width: 34px;
  top: 5px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
}

md-switch .md-thumb-container {
  top: 2px;
  left: 0;
  width: 16px;
  position: absolute;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

md-switch.md-checked .md-thumb-container {
  transform: translate3d(100%, 0, 0);
}

md-switch .md-thumb {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

md-switch .md-thumb:before {
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}

md-switch .md-thumb .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
}

md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb-container,
md-switch:not(.md-dragging) .md-thumb {
  transition: all 0.08s linear;
  transition-property: transform, background-color;
}

md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb {
  transition-delay: 0.05s;
}

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666;
  }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E;
  }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff;
  }
}

@keyframes md-tab-content-hide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 248px;
}

md-tabs[md-align-tabs="bottom"] {
  padding-bottom: 48px;
}

md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 2;
}

md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
  top: 0;
  bottom: 48px;
}

md-tabs.md-dynamic-height md-tabs-content-wrapper {
  min-height: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: visible;
}

md-tabs.md-dynamic-height md-tab-content.md-active {
  position: relative;
}

md-tabs[md-border-bottom] md-tabs-wrapper {
  border-width: 0 0 1px;
  border-style: solid;
}

md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
  top: 49px;
}

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
}

md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
  height: 100%;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1em;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
  background: transparent no-repeat center center;
  transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
  outline: none;
}

md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
  opacity: 0.25;
  cursor: default;
}

md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
  transition: none;
}

md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

md-tabs-wrapper md-prev-button {
  left: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg==");
}

md-tabs-wrapper md-next-button {
  right: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K");
}

md-tabs-wrapper md-next-button md-icon {
  transform: translate3d(-50%, -50%, 0) rotate(180deg);
}

md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
  flex-grow: 1;
}

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px;
}

md-tabs-canvas:after {
  content: '';
  display: table;
  clear: both;
}

md-tabs-canvas .md-dummy-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

md-tabs-canvas.md-paginated {
  margin: 0 32px;
}

md-tabs-canvas.md-center-tabs {
  display: flex;
  flex-direction: column;
  text-align: center;
}

md-tabs-canvas.md-center-tabs .md-tab {
  float: none;
  display: inline-block;
}

md-pagination-wrapper {
  height: 48px;
  display: block;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  width: 999999px;
  left: 0;
  transform: translate3d(0, 0, 0);
}

md-pagination-wrapper:after {
  content: '';
  display: table;
  clear: both;
}

md-pagination-wrapper.md-center-tabs {
  position: relative;
  width: initial;
  margin: 0 auto;
}

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

md-tab-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

md-tab-content.md-no-scroll {
  bottom: auto;
  overflow: hidden;
}

md-tab-content.ng-leave, md-tab-content.md-no-transition {
  transition: none;
}

md-tab-content.md-left:not(.md-active) {
  transform: translateX(-100%);
  animation: 1s md-tab-content-hide;
  opacity: 0;
}

md-tab-content.md-left:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden;
}

md-tab-content.md-right:not(.md-active) {
  transform: translateX(100%);
  animation: 1s md-tab-content-hide;
  opacity: 0;
}

md-tab-content.md-right:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden;
}

md-tab-content > div.ng-leave {
  animation: 1s md-tab-content-hide;
}

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px;
}

md-ink-bar.md-left {
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}

md-ink-bar.md-right {
  transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1);
}

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px;
}

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-tab.md-focused {
  box-shadow: none;
  outline: none;
}

.md-tab.md-active {
  cursor: default;
}

.md-tab.md-disabled {
  pointer-events: none;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  opacity: 0.5;
  cursor: default;
}

.md-tab.ng-leave {
  transition: none;
}

md-toolbar + md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
}

md-toast .md-toast-content {
  display: flex;
  align-items: center;
  height: 0;
  max-height: 168px;
  max-width: 100%;
  min-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  font-size: 14px;
  overflow: hidden;
  transform: translate3d(0, 0, 0) rotateZ(0deg);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-toast.md-capsule {
  border-radius: 24px;
}

md-toast.md-capsule .md-toast-content {
  border-radius: 24px;
}

md-toast.ng-leave-active .md-toast-content {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-toast.ng-enter {
  opacity: 0;
}

md-toast.ng-enter .md-toast-content {
  transform: translate3d(0, 100%, 0);
}

md-toast.ng-enter.md-top .md-toast-content {
  transform: translate3d(0, -100%, 0);
}

md-toast.ng-enter.ng-enter-active {
  opacity: 1;
}

md-toast.ng-enter.ng-enter-active .md-toast-content {
  transform: translate3d(0, 0, 0);
}

md-toast.ng-leave.ng-leave-active .md-toast-content {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
  transform: translate3d(0, -50%, 0);
}

md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
  transform: translate3d(0, 50%, 0);
}

md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
  transform: translate3d(0, -100%, 0);
}

md-toast .md-action {
  line-height: 19px;
  margin-left: 24px;
  margin-right: 0;
  cursor: pointer;
  text-transform: uppercase;
  float: right;
}

md-toast .md-action.md-button {
  min-width: 0;
}

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
  }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0);
  }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0);
  }
}

@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
  }
  md-toast.md-bottom {
    bottom: 0;
  }
  md-toast.md-left {
    left: 0;
  }
  md-toast.md-right {
    right: 0;
  }
  md-toast.md-top {
    top: 0;
  }
  md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
    transform: translate3d(-50%, 0, 0);
  }
  md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
    transform: translate3d(50%, 0, 0);
  }
}

@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px;
  }
}

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff;
  }
}

.md-toast-animating {
  overflow: hidden !important;
}

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%;
}

md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
  transition: box-shadow 0.5s linear;
}

md-toolbar md-toolbar-filler {
  width: 72px;
}

md-toolbar *,
md-toolbar *:before,
md-toolbar *:after {
  box-sizing: border-box;
}

md-toolbar.md-tall {
  height: 128px;
  min-height: 128px;
  max-height: 128px;
}

md-toolbar.md-medium-tall {
  height: 88px;
  min-height: 88px;
  max-height: 88px;
}

md-toolbar.md-medium-tall .md-toolbar-tools {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

md-toolbar > .md-indent {
  margin-left: 64px;
}

md-toolbar ~ md-content > md-list {
  padding: 0;
}

md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
  display: none;
}

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0;
}

.md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
  font-size: inherit;
  font-weight: inherit;
  margin: inherit;
}

.md-toolbar-tools a {
  color: inherit;
  text-decoration: none;
}

.md-toolbar-tools .fill-height {
  display: flex;
  align-items: center;
}

.md-toolbar-tools .md-button {
  margin-top: 0;
  margin-bottom: 0;
}

.md-toolbar-tools > .md-button:first-child {
  margin-left: -8px;
}

.md-toolbar-tools > .md-button:last-child {
  margin-right: -8px;
}

.md-toolbar-tools > md-menu:last-child {
  margin-right: -8px;
}

.md-toolbar-tools > md-menu:last-child > .md-button {
  margin-right: 0;
}

@media screen and (-ms-high-contrast: active) {
  .md-toolbar-tools {
    border-bottom: 1px solid #fff;
  }
}

@media only screen and (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px;
  }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px;
  }
}

@media only screen and (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px;
  }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px;
  }
}

md-tooltip {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

@media screen and (min-width: 960px) {
  md-tooltip {
    font-size: 10px;
  }
}

md-tooltip .md-content {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: center top;
  transform: scale(0);
  opacity: 0;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (min-width: 960px) {
  md-tooltip .md-content {
    height: 22px;
    line-height: 22px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

md-tooltip .md-content.md-show-add {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: .2s;
  transform: scale(0);
  opacity: 0;
}

md-tooltip .md-content.md-show, md-tooltip .md-content.md-show-add-active {
  transform: scale(1);
  opacity: 1;
  transform-origin: center top;
}

md-tooltip .md-content.md-show-remove {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: .2s;
}

md-tooltip .md-content.md-show-remove.md-show-remove-active {
  transform: scale(0);
  opacity: 0;
}

md-tooltip.md-hide {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

md-tooltip.md-show {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: auto;
  will-change: opacity, height, width;
}

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.md-virtual-repeat-container .md-virtual-repeat-scroller {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.md-virtual-repeat-container .md-virtual-repeat-sizer {
  box-sizing: border-box;
  height: 1px;
  display: block;
  margin: 0;
  padding: 0;
  width: 1px;
}

.md-virtual-repeat-container .md-virtual-repeat-offsetter {
  box-sizing: border-box;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden;
}

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap;
}

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff;
  }
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/
@-moz-document url-prefix() {
  [layout-fill] {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
[flex-order] {
  order: 0;
}

[flex-order="-20"] {
  order: -20;
}

[flex-order="-19"] {
  order: -19;
}

[flex-order="-18"] {
  order: -18;
}

[flex-order="-17"] {
  order: -17;
}

[flex-order="-16"] {
  order: -16;
}

[flex-order="-15"] {
  order: -15;
}

[flex-order="-14"] {
  order: -14;
}

[flex-order="-13"] {
  order: -13;
}

[flex-order="-12"] {
  order: -12;
}

[flex-order="-11"] {
  order: -11;
}

[flex-order="-10"] {
  order: -10;
}

[flex-order="-9"] {
  order: -9;
}

[flex-order="-8"] {
  order: -8;
}

[flex-order="-7"] {
  order: -7;
}

[flex-order="-6"] {
  order: -6;
}

[flex-order="-5"] {
  order: -5;
}

[flex-order="-4"] {
  order: -4;
}

[flex-order="-3"] {
  order: -3;
}

[flex-order="-2"] {
  order: -2;
}

[flex-order="-1"] {
  order: -1;
}

[flex-order="0"] {
  order: 0;
}

[flex-order="1"] {
  order: 1;
}

[flex-order="2"] {
  order: 2;
}

[flex-order="3"] {
  order: 3;
}

[flex-order="4"] {
  order: 4;
}

[flex-order="5"] {
  order: 5;
}

[flex-order="6"] {
  order: 6;
}

[flex-order="7"] {
  order: 7;
}

[flex-order="8"] {
  order: 8;
}

[flex-order="9"] {
  order: 9;
}

[flex-order="10"] {
  order: 10;
}

[flex-order="11"] {
  order: 11;
}

[flex-order="12"] {
  order: 12;
}

[flex-order="13"] {
  order: 13;
}

[flex-order="14"] {
  order: 14;
}

[flex-order="15"] {
  order: 15;
}

[flex-order="16"] {
  order: 16;
}

[flex-order="17"] {
  order: 17;
}

[flex-order="18"] {
  order: 18;
}

[flex-order="19"] {
  order: 19;
}

[flex-order="20"] {
  order: 20;
}

[flex-offset="0"] {
  margin-left: 0%;
}

[flex-offset="5"] {
  margin-left: 5%;
}

[flex-offset="10"] {
  margin-left: 10%;
}

[flex-offset="15"] {
  margin-left: 15%;
}

[flex-offset="20"] {
  margin-left: 20%;
}

[flex-offset="25"] {
  margin-left: 25%;
}

[flex-offset="30"] {
  margin-left: 30%;
}

[flex-offset="35"] {
  margin-left: 35%;
}

[flex-offset="40"] {
  margin-left: 40%;
}

[flex-offset="45"] {
  margin-left: 45%;
}

[flex-offset="50"] {
  margin-left: 50%;
}

[flex-offset="55"] {
  margin-left: 55%;
}

[flex-offset="60"] {
  margin-left: 60%;
}

[flex-offset="65"] {
  margin-left: 65%;
}

[flex-offset="70"] {
  margin-left: 70%;
}

[flex-offset="75"] {
  margin-left: 75%;
}

[flex-offset="80"] {
  margin-left: 80%;
}

[flex-offset="85"] {
  margin-left: 85%;
}

[flex-offset="90"] {
  margin-left: 90%;
}

[flex-offset="95"] {
  margin-left: 95%;
}

[flex-offset="33"] {
  margin-left: calc(100% / 3);
}

[flex-offset="66"] {
  margin-left: calc(200% / 3);
}

[layout-align],
[layout-align="start stretch"] {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

[layout-align="start"],
[layout-align="start start"],
[layout-align="start center"],
[layout-align="start end"],
[layout-align="start stretch"] {
  justify-content: flex-start;
}

[layout-align="center"],
[layout-align="center start"],
[layout-align="center center"],
[layout-align="center end"],
[layout-align="center stretch"] {
  justify-content: center;
}

[layout-align="end"],
[layout-align="end center"],
[layout-align="end start"],
[layout-align="end end"],
[layout-align="end stretch"] {
  justify-content: flex-end;
}

[layout-align="space-around"],
[layout-align="space-around center"],
[layout-align="space-around start"],
[layout-align="space-around end"],
[layout-align="space-around stretch"] {
  justify-content: space-around;
}

[layout-align="space-between"],
[layout-align="space-between center"],
[layout-align="space-between start"],
[layout-align="space-between end"],
[layout-align="space-between stretch"] {
  justify-content: space-between;
}

[layout-align="start start"],
[layout-align="center start"],
[layout-align="end start"],
[layout-align="space-between start"],
[layout-align="space-around start"] {
  align-items: flex-start;
  align-content: flex-start;
}

[layout-align="start center"],
[layout-align="center center"],
[layout-align="end center"],
[layout-align="space-between center"],
[layout-align="space-around center"] {
  align-items: center;
  align-content: center;
  max-width: 100%;
}

[layout-align="start center"] > *,
[layout-align="center center"] > *,
[layout-align="end center"] > *,
[layout-align="space-between center"] > *,
[layout-align="space-around center"] > * {
  max-width: 100%;
  box-sizing: border-box;
}

[layout-align="start end"],
[layout-align="center end"],
[layout-align="end end"],
[layout-align="space-between end"],
[layout-align="space-around end"] {
  align-items: flex-end;
  align-content: flex-end;
}

[layout-align="start stretch"],
[layout-align="center stretch"],
[layout-align="end stretch"],
[layout-align="space-between stretch"],
[layout-align="space-around stretch"] {
  align-items: stretch;
  align-content: stretch;
}

[flex] {
  flex: 1;
  box-sizing: border-box;
}

@media screen\0 {
  [flex] {
    flex: 1 1 0%;
  }
}

[flex-grow] {
  flex: 1 1 100%;
  box-sizing: border-box;
}

[flex-initial] {
  flex: 0 1 auto;
  box-sizing: border-box;
}

[flex-auto] {
  flex: 1 1 auto;
  box-sizing: border-box;
}

[flex-none] {
  flex: 0 0 auto;
  box-sizing: border-box;
}

[flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="0"],
[layout="row"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="0"],
[layout="column"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
}

[flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="5"],
[layout="row"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="5"],
[layout="column"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

[flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="10"],
[layout="row"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="10"],
[layout="column"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

[flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="15"],
[layout="row"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="15"],
[layout="column"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

[flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="20"],
[layout="row"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="20"],
[layout="column"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

[flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="25"],
[layout="row"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="25"],
[layout="column"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

[flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="30"],
[layout="row"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="30"],
[layout="column"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

[flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="35"],
[layout="row"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="35"],
[layout="column"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

[flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="40"],
[layout="row"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="40"],
[layout="column"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

[flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="45"],
[layout="row"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="45"],
[layout="column"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

[flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="50"],
[layout="row"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="50"],
[layout="column"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

[flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="55"],
[layout="row"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="55"],
[layout="column"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

[flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="60"],
[layout="row"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="60"],
[layout="column"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

[flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="65"],
[layout="row"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="65"],
[layout="column"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

[flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="70"],
[layout="row"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="70"],
[layout="column"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

[flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="75"],
[layout="row"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="75"],
[layout="column"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

[flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="80"],
[layout="row"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="80"],
[layout="column"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

[flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="85"],
[layout="row"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="85"],
[layout="column"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

[flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="90"],
[layout="row"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="90"],
[layout="column"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

[flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="95"],
[layout="row"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="95"],
[layout="column"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

[flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="100"],
[layout="row"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="100"],
[layout="column"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"] {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"] {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

[layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"] {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

[layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"] {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

[layout], [layout="column"], [layout="row"] {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

[layout="column"] {
  flex-direction: column;
}

[layout="row"] {
  flex-direction: row;
}

[layout-padding] > [flex-sm], [layout-padding] > [flex-lt-md] {
  padding: 4px;
}

[layout-padding],
[layout-padding] > [flex],
[layout-padding] > [flex-gt-sm],
[layout-padding] > [flex-md],
[layout-padding] > [flex-lt-lg] {
  padding: 8px;
}

[layout-padding] > [flex-gt-md],
[layout-padding] > [flex-lg] {
  padding: 16px;
}

[layout-margin] > [flex-sm],
[layout-margin] > [flex-lt-md] {
  margin: 4px;
}

[layout-margin],
[layout-margin] > [flex],
[layout-margin] > [flex-gt-sm],
[layout-margin] > [flex-md],
[layout-margin] > [flex-lt-lg] {
  margin: 8px;
}

[layout-margin] > [flex-gt-md],
[layout-margin] > [flex-lg] {
  margin: 16px;
}

[layout-wrap] {
  flex-wrap: wrap;
}

[layout-nowrap] {
  flex-wrap: nowrap;
}

[layout-fill] {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  [hide-xs]:not([show-xs]):not([show]), [hide]:not([show-xs]):not([show]) {
    display: none;
  }
  [flex-order-xs="-20"] {
    order: -20;
  }
  [flex-order-xs="-19"] {
    order: -19;
  }
  [flex-order-xs="-18"] {
    order: -18;
  }
  [flex-order-xs="-17"] {
    order: -17;
  }
  [flex-order-xs="-16"] {
    order: -16;
  }
  [flex-order-xs="-15"] {
    order: -15;
  }
  [flex-order-xs="-14"] {
    order: -14;
  }
  [flex-order-xs="-13"] {
    order: -13;
  }
  [flex-order-xs="-12"] {
    order: -12;
  }
  [flex-order-xs="-11"] {
    order: -11;
  }
  [flex-order-xs="-10"] {
    order: -10;
  }
  [flex-order-xs="-9"] {
    order: -9;
  }
  [flex-order-xs="-8"] {
    order: -8;
  }
  [flex-order-xs="-7"] {
    order: -7;
  }
  [flex-order-xs="-6"] {
    order: -6;
  }
  [flex-order-xs="-5"] {
    order: -5;
  }
  [flex-order-xs="-4"] {
    order: -4;
  }
  [flex-order-xs="-3"] {
    order: -3;
  }
  [flex-order-xs="-2"] {
    order: -2;
  }
  [flex-order-xs="-1"] {
    order: -1;
  }
  [flex-order-xs="0"] {
    order: 0;
  }
  [flex-order-xs="1"] {
    order: 1;
  }
  [flex-order-xs="2"] {
    order: 2;
  }
  [flex-order-xs="3"] {
    order: 3;
  }
  [flex-order-xs="4"] {
    order: 4;
  }
  [flex-order-xs="5"] {
    order: 5;
  }
  [flex-order-xs="6"] {
    order: 6;
  }
  [flex-order-xs="7"] {
    order: 7;
  }
  [flex-order-xs="8"] {
    order: 8;
  }
  [flex-order-xs="9"] {
    order: 9;
  }
  [flex-order-xs="10"] {
    order: 10;
  }
  [flex-order-xs="11"] {
    order: 11;
  }
  [flex-order-xs="12"] {
    order: 12;
  }
  [flex-order-xs="13"] {
    order: 13;
  }
  [flex-order-xs="14"] {
    order: 14;
  }
  [flex-order-xs="15"] {
    order: 15;
  }
  [flex-order-xs="16"] {
    order: 16;
  }
  [flex-order-xs="17"] {
    order: 17;
  }
  [flex-order-xs="18"] {
    order: 18;
  }
  [flex-order-xs="19"] {
    order: 19;
  }
  [flex-order-xs="20"] {
    order: 20;
  }
  [flex-offset-xs="0"] {
    margin-left: 0%;
  }
  [flex-offset-xs="5"] {
    margin-left: 5%;
  }
  [flex-offset-xs="10"] {
    margin-left: 10%;
  }
  [flex-offset-xs="15"] {
    margin-left: 15%;
  }
  [flex-offset-xs="20"] {
    margin-left: 20%;
  }
  [flex-offset-xs="25"] {
    margin-left: 25%;
  }
  [flex-offset-xs="30"] {
    margin-left: 30%;
  }
  [flex-offset-xs="35"] {
    margin-left: 35%;
  }
  [flex-offset-xs="40"] {
    margin-left: 40%;
  }
  [flex-offset-xs="45"] {
    margin-left: 45%;
  }
  [flex-offset-xs="50"] {
    margin-left: 50%;
  }
  [flex-offset-xs="55"] {
    margin-left: 55%;
  }
  [flex-offset-xs="60"] {
    margin-left: 60%;
  }
  [flex-offset-xs="65"] {
    margin-left: 65%;
  }
  [flex-offset-xs="70"] {
    margin-left: 70%;
  }
  [flex-offset-xs="75"] {
    margin-left: 75%;
  }
  [flex-offset-xs="80"] {
    margin-left: 80%;
  }
  [flex-offset-xs="85"] {
    margin-left: 85%;
  }
  [flex-offset-xs="90"] {
    margin-left: 90%;
  }
  [flex-offset-xs="95"] {
    margin-left: 95%;
  }
  [flex-offset-xs="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-xs="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-xs],
  [layout-align-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-xs="start"],
  [layout-align-xs="start start"],
  [layout-align-xs="start center"],
  [layout-align-xs="start end"],
  [layout-align-xs="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-xs="center"],
  [layout-align-xs="center start"],
  [layout-align-xs="center center"],
  [layout-align-xs="center end"],
  [layout-align-xs="center stretch"] {
    justify-content: center;
  }
  [layout-align-xs="end"],
  [layout-align-xs="end center"],
  [layout-align-xs="end start"],
  [layout-align-xs="end end"],
  [layout-align-xs="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-xs="space-around"],
  [layout-align-xs="space-around center"],
  [layout-align-xs="space-around start"],
  [layout-align-xs="space-around end"],
  [layout-align-xs="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-xs="space-between"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-xs="start start"],
  [layout-align-xs="center start"],
  [layout-align-xs="end start"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-xs="start center"],
  [layout-align-xs="center center"],
  [layout-align-xs="end center"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-xs="start center"] > *,
  [layout-align-xs="center center"] > *,
  [layout-align-xs="end center"] > *,
  [layout-align-xs="space-between center"] > *,
  [layout-align-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-xs="start end"],
  [layout-align-xs="center end"],
  [layout-align-xs="end end"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-xs="start stretch"],
  [layout-align-xs="center stretch"],
  [layout-align-xs="end stretch"],
  [layout-align-xs="space-between stretch"],
  [layout-align-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-xs] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (max-width: 599px) {
  [flex-xs] {
    flex: 1 1 0%;
  }
}

@media (max-width: 599px) {
  [flex-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="0"],
  [layout-xs="row"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="0"],
  [layout-xs="column"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="5"],
  [layout-xs="row"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="5"],
  [layout-xs="column"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="10"],
  [layout-xs="row"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="10"],
  [layout-xs="column"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="15"],
  [layout-xs="row"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="15"],
  [layout-xs="column"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="20"],
  [layout-xs="row"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="20"],
  [layout-xs="column"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="25"],
  [layout-xs="row"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="25"],
  [layout-xs="column"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="30"],
  [layout-xs="row"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="30"],
  [layout-xs="column"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="35"],
  [layout-xs="row"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="35"],
  [layout-xs="column"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="40"],
  [layout-xs="row"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="40"],
  [layout-xs="column"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="45"],
  [layout-xs="row"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="45"],
  [layout-xs="column"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="50"],
  [layout-xs="row"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="50"],
  [layout-xs="column"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="55"],
  [layout-xs="row"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="55"],
  [layout-xs="column"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="60"],
  [layout-xs="row"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="60"],
  [layout-xs="column"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="65"],
  [layout-xs="row"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="65"],
  [layout-xs="column"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="70"],
  [layout-xs="row"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="70"],
  [layout-xs="column"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="75"],
  [layout-xs="row"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="75"],
  [layout-xs="column"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="80"],
  [layout-xs="row"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="80"],
  [layout-xs="column"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="85"],
  [layout-xs="row"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="85"],
  [layout-xs="column"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="90"],
  [layout-xs="row"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="90"],
  [layout-xs="column"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="95"],
  [layout-xs="row"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="95"],
  [layout-xs="column"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="100"],
  [layout-xs="row"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="100"],
  [layout-xs="column"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="33"], [layout="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xs="66"], [layout="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="33"], [layout="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xs="66"], [layout="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-xs], [layout-xs="column"], [layout-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-xs="column"] {
    flex-direction: column;
  }
  [layout-xs="row"] {
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  [flex-order-gt-xs="-20"] {
    order: -20;
  }
  [flex-order-gt-xs="-19"] {
    order: -19;
  }
  [flex-order-gt-xs="-18"] {
    order: -18;
  }
  [flex-order-gt-xs="-17"] {
    order: -17;
  }
  [flex-order-gt-xs="-16"] {
    order: -16;
  }
  [flex-order-gt-xs="-15"] {
    order: -15;
  }
  [flex-order-gt-xs="-14"] {
    order: -14;
  }
  [flex-order-gt-xs="-13"] {
    order: -13;
  }
  [flex-order-gt-xs="-12"] {
    order: -12;
  }
  [flex-order-gt-xs="-11"] {
    order: -11;
  }
  [flex-order-gt-xs="-10"] {
    order: -10;
  }
  [flex-order-gt-xs="-9"] {
    order: -9;
  }
  [flex-order-gt-xs="-8"] {
    order: -8;
  }
  [flex-order-gt-xs="-7"] {
    order: -7;
  }
  [flex-order-gt-xs="-6"] {
    order: -6;
  }
  [flex-order-gt-xs="-5"] {
    order: -5;
  }
  [flex-order-gt-xs="-4"] {
    order: -4;
  }
  [flex-order-gt-xs="-3"] {
    order: -3;
  }
  [flex-order-gt-xs="-2"] {
    order: -2;
  }
  [flex-order-gt-xs="-1"] {
    order: -1;
  }
  [flex-order-gt-xs="0"] {
    order: 0;
  }
  [flex-order-gt-xs="1"] {
    order: 1;
  }
  [flex-order-gt-xs="2"] {
    order: 2;
  }
  [flex-order-gt-xs="3"] {
    order: 3;
  }
  [flex-order-gt-xs="4"] {
    order: 4;
  }
  [flex-order-gt-xs="5"] {
    order: 5;
  }
  [flex-order-gt-xs="6"] {
    order: 6;
  }
  [flex-order-gt-xs="7"] {
    order: 7;
  }
  [flex-order-gt-xs="8"] {
    order: 8;
  }
  [flex-order-gt-xs="9"] {
    order: 9;
  }
  [flex-order-gt-xs="10"] {
    order: 10;
  }
  [flex-order-gt-xs="11"] {
    order: 11;
  }
  [flex-order-gt-xs="12"] {
    order: 12;
  }
  [flex-order-gt-xs="13"] {
    order: 13;
  }
  [flex-order-gt-xs="14"] {
    order: 14;
  }
  [flex-order-gt-xs="15"] {
    order: 15;
  }
  [flex-order-gt-xs="16"] {
    order: 16;
  }
  [flex-order-gt-xs="17"] {
    order: 17;
  }
  [flex-order-gt-xs="18"] {
    order: 18;
  }
  [flex-order-gt-xs="19"] {
    order: 19;
  }
  [flex-order-gt-xs="20"] {
    order: 20;
  }
  [flex-offset-gt-xs="0"] {
    margin-left: 0%;
  }
  [flex-offset-gt-xs="5"] {
    margin-left: 5%;
  }
  [flex-offset-gt-xs="10"] {
    margin-left: 10%;
  }
  [flex-offset-gt-xs="15"] {
    margin-left: 15%;
  }
  [flex-offset-gt-xs="20"] {
    margin-left: 20%;
  }
  [flex-offset-gt-xs="25"] {
    margin-left: 25%;
  }
  [flex-offset-gt-xs="30"] {
    margin-left: 30%;
  }
  [flex-offset-gt-xs="35"] {
    margin-left: 35%;
  }
  [flex-offset-gt-xs="40"] {
    margin-left: 40%;
  }
  [flex-offset-gt-xs="45"] {
    margin-left: 45%;
  }
  [flex-offset-gt-xs="50"] {
    margin-left: 50%;
  }
  [flex-offset-gt-xs="55"] {
    margin-left: 55%;
  }
  [flex-offset-gt-xs="60"] {
    margin-left: 60%;
  }
  [flex-offset-gt-xs="65"] {
    margin-left: 65%;
  }
  [flex-offset-gt-xs="70"] {
    margin-left: 70%;
  }
  [flex-offset-gt-xs="75"] {
    margin-left: 75%;
  }
  [flex-offset-gt-xs="80"] {
    margin-left: 80%;
  }
  [flex-offset-gt-xs="85"] {
    margin-left: 85%;
  }
  [flex-offset-gt-xs="90"] {
    margin-left: 90%;
  }
  [flex-offset-gt-xs="95"] {
    margin-left: 95%;
  }
  [flex-offset-gt-xs="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-gt-xs="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-gt-xs],
  [layout-align-gt-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-gt-xs="start"],
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-gt-xs="center"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="center stretch"] {
    justify-content: center;
  }
  [layout-align-gt-xs="end"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-gt-xs="space-around"],
  [layout-align-gt-xs="space-around center"],
  [layout-align-gt-xs="space-around start"],
  [layout-align-gt-xs="space-around end"],
  [layout-align-gt-xs="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-gt-xs="space-between"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-gt-xs="start center"] > *,
  [layout-align-gt-xs="center center"] > *,
  [layout-align-gt-xs="end center"] > *,
  [layout-align-gt-xs="space-between center"] > *,
  [layout-align-gt-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-gt-xs="start stretch"],
  [layout-align-gt-xs="center stretch"],
  [layout-align-gt-xs="end stretch"],
  [layout-align-gt-xs="space-between stretch"],
  [layout-align-gt-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-gt-xs] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 600px) {
  [flex-gt-xs] {
    flex: 1 1 0%;
  }
}

@media (min-width: 600px) {
  [flex-gt-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-gt-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="0"],
  [layout-gt-xs="row"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="0"],
  [layout-gt-xs="column"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="5"],
  [layout-gt-xs="row"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="5"],
  [layout-gt-xs="column"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="10"],
  [layout-gt-xs="row"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="10"],
  [layout-gt-xs="column"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="15"],
  [layout-gt-xs="row"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="15"],
  [layout-gt-xs="column"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="20"],
  [layout-gt-xs="row"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="20"],
  [layout-gt-xs="column"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="25"],
  [layout-gt-xs="row"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="25"],
  [layout-gt-xs="column"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="30"],
  [layout-gt-xs="row"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="30"],
  [layout-gt-xs="column"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="35"],
  [layout-gt-xs="row"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="35"],
  [layout-gt-xs="column"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="40"],
  [layout-gt-xs="row"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="40"],
  [layout-gt-xs="column"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="45"],
  [layout-gt-xs="row"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="45"],
  [layout-gt-xs="column"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="50"],
  [layout-gt-xs="row"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="50"],
  [layout-gt-xs="column"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="55"],
  [layout-gt-xs="row"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="55"],
  [layout-gt-xs="column"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="60"],
  [layout-gt-xs="row"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="60"],
  [layout-gt-xs="column"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="65"],
  [layout-gt-xs="row"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="65"],
  [layout-gt-xs="column"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="70"],
  [layout-gt-xs="row"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="70"],
  [layout-gt-xs="column"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="75"],
  [layout-gt-xs="row"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="75"],
  [layout-gt-xs="column"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="80"],
  [layout-gt-xs="row"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="80"],
  [layout-gt-xs="column"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="85"],
  [layout-gt-xs="row"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="85"],
  [layout-gt-xs="column"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="90"],
  [layout-gt-xs="row"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="90"],
  [layout-gt-xs="column"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="95"],
  [layout-gt-xs="row"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="95"],
  [layout-gt-xs="column"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="100"],
  [layout-gt-xs="row"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="100"],
  [layout-gt-xs="column"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="33"], [layout="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-xs="66"], [layout="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="33"], [layout="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-xs="66"], [layout="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-gt-xs], [layout-gt-xs="column"], [layout-gt-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-gt-xs="column"] {
    flex-direction: column;
  }
  [layout-gt-xs="row"] {
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  [hide-sm]:not([show-gt-xs]):not([show-sm]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-sm]):not([show]) {
    display: none;
  }
  [hide-sm]:not([show-sm]):not([show]) {
    display: none;
  }
  [flex-order-sm="-20"] {
    order: -20;
  }
  [flex-order-sm="-19"] {
    order: -19;
  }
  [flex-order-sm="-18"] {
    order: -18;
  }
  [flex-order-sm="-17"] {
    order: -17;
  }
  [flex-order-sm="-16"] {
    order: -16;
  }
  [flex-order-sm="-15"] {
    order: -15;
  }
  [flex-order-sm="-14"] {
    order: -14;
  }
  [flex-order-sm="-13"] {
    order: -13;
  }
  [flex-order-sm="-12"] {
    order: -12;
  }
  [flex-order-sm="-11"] {
    order: -11;
  }
  [flex-order-sm="-10"] {
    order: -10;
  }
  [flex-order-sm="-9"] {
    order: -9;
  }
  [flex-order-sm="-8"] {
    order: -8;
  }
  [flex-order-sm="-7"] {
    order: -7;
  }
  [flex-order-sm="-6"] {
    order: -6;
  }
  [flex-order-sm="-5"] {
    order: -5;
  }
  [flex-order-sm="-4"] {
    order: -4;
  }
  [flex-order-sm="-3"] {
    order: -3;
  }
  [flex-order-sm="-2"] {
    order: -2;
  }
  [flex-order-sm="-1"] {
    order: -1;
  }
  [flex-order-sm="0"] {
    order: 0;
  }
  [flex-order-sm="1"] {
    order: 1;
  }
  [flex-order-sm="2"] {
    order: 2;
  }
  [flex-order-sm="3"] {
    order: 3;
  }
  [flex-order-sm="4"] {
    order: 4;
  }
  [flex-order-sm="5"] {
    order: 5;
  }
  [flex-order-sm="6"] {
    order: 6;
  }
  [flex-order-sm="7"] {
    order: 7;
  }
  [flex-order-sm="8"] {
    order: 8;
  }
  [flex-order-sm="9"] {
    order: 9;
  }
  [flex-order-sm="10"] {
    order: 10;
  }
  [flex-order-sm="11"] {
    order: 11;
  }
  [flex-order-sm="12"] {
    order: 12;
  }
  [flex-order-sm="13"] {
    order: 13;
  }
  [flex-order-sm="14"] {
    order: 14;
  }
  [flex-order-sm="15"] {
    order: 15;
  }
  [flex-order-sm="16"] {
    order: 16;
  }
  [flex-order-sm="17"] {
    order: 17;
  }
  [flex-order-sm="18"] {
    order: 18;
  }
  [flex-order-sm="19"] {
    order: 19;
  }
  [flex-order-sm="20"] {
    order: 20;
  }
  [flex-offset-sm="0"] {
    margin-left: 0%;
  }
  [flex-offset-sm="5"] {
    margin-left: 5%;
  }
  [flex-offset-sm="10"] {
    margin-left: 10%;
  }
  [flex-offset-sm="15"] {
    margin-left: 15%;
  }
  [flex-offset-sm="20"] {
    margin-left: 20%;
  }
  [flex-offset-sm="25"] {
    margin-left: 25%;
  }
  [flex-offset-sm="30"] {
    margin-left: 30%;
  }
  [flex-offset-sm="35"] {
    margin-left: 35%;
  }
  [flex-offset-sm="40"] {
    margin-left: 40%;
  }
  [flex-offset-sm="45"] {
    margin-left: 45%;
  }
  [flex-offset-sm="50"] {
    margin-left: 50%;
  }
  [flex-offset-sm="55"] {
    margin-left: 55%;
  }
  [flex-offset-sm="60"] {
    margin-left: 60%;
  }
  [flex-offset-sm="65"] {
    margin-left: 65%;
  }
  [flex-offset-sm="70"] {
    margin-left: 70%;
  }
  [flex-offset-sm="75"] {
    margin-left: 75%;
  }
  [flex-offset-sm="80"] {
    margin-left: 80%;
  }
  [flex-offset-sm="85"] {
    margin-left: 85%;
  }
  [flex-offset-sm="90"] {
    margin-left: 90%;
  }
  [flex-offset-sm="95"] {
    margin-left: 95%;
  }
  [flex-offset-sm="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-sm="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-sm],
  [layout-align-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-sm="start"],
  [layout-align-sm="start start"],
  [layout-align-sm="start center"],
  [layout-align-sm="start end"],
  [layout-align-sm="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-sm="center"],
  [layout-align-sm="center start"],
  [layout-align-sm="center center"],
  [layout-align-sm="center end"],
  [layout-align-sm="center stretch"] {
    justify-content: center;
  }
  [layout-align-sm="end"],
  [layout-align-sm="end center"],
  [layout-align-sm="end start"],
  [layout-align-sm="end end"],
  [layout-align-sm="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-sm="space-around"],
  [layout-align-sm="space-around center"],
  [layout-align-sm="space-around start"],
  [layout-align-sm="space-around end"],
  [layout-align-sm="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-sm="space-between"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-sm="start start"],
  [layout-align-sm="center start"],
  [layout-align-sm="end start"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-sm="start center"],
  [layout-align-sm="center center"],
  [layout-align-sm="end center"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-sm="start center"] > *,
  [layout-align-sm="center center"] > *,
  [layout-align-sm="end center"] > *,
  [layout-align-sm="space-between center"] > *,
  [layout-align-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-sm="start end"],
  [layout-align-sm="center end"],
  [layout-align-sm="end end"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-sm="start stretch"],
  [layout-align-sm="center stretch"],
  [layout-align-sm="end stretch"],
  [layout-align-sm="space-between stretch"],
  [layout-align-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-sm] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 600px) and (max-width: 959px) {
  [flex-sm] {
    flex: 1 1 0%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  [flex-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="0"],
  [layout-sm="row"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="0"],
  [layout-sm="column"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="5"],
  [layout-sm="row"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="5"],
  [layout-sm="column"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="10"],
  [layout-sm="row"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="10"],
  [layout-sm="column"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="15"],
  [layout-sm="row"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="15"],
  [layout-sm="column"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="20"],
  [layout-sm="row"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="20"],
  [layout-sm="column"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="25"],
  [layout-sm="row"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="25"],
  [layout-sm="column"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="30"],
  [layout-sm="row"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="30"],
  [layout-sm="column"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="35"],
  [layout-sm="row"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="35"],
  [layout-sm="column"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="40"],
  [layout-sm="row"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="40"],
  [layout-sm="column"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="45"],
  [layout-sm="row"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="45"],
  [layout-sm="column"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="50"],
  [layout-sm="row"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="50"],
  [layout-sm="column"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="55"],
  [layout-sm="row"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="55"],
  [layout-sm="column"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="60"],
  [layout-sm="row"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="60"],
  [layout-sm="column"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="65"],
  [layout-sm="row"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="65"],
  [layout-sm="column"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="70"],
  [layout-sm="row"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="70"],
  [layout-sm="column"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="75"],
  [layout-sm="row"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="75"],
  [layout-sm="column"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="80"],
  [layout-sm="row"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="80"],
  [layout-sm="column"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="85"],
  [layout-sm="row"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="85"],
  [layout-sm="column"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="90"],
  [layout-sm="row"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="90"],
  [layout-sm="column"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="95"],
  [layout-sm="row"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="95"],
  [layout-sm="column"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="100"],
  [layout-sm="row"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="100"],
  [layout-sm="column"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="33"], [layout="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-sm="66"], [layout="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="33"], [layout="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-sm="66"], [layout="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-sm], [layout-sm="column"], [layout-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-sm="column"] {
    flex-direction: column;
  }
  [layout-sm="row"] {
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  [flex-order-gt-sm="-20"] {
    order: -20;
  }
  [flex-order-gt-sm="-19"] {
    order: -19;
  }
  [flex-order-gt-sm="-18"] {
    order: -18;
  }
  [flex-order-gt-sm="-17"] {
    order: -17;
  }
  [flex-order-gt-sm="-16"] {
    order: -16;
  }
  [flex-order-gt-sm="-15"] {
    order: -15;
  }
  [flex-order-gt-sm="-14"] {
    order: -14;
  }
  [flex-order-gt-sm="-13"] {
    order: -13;
  }
  [flex-order-gt-sm="-12"] {
    order: -12;
  }
  [flex-order-gt-sm="-11"] {
    order: -11;
  }
  [flex-order-gt-sm="-10"] {
    order: -10;
  }
  [flex-order-gt-sm="-9"] {
    order: -9;
  }
  [flex-order-gt-sm="-8"] {
    order: -8;
  }
  [flex-order-gt-sm="-7"] {
    order: -7;
  }
  [flex-order-gt-sm="-6"] {
    order: -6;
  }
  [flex-order-gt-sm="-5"] {
    order: -5;
  }
  [flex-order-gt-sm="-4"] {
    order: -4;
  }
  [flex-order-gt-sm="-3"] {
    order: -3;
  }
  [flex-order-gt-sm="-2"] {
    order: -2;
  }
  [flex-order-gt-sm="-1"] {
    order: -1;
  }
  [flex-order-gt-sm="0"] {
    order: 0;
  }
  [flex-order-gt-sm="1"] {
    order: 1;
  }
  [flex-order-gt-sm="2"] {
    order: 2;
  }
  [flex-order-gt-sm="3"] {
    order: 3;
  }
  [flex-order-gt-sm="4"] {
    order: 4;
  }
  [flex-order-gt-sm="5"] {
    order: 5;
  }
  [flex-order-gt-sm="6"] {
    order: 6;
  }
  [flex-order-gt-sm="7"] {
    order: 7;
  }
  [flex-order-gt-sm="8"] {
    order: 8;
  }
  [flex-order-gt-sm="9"] {
    order: 9;
  }
  [flex-order-gt-sm="10"] {
    order: 10;
  }
  [flex-order-gt-sm="11"] {
    order: 11;
  }
  [flex-order-gt-sm="12"] {
    order: 12;
  }
  [flex-order-gt-sm="13"] {
    order: 13;
  }
  [flex-order-gt-sm="14"] {
    order: 14;
  }
  [flex-order-gt-sm="15"] {
    order: 15;
  }
  [flex-order-gt-sm="16"] {
    order: 16;
  }
  [flex-order-gt-sm="17"] {
    order: 17;
  }
  [flex-order-gt-sm="18"] {
    order: 18;
  }
  [flex-order-gt-sm="19"] {
    order: 19;
  }
  [flex-order-gt-sm="20"] {
    order: 20;
  }
  [flex-offset-gt-sm="0"] {
    margin-left: 0%;
  }
  [flex-offset-gt-sm="5"] {
    margin-left: 5%;
  }
  [flex-offset-gt-sm="10"] {
    margin-left: 10%;
  }
  [flex-offset-gt-sm="15"] {
    margin-left: 15%;
  }
  [flex-offset-gt-sm="20"] {
    margin-left: 20%;
  }
  [flex-offset-gt-sm="25"] {
    margin-left: 25%;
  }
  [flex-offset-gt-sm="30"] {
    margin-left: 30%;
  }
  [flex-offset-gt-sm="35"] {
    margin-left: 35%;
  }
  [flex-offset-gt-sm="40"] {
    margin-left: 40%;
  }
  [flex-offset-gt-sm="45"] {
    margin-left: 45%;
  }
  [flex-offset-gt-sm="50"] {
    margin-left: 50%;
  }
  [flex-offset-gt-sm="55"] {
    margin-left: 55%;
  }
  [flex-offset-gt-sm="60"] {
    margin-left: 60%;
  }
  [flex-offset-gt-sm="65"] {
    margin-left: 65%;
  }
  [flex-offset-gt-sm="70"] {
    margin-left: 70%;
  }
  [flex-offset-gt-sm="75"] {
    margin-left: 75%;
  }
  [flex-offset-gt-sm="80"] {
    margin-left: 80%;
  }
  [flex-offset-gt-sm="85"] {
    margin-left: 85%;
  }
  [flex-offset-gt-sm="90"] {
    margin-left: 90%;
  }
  [flex-offset-gt-sm="95"] {
    margin-left: 95%;
  }
  [flex-offset-gt-sm="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-gt-sm="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-gt-sm],
  [layout-align-gt-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-gt-sm="start"],
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-gt-sm="center"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="center stretch"] {
    justify-content: center;
  }
  [layout-align-gt-sm="end"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-gt-sm="space-around"],
  [layout-align-gt-sm="space-around center"],
  [layout-align-gt-sm="space-around start"],
  [layout-align-gt-sm="space-around end"],
  [layout-align-gt-sm="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-gt-sm="space-between"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-gt-sm="start center"] > *,
  [layout-align-gt-sm="center center"] > *,
  [layout-align-gt-sm="end center"] > *,
  [layout-align-gt-sm="space-between center"] > *,
  [layout-align-gt-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-gt-sm="start stretch"],
  [layout-align-gt-sm="center stretch"],
  [layout-align-gt-sm="end stretch"],
  [layout-align-gt-sm="space-between stretch"],
  [layout-align-gt-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-gt-sm] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 960px) {
  [flex-gt-sm] {
    flex: 1 1 0%;
  }
}

@media (min-width: 960px) {
  [flex-gt-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-gt-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="0"],
  [layout-gt-sm="row"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="0"],
  [layout-gt-sm="column"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="5"],
  [layout-gt-sm="row"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="5"],
  [layout-gt-sm="column"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="10"],
  [layout-gt-sm="row"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="10"],
  [layout-gt-sm="column"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="15"],
  [layout-gt-sm="row"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="15"],
  [layout-gt-sm="column"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="20"],
  [layout-gt-sm="row"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="20"],
  [layout-gt-sm="column"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="25"],
  [layout-gt-sm="row"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="25"],
  [layout-gt-sm="column"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="30"],
  [layout-gt-sm="row"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="30"],
  [layout-gt-sm="column"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="35"],
  [layout-gt-sm="row"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="35"],
  [layout-gt-sm="column"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="40"],
  [layout-gt-sm="row"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="40"],
  [layout-gt-sm="column"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="45"],
  [layout-gt-sm="row"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="45"],
  [layout-gt-sm="column"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="50"],
  [layout-gt-sm="row"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="50"],
  [layout-gt-sm="column"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="55"],
  [layout-gt-sm="row"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="55"],
  [layout-gt-sm="column"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="60"],
  [layout-gt-sm="row"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="60"],
  [layout-gt-sm="column"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="65"],
  [layout-gt-sm="row"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="65"],
  [layout-gt-sm="column"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="70"],
  [layout-gt-sm="row"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="70"],
  [layout-gt-sm="column"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="75"],
  [layout-gt-sm="row"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="75"],
  [layout-gt-sm="column"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="80"],
  [layout-gt-sm="row"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="80"],
  [layout-gt-sm="column"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="85"],
  [layout-gt-sm="row"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="85"],
  [layout-gt-sm="column"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="90"],
  [layout-gt-sm="row"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="90"],
  [layout-gt-sm="column"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="95"],
  [layout-gt-sm="row"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="95"],
  [layout-gt-sm="column"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="100"],
  [layout-gt-sm="row"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="100"],
  [layout-gt-sm="column"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="33"], [layout="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-sm="66"], [layout="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="33"], [layout="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-sm="66"], [layout="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-gt-sm], [layout-gt-sm="column"], [layout-gt-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-gt-sm="column"] {
    flex-direction: column;
  }
  [layout-gt-sm="row"] {
    flex-direction: row;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]) {
    display: none;
  }
  [hide-md]:not([show-md]):not([show]) {
    display: none;
  }
  [flex-order-md="-20"] {
    order: -20;
  }
  [flex-order-md="-19"] {
    order: -19;
  }
  [flex-order-md="-18"] {
    order: -18;
  }
  [flex-order-md="-17"] {
    order: -17;
  }
  [flex-order-md="-16"] {
    order: -16;
  }
  [flex-order-md="-15"] {
    order: -15;
  }
  [flex-order-md="-14"] {
    order: -14;
  }
  [flex-order-md="-13"] {
    order: -13;
  }
  [flex-order-md="-12"] {
    order: -12;
  }
  [flex-order-md="-11"] {
    order: -11;
  }
  [flex-order-md="-10"] {
    order: -10;
  }
  [flex-order-md="-9"] {
    order: -9;
  }
  [flex-order-md="-8"] {
    order: -8;
  }
  [flex-order-md="-7"] {
    order: -7;
  }
  [flex-order-md="-6"] {
    order: -6;
  }
  [flex-order-md="-5"] {
    order: -5;
  }
  [flex-order-md="-4"] {
    order: -4;
  }
  [flex-order-md="-3"] {
    order: -3;
  }
  [flex-order-md="-2"] {
    order: -2;
  }
  [flex-order-md="-1"] {
    order: -1;
  }
  [flex-order-md="0"] {
    order: 0;
  }
  [flex-order-md="1"] {
    order: 1;
  }
  [flex-order-md="2"] {
    order: 2;
  }
  [flex-order-md="3"] {
    order: 3;
  }
  [flex-order-md="4"] {
    order: 4;
  }
  [flex-order-md="5"] {
    order: 5;
  }
  [flex-order-md="6"] {
    order: 6;
  }
  [flex-order-md="7"] {
    order: 7;
  }
  [flex-order-md="8"] {
    order: 8;
  }
  [flex-order-md="9"] {
    order: 9;
  }
  [flex-order-md="10"] {
    order: 10;
  }
  [flex-order-md="11"] {
    order: 11;
  }
  [flex-order-md="12"] {
    order: 12;
  }
  [flex-order-md="13"] {
    order: 13;
  }
  [flex-order-md="14"] {
    order: 14;
  }
  [flex-order-md="15"] {
    order: 15;
  }
  [flex-order-md="16"] {
    order: 16;
  }
  [flex-order-md="17"] {
    order: 17;
  }
  [flex-order-md="18"] {
    order: 18;
  }
  [flex-order-md="19"] {
    order: 19;
  }
  [flex-order-md="20"] {
    order: 20;
  }
  [flex-offset-md="0"] {
    margin-left: 0%;
  }
  [flex-offset-md="5"] {
    margin-left: 5%;
  }
  [flex-offset-md="10"] {
    margin-left: 10%;
  }
  [flex-offset-md="15"] {
    margin-left: 15%;
  }
  [flex-offset-md="20"] {
    margin-left: 20%;
  }
  [flex-offset-md="25"] {
    margin-left: 25%;
  }
  [flex-offset-md="30"] {
    margin-left: 30%;
  }
  [flex-offset-md="35"] {
    margin-left: 35%;
  }
  [flex-offset-md="40"] {
    margin-left: 40%;
  }
  [flex-offset-md="45"] {
    margin-left: 45%;
  }
  [flex-offset-md="50"] {
    margin-left: 50%;
  }
  [flex-offset-md="55"] {
    margin-left: 55%;
  }
  [flex-offset-md="60"] {
    margin-left: 60%;
  }
  [flex-offset-md="65"] {
    margin-left: 65%;
  }
  [flex-offset-md="70"] {
    margin-left: 70%;
  }
  [flex-offset-md="75"] {
    margin-left: 75%;
  }
  [flex-offset-md="80"] {
    margin-left: 80%;
  }
  [flex-offset-md="85"] {
    margin-left: 85%;
  }
  [flex-offset-md="90"] {
    margin-left: 90%;
  }
  [flex-offset-md="95"] {
    margin-left: 95%;
  }
  [flex-offset-md="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-md="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-md],
  [layout-align-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-md="start"],
  [layout-align-md="start start"],
  [layout-align-md="start center"],
  [layout-align-md="start end"],
  [layout-align-md="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-md="center"],
  [layout-align-md="center start"],
  [layout-align-md="center center"],
  [layout-align-md="center end"],
  [layout-align-md="center stretch"] {
    justify-content: center;
  }
  [layout-align-md="end"],
  [layout-align-md="end center"],
  [layout-align-md="end start"],
  [layout-align-md="end end"],
  [layout-align-md="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-md="space-around"],
  [layout-align-md="space-around center"],
  [layout-align-md="space-around start"],
  [layout-align-md="space-around end"],
  [layout-align-md="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-md="space-between"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-md="start start"],
  [layout-align-md="center start"],
  [layout-align-md="end start"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-md="start center"],
  [layout-align-md="center center"],
  [layout-align-md="end center"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-md="start center"] > *,
  [layout-align-md="center center"] > *,
  [layout-align-md="end center"] > *,
  [layout-align-md="space-between center"] > *,
  [layout-align-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-md="start end"],
  [layout-align-md="center end"],
  [layout-align-md="end end"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-md="start stretch"],
  [layout-align-md="center stretch"],
  [layout-align-md="end stretch"],
  [layout-align-md="space-between stretch"],
  [layout-align-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-md] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 960px) and (max-width: 1279px) {
  [flex-md] {
    flex: 1 1 0%;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  [flex-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="0"],
  [layout-md="row"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="0"],
  [layout-md="column"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="5"],
  [layout-md="row"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="5"],
  [layout-md="column"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="10"],
  [layout-md="row"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="10"],
  [layout-md="column"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="15"],
  [layout-md="row"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="15"],
  [layout-md="column"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="20"],
  [layout-md="row"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="20"],
  [layout-md="column"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="25"],
  [layout-md="row"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="25"],
  [layout-md="column"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="30"],
  [layout-md="row"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="30"],
  [layout-md="column"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="35"],
  [layout-md="row"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="35"],
  [layout-md="column"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="40"],
  [layout-md="row"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="40"],
  [layout-md="column"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="45"],
  [layout-md="row"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="45"],
  [layout-md="column"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="50"],
  [layout-md="row"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="50"],
  [layout-md="column"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="55"],
  [layout-md="row"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="55"],
  [layout-md="column"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="60"],
  [layout-md="row"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="60"],
  [layout-md="column"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="65"],
  [layout-md="row"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="65"],
  [layout-md="column"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="70"],
  [layout-md="row"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="70"],
  [layout-md="column"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="75"],
  [layout-md="row"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="75"],
  [layout-md="column"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="80"],
  [layout-md="row"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="80"],
  [layout-md="column"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="85"],
  [layout-md="row"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="85"],
  [layout-md="column"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="90"],
  [layout-md="row"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="90"],
  [layout-md="column"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="95"],
  [layout-md="row"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="95"],
  [layout-md="column"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="100"],
  [layout-md="row"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="100"],
  [layout-md="column"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="33"], [layout="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-md="66"], [layout="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="33"], [layout="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-md="66"], [layout="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-md], [layout-md="column"], [layout-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-md="column"] {
    flex-direction: column;
  }
  [layout-md="row"] {
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  [flex-order-gt-md="-20"] {
    order: -20;
  }
  [flex-order-gt-md="-19"] {
    order: -19;
  }
  [flex-order-gt-md="-18"] {
    order: -18;
  }
  [flex-order-gt-md="-17"] {
    order: -17;
  }
  [flex-order-gt-md="-16"] {
    order: -16;
  }
  [flex-order-gt-md="-15"] {
    order: -15;
  }
  [flex-order-gt-md="-14"] {
    order: -14;
  }
  [flex-order-gt-md="-13"] {
    order: -13;
  }
  [flex-order-gt-md="-12"] {
    order: -12;
  }
  [flex-order-gt-md="-11"] {
    order: -11;
  }
  [flex-order-gt-md="-10"] {
    order: -10;
  }
  [flex-order-gt-md="-9"] {
    order: -9;
  }
  [flex-order-gt-md="-8"] {
    order: -8;
  }
  [flex-order-gt-md="-7"] {
    order: -7;
  }
  [flex-order-gt-md="-6"] {
    order: -6;
  }
  [flex-order-gt-md="-5"] {
    order: -5;
  }
  [flex-order-gt-md="-4"] {
    order: -4;
  }
  [flex-order-gt-md="-3"] {
    order: -3;
  }
  [flex-order-gt-md="-2"] {
    order: -2;
  }
  [flex-order-gt-md="-1"] {
    order: -1;
  }
  [flex-order-gt-md="0"] {
    order: 0;
  }
  [flex-order-gt-md="1"] {
    order: 1;
  }
  [flex-order-gt-md="2"] {
    order: 2;
  }
  [flex-order-gt-md="3"] {
    order: 3;
  }
  [flex-order-gt-md="4"] {
    order: 4;
  }
  [flex-order-gt-md="5"] {
    order: 5;
  }
  [flex-order-gt-md="6"] {
    order: 6;
  }
  [flex-order-gt-md="7"] {
    order: 7;
  }
  [flex-order-gt-md="8"] {
    order: 8;
  }
  [flex-order-gt-md="9"] {
    order: 9;
  }
  [flex-order-gt-md="10"] {
    order: 10;
  }
  [flex-order-gt-md="11"] {
    order: 11;
  }
  [flex-order-gt-md="12"] {
    order: 12;
  }
  [flex-order-gt-md="13"] {
    order: 13;
  }
  [flex-order-gt-md="14"] {
    order: 14;
  }
  [flex-order-gt-md="15"] {
    order: 15;
  }
  [flex-order-gt-md="16"] {
    order: 16;
  }
  [flex-order-gt-md="17"] {
    order: 17;
  }
  [flex-order-gt-md="18"] {
    order: 18;
  }
  [flex-order-gt-md="19"] {
    order: 19;
  }
  [flex-order-gt-md="20"] {
    order: 20;
  }
  [flex-offset-gt-md="0"] {
    margin-left: 0%;
  }
  [flex-offset-gt-md="5"] {
    margin-left: 5%;
  }
  [flex-offset-gt-md="10"] {
    margin-left: 10%;
  }
  [flex-offset-gt-md="15"] {
    margin-left: 15%;
  }
  [flex-offset-gt-md="20"] {
    margin-left: 20%;
  }
  [flex-offset-gt-md="25"] {
    margin-left: 25%;
  }
  [flex-offset-gt-md="30"] {
    margin-left: 30%;
  }
  [flex-offset-gt-md="35"] {
    margin-left: 35%;
  }
  [flex-offset-gt-md="40"] {
    margin-left: 40%;
  }
  [flex-offset-gt-md="45"] {
    margin-left: 45%;
  }
  [flex-offset-gt-md="50"] {
    margin-left: 50%;
  }
  [flex-offset-gt-md="55"] {
    margin-left: 55%;
  }
  [flex-offset-gt-md="60"] {
    margin-left: 60%;
  }
  [flex-offset-gt-md="65"] {
    margin-left: 65%;
  }
  [flex-offset-gt-md="70"] {
    margin-left: 70%;
  }
  [flex-offset-gt-md="75"] {
    margin-left: 75%;
  }
  [flex-offset-gt-md="80"] {
    margin-left: 80%;
  }
  [flex-offset-gt-md="85"] {
    margin-left: 85%;
  }
  [flex-offset-gt-md="90"] {
    margin-left: 90%;
  }
  [flex-offset-gt-md="95"] {
    margin-left: 95%;
  }
  [flex-offset-gt-md="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-gt-md="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-gt-md],
  [layout-align-gt-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-gt-md="start"],
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-gt-md="center"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="center stretch"] {
    justify-content: center;
  }
  [layout-align-gt-md="end"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-gt-md="space-around"],
  [layout-align-gt-md="space-around center"],
  [layout-align-gt-md="space-around start"],
  [layout-align-gt-md="space-around end"],
  [layout-align-gt-md="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-gt-md="space-between"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-gt-md="start center"] > *,
  [layout-align-gt-md="center center"] > *,
  [layout-align-gt-md="end center"] > *,
  [layout-align-gt-md="space-between center"] > *,
  [layout-align-gt-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-gt-md="start stretch"],
  [layout-align-gt-md="center stretch"],
  [layout-align-gt-md="end stretch"],
  [layout-align-gt-md="space-between stretch"],
  [layout-align-gt-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-gt-md] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1280px) {
  [flex-gt-md] {
    flex: 1 1 0%;
  }
}

@media (min-width: 1280px) {
  [flex-gt-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-gt-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="0"],
  [layout-gt-md="row"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="0"],
  [layout-gt-md="column"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="5"],
  [layout-gt-md="row"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="5"],
  [layout-gt-md="column"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="10"],
  [layout-gt-md="row"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="10"],
  [layout-gt-md="column"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="15"],
  [layout-gt-md="row"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="15"],
  [layout-gt-md="column"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="20"],
  [layout-gt-md="row"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="20"],
  [layout-gt-md="column"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="25"],
  [layout-gt-md="row"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="25"],
  [layout-gt-md="column"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="30"],
  [layout-gt-md="row"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="30"],
  [layout-gt-md="column"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="35"],
  [layout-gt-md="row"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="35"],
  [layout-gt-md="column"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="40"],
  [layout-gt-md="row"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="40"],
  [layout-gt-md="column"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="45"],
  [layout-gt-md="row"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="45"],
  [layout-gt-md="column"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="50"],
  [layout-gt-md="row"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="50"],
  [layout-gt-md="column"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="55"],
  [layout-gt-md="row"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="55"],
  [layout-gt-md="column"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="60"],
  [layout-gt-md="row"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="60"],
  [layout-gt-md="column"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="65"],
  [layout-gt-md="row"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="65"],
  [layout-gt-md="column"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="70"],
  [layout-gt-md="row"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="70"],
  [layout-gt-md="column"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="75"],
  [layout-gt-md="row"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="75"],
  [layout-gt-md="column"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="80"],
  [layout-gt-md="row"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="80"],
  [layout-gt-md="column"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="85"],
  [layout-gt-md="row"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="85"],
  [layout-gt-md="column"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="90"],
  [layout-gt-md="row"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="90"],
  [layout-gt-md="column"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="95"],
  [layout-gt-md="row"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="95"],
  [layout-gt-md="column"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="100"],
  [layout-gt-md="row"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="100"],
  [layout-gt-md="column"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="33"], [layout="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-md="66"], [layout="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="33"], [layout="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-md="66"], [layout="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-gt-md], [layout-gt-md="column"], [layout-gt-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-gt-md="column"] {
    flex-direction: column;
  }
  [layout-gt-md="row"] {
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]) {
    display: none;
  }
  [hide-lg]:not([show-lg]):not([show]) {
    display: none;
  }
  [flex-order-lg="-20"] {
    order: -20;
  }
  [flex-order-lg="-19"] {
    order: -19;
  }
  [flex-order-lg="-18"] {
    order: -18;
  }
  [flex-order-lg="-17"] {
    order: -17;
  }
  [flex-order-lg="-16"] {
    order: -16;
  }
  [flex-order-lg="-15"] {
    order: -15;
  }
  [flex-order-lg="-14"] {
    order: -14;
  }
  [flex-order-lg="-13"] {
    order: -13;
  }
  [flex-order-lg="-12"] {
    order: -12;
  }
  [flex-order-lg="-11"] {
    order: -11;
  }
  [flex-order-lg="-10"] {
    order: -10;
  }
  [flex-order-lg="-9"] {
    order: -9;
  }
  [flex-order-lg="-8"] {
    order: -8;
  }
  [flex-order-lg="-7"] {
    order: -7;
  }
  [flex-order-lg="-6"] {
    order: -6;
  }
  [flex-order-lg="-5"] {
    order: -5;
  }
  [flex-order-lg="-4"] {
    order: -4;
  }
  [flex-order-lg="-3"] {
    order: -3;
  }
  [flex-order-lg="-2"] {
    order: -2;
  }
  [flex-order-lg="-1"] {
    order: -1;
  }
  [flex-order-lg="0"] {
    order: 0;
  }
  [flex-order-lg="1"] {
    order: 1;
  }
  [flex-order-lg="2"] {
    order: 2;
  }
  [flex-order-lg="3"] {
    order: 3;
  }
  [flex-order-lg="4"] {
    order: 4;
  }
  [flex-order-lg="5"] {
    order: 5;
  }
  [flex-order-lg="6"] {
    order: 6;
  }
  [flex-order-lg="7"] {
    order: 7;
  }
  [flex-order-lg="8"] {
    order: 8;
  }
  [flex-order-lg="9"] {
    order: 9;
  }
  [flex-order-lg="10"] {
    order: 10;
  }
  [flex-order-lg="11"] {
    order: 11;
  }
  [flex-order-lg="12"] {
    order: 12;
  }
  [flex-order-lg="13"] {
    order: 13;
  }
  [flex-order-lg="14"] {
    order: 14;
  }
  [flex-order-lg="15"] {
    order: 15;
  }
  [flex-order-lg="16"] {
    order: 16;
  }
  [flex-order-lg="17"] {
    order: 17;
  }
  [flex-order-lg="18"] {
    order: 18;
  }
  [flex-order-lg="19"] {
    order: 19;
  }
  [flex-order-lg="20"] {
    order: 20;
  }
  [flex-offset-lg="0"] {
    margin-left: 0%;
  }
  [flex-offset-lg="5"] {
    margin-left: 5%;
  }
  [flex-offset-lg="10"] {
    margin-left: 10%;
  }
  [flex-offset-lg="15"] {
    margin-left: 15%;
  }
  [flex-offset-lg="20"] {
    margin-left: 20%;
  }
  [flex-offset-lg="25"] {
    margin-left: 25%;
  }
  [flex-offset-lg="30"] {
    margin-left: 30%;
  }
  [flex-offset-lg="35"] {
    margin-left: 35%;
  }
  [flex-offset-lg="40"] {
    margin-left: 40%;
  }
  [flex-offset-lg="45"] {
    margin-left: 45%;
  }
  [flex-offset-lg="50"] {
    margin-left: 50%;
  }
  [flex-offset-lg="55"] {
    margin-left: 55%;
  }
  [flex-offset-lg="60"] {
    margin-left: 60%;
  }
  [flex-offset-lg="65"] {
    margin-left: 65%;
  }
  [flex-offset-lg="70"] {
    margin-left: 70%;
  }
  [flex-offset-lg="75"] {
    margin-left: 75%;
  }
  [flex-offset-lg="80"] {
    margin-left: 80%;
  }
  [flex-offset-lg="85"] {
    margin-left: 85%;
  }
  [flex-offset-lg="90"] {
    margin-left: 90%;
  }
  [flex-offset-lg="95"] {
    margin-left: 95%;
  }
  [flex-offset-lg="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-lg="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-lg],
  [layout-align-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-lg="start"],
  [layout-align-lg="start start"],
  [layout-align-lg="start center"],
  [layout-align-lg="start end"],
  [layout-align-lg="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-lg="center"],
  [layout-align-lg="center start"],
  [layout-align-lg="center center"],
  [layout-align-lg="center end"],
  [layout-align-lg="center stretch"] {
    justify-content: center;
  }
  [layout-align-lg="end"],
  [layout-align-lg="end center"],
  [layout-align-lg="end start"],
  [layout-align-lg="end end"],
  [layout-align-lg="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-lg="space-around"],
  [layout-align-lg="space-around center"],
  [layout-align-lg="space-around start"],
  [layout-align-lg="space-around end"],
  [layout-align-lg="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-lg="space-between"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-lg="start start"],
  [layout-align-lg="center start"],
  [layout-align-lg="end start"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-lg="start center"],
  [layout-align-lg="center center"],
  [layout-align-lg="end center"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-lg="start center"] > *,
  [layout-align-lg="center center"] > *,
  [layout-align-lg="end center"] > *,
  [layout-align-lg="space-between center"] > *,
  [layout-align-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-lg="start end"],
  [layout-align-lg="center end"],
  [layout-align-lg="end end"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-lg="start stretch"],
  [layout-align-lg="center stretch"],
  [layout-align-lg="end stretch"],
  [layout-align-lg="space-between stretch"],
  [layout-align-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-lg] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1280px) and (max-width: 1919px) {
  [flex-lg] {
    flex: 1 1 0%;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  [flex-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="0"],
  [layout-lg="row"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="0"],
  [layout-lg="column"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="5"],
  [layout-lg="row"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="5"],
  [layout-lg="column"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="10"],
  [layout-lg="row"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="10"],
  [layout-lg="column"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="15"],
  [layout-lg="row"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="15"],
  [layout-lg="column"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="20"],
  [layout-lg="row"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="20"],
  [layout-lg="column"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="25"],
  [layout-lg="row"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="25"],
  [layout-lg="column"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="30"],
  [layout-lg="row"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="30"],
  [layout-lg="column"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="35"],
  [layout-lg="row"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="35"],
  [layout-lg="column"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="40"],
  [layout-lg="row"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="40"],
  [layout-lg="column"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="45"],
  [layout-lg="row"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="45"],
  [layout-lg="column"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="50"],
  [layout-lg="row"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="50"],
  [layout-lg="column"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="55"],
  [layout-lg="row"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="55"],
  [layout-lg="column"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="60"],
  [layout-lg="row"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="60"],
  [layout-lg="column"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="65"],
  [layout-lg="row"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="65"],
  [layout-lg="column"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="70"],
  [layout-lg="row"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="70"],
  [layout-lg="column"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="75"],
  [layout-lg="row"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="75"],
  [layout-lg="column"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="80"],
  [layout-lg="row"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="80"],
  [layout-lg="column"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="85"],
  [layout-lg="row"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="85"],
  [layout-lg="column"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="90"],
  [layout-lg="row"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="90"],
  [layout-lg="column"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="95"],
  [layout-lg="row"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="95"],
  [layout-lg="column"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="100"],
  [layout-lg="row"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="100"],
  [layout-lg="column"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="33"], [layout="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-lg="66"], [layout="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="33"], [layout="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-lg="66"], [layout="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-lg], [layout-lg="column"], [layout-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-lg="column"] {
    flex-direction: column;
  }
  [layout-lg="row"] {
    flex-direction: row;
  }
}

@media (min-width: 1920px) {
  [flex-order-gt-lg="-20"] {
    order: -20;
  }
  [flex-order-gt-lg="-19"] {
    order: -19;
  }
  [flex-order-gt-lg="-18"] {
    order: -18;
  }
  [flex-order-gt-lg="-17"] {
    order: -17;
  }
  [flex-order-gt-lg="-16"] {
    order: -16;
  }
  [flex-order-gt-lg="-15"] {
    order: -15;
  }
  [flex-order-gt-lg="-14"] {
    order: -14;
  }
  [flex-order-gt-lg="-13"] {
    order: -13;
  }
  [flex-order-gt-lg="-12"] {
    order: -12;
  }
  [flex-order-gt-lg="-11"] {
    order: -11;
  }
  [flex-order-gt-lg="-10"] {
    order: -10;
  }
  [flex-order-gt-lg="-9"] {
    order: -9;
  }
  [flex-order-gt-lg="-8"] {
    order: -8;
  }
  [flex-order-gt-lg="-7"] {
    order: -7;
  }
  [flex-order-gt-lg="-6"] {
    order: -6;
  }
  [flex-order-gt-lg="-5"] {
    order: -5;
  }
  [flex-order-gt-lg="-4"] {
    order: -4;
  }
  [flex-order-gt-lg="-3"] {
    order: -3;
  }
  [flex-order-gt-lg="-2"] {
    order: -2;
  }
  [flex-order-gt-lg="-1"] {
    order: -1;
  }
  [flex-order-gt-lg="0"] {
    order: 0;
  }
  [flex-order-gt-lg="1"] {
    order: 1;
  }
  [flex-order-gt-lg="2"] {
    order: 2;
  }
  [flex-order-gt-lg="3"] {
    order: 3;
  }
  [flex-order-gt-lg="4"] {
    order: 4;
  }
  [flex-order-gt-lg="5"] {
    order: 5;
  }
  [flex-order-gt-lg="6"] {
    order: 6;
  }
  [flex-order-gt-lg="7"] {
    order: 7;
  }
  [flex-order-gt-lg="8"] {
    order: 8;
  }
  [flex-order-gt-lg="9"] {
    order: 9;
  }
  [flex-order-gt-lg="10"] {
    order: 10;
  }
  [flex-order-gt-lg="11"] {
    order: 11;
  }
  [flex-order-gt-lg="12"] {
    order: 12;
  }
  [flex-order-gt-lg="13"] {
    order: 13;
  }
  [flex-order-gt-lg="14"] {
    order: 14;
  }
  [flex-order-gt-lg="15"] {
    order: 15;
  }
  [flex-order-gt-lg="16"] {
    order: 16;
  }
  [flex-order-gt-lg="17"] {
    order: 17;
  }
  [flex-order-gt-lg="18"] {
    order: 18;
  }
  [flex-order-gt-lg="19"] {
    order: 19;
  }
  [flex-order-gt-lg="20"] {
    order: 20;
  }
  [flex-offset-gt-lg="0"] {
    margin-left: 0%;
  }
  [flex-offset-gt-lg="5"] {
    margin-left: 5%;
  }
  [flex-offset-gt-lg="10"] {
    margin-left: 10%;
  }
  [flex-offset-gt-lg="15"] {
    margin-left: 15%;
  }
  [flex-offset-gt-lg="20"] {
    margin-left: 20%;
  }
  [flex-offset-gt-lg="25"] {
    margin-left: 25%;
  }
  [flex-offset-gt-lg="30"] {
    margin-left: 30%;
  }
  [flex-offset-gt-lg="35"] {
    margin-left: 35%;
  }
  [flex-offset-gt-lg="40"] {
    margin-left: 40%;
  }
  [flex-offset-gt-lg="45"] {
    margin-left: 45%;
  }
  [flex-offset-gt-lg="50"] {
    margin-left: 50%;
  }
  [flex-offset-gt-lg="55"] {
    margin-left: 55%;
  }
  [flex-offset-gt-lg="60"] {
    margin-left: 60%;
  }
  [flex-offset-gt-lg="65"] {
    margin-left: 65%;
  }
  [flex-offset-gt-lg="70"] {
    margin-left: 70%;
  }
  [flex-offset-gt-lg="75"] {
    margin-left: 75%;
  }
  [flex-offset-gt-lg="80"] {
    margin-left: 80%;
  }
  [flex-offset-gt-lg="85"] {
    margin-left: 85%;
  }
  [flex-offset-gt-lg="90"] {
    margin-left: 90%;
  }
  [flex-offset-gt-lg="95"] {
    margin-left: 95%;
  }
  [flex-offset-gt-lg="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-gt-lg="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-gt-lg],
  [layout-align-gt-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-gt-lg="start"],
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-gt-lg="center"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="center stretch"] {
    justify-content: center;
  }
  [layout-align-gt-lg="end"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-gt-lg="space-around"],
  [layout-align-gt-lg="space-around center"],
  [layout-align-gt-lg="space-around start"],
  [layout-align-gt-lg="space-around end"],
  [layout-align-gt-lg="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-gt-lg="space-between"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-gt-lg="start center"] > *,
  [layout-align-gt-lg="center center"] > *,
  [layout-align-gt-lg="end center"] > *,
  [layout-align-gt-lg="space-between center"] > *,
  [layout-align-gt-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-gt-lg="start stretch"],
  [layout-align-gt-lg="center stretch"],
  [layout-align-gt-lg="end stretch"],
  [layout-align-gt-lg="space-between stretch"],
  [layout-align-gt-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-gt-lg] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1920px) {
  [flex-gt-lg] {
    flex: 1 1 0%;
  }
}

@media (min-width: 1920px) {
  [flex-gt-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-gt-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-gt-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="0"],
  [layout-gt-lg="row"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="0"],
  [layout-gt-lg="column"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="5"],
  [layout-gt-lg="row"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="5"],
  [layout-gt-lg="column"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="10"],
  [layout-gt-lg="row"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="10"],
  [layout-gt-lg="column"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="15"],
  [layout-gt-lg="row"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="15"],
  [layout-gt-lg="column"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="20"],
  [layout-gt-lg="row"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="20"],
  [layout-gt-lg="column"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="25"],
  [layout-gt-lg="row"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="25"],
  [layout-gt-lg="column"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="30"],
  [layout-gt-lg="row"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="30"],
  [layout-gt-lg="column"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="35"],
  [layout-gt-lg="row"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="35"],
  [layout-gt-lg="column"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="40"],
  [layout-gt-lg="row"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="40"],
  [layout-gt-lg="column"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="45"],
  [layout-gt-lg="row"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="45"],
  [layout-gt-lg="column"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="50"],
  [layout-gt-lg="row"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="50"],
  [layout-gt-lg="column"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="55"],
  [layout-gt-lg="row"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="55"],
  [layout-gt-lg="column"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="60"],
  [layout-gt-lg="row"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="60"],
  [layout-gt-lg="column"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="65"],
  [layout-gt-lg="row"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="65"],
  [layout-gt-lg="column"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="70"],
  [layout-gt-lg="row"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="70"],
  [layout-gt-lg="column"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="75"],
  [layout-gt-lg="row"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="75"],
  [layout-gt-lg="column"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="80"],
  [layout-gt-lg="row"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="80"],
  [layout-gt-lg="column"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="85"],
  [layout-gt-lg="row"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="85"],
  [layout-gt-lg="column"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="90"],
  [layout-gt-lg="row"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="90"],
  [layout-gt-lg="column"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="95"],
  [layout-gt-lg="row"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="95"],
  [layout-gt-lg="column"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="100"],
  [layout-gt-lg="row"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="100"],
  [layout-gt-lg="column"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="33"], [layout="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-gt-lg="66"], [layout="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="33"], [layout="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-gt-lg="66"], [layout="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-gt-lg], [layout-gt-lg="column"], [layout-gt-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-gt-lg="column"] {
    flex-direction: column;
  }
  [layout-gt-lg="row"] {
    flex-direction: row;
  }
  [flex-order-xl="-20"] {
    order: -20;
  }
  [flex-order-xl="-19"] {
    order: -19;
  }
  [flex-order-xl="-18"] {
    order: -18;
  }
  [flex-order-xl="-17"] {
    order: -17;
  }
  [flex-order-xl="-16"] {
    order: -16;
  }
  [flex-order-xl="-15"] {
    order: -15;
  }
  [flex-order-xl="-14"] {
    order: -14;
  }
  [flex-order-xl="-13"] {
    order: -13;
  }
  [flex-order-xl="-12"] {
    order: -12;
  }
  [flex-order-xl="-11"] {
    order: -11;
  }
  [flex-order-xl="-10"] {
    order: -10;
  }
  [flex-order-xl="-9"] {
    order: -9;
  }
  [flex-order-xl="-8"] {
    order: -8;
  }
  [flex-order-xl="-7"] {
    order: -7;
  }
  [flex-order-xl="-6"] {
    order: -6;
  }
  [flex-order-xl="-5"] {
    order: -5;
  }
  [flex-order-xl="-4"] {
    order: -4;
  }
  [flex-order-xl="-3"] {
    order: -3;
  }
  [flex-order-xl="-2"] {
    order: -2;
  }
  [flex-order-xl="-1"] {
    order: -1;
  }
  [flex-order-xl="0"] {
    order: 0;
  }
  [flex-order-xl="1"] {
    order: 1;
  }
  [flex-order-xl="2"] {
    order: 2;
  }
  [flex-order-xl="3"] {
    order: 3;
  }
  [flex-order-xl="4"] {
    order: 4;
  }
  [flex-order-xl="5"] {
    order: 5;
  }
  [flex-order-xl="6"] {
    order: 6;
  }
  [flex-order-xl="7"] {
    order: 7;
  }
  [flex-order-xl="8"] {
    order: 8;
  }
  [flex-order-xl="9"] {
    order: 9;
  }
  [flex-order-xl="10"] {
    order: 10;
  }
  [flex-order-xl="11"] {
    order: 11;
  }
  [flex-order-xl="12"] {
    order: 12;
  }
  [flex-order-xl="13"] {
    order: 13;
  }
  [flex-order-xl="14"] {
    order: 14;
  }
  [flex-order-xl="15"] {
    order: 15;
  }
  [flex-order-xl="16"] {
    order: 16;
  }
  [flex-order-xl="17"] {
    order: 17;
  }
  [flex-order-xl="18"] {
    order: 18;
  }
  [flex-order-xl="19"] {
    order: 19;
  }
  [flex-order-xl="20"] {
    order: 20;
  }
  [flex-offset-xl="0"] {
    margin-left: 0%;
  }
  [flex-offset-xl="5"] {
    margin-left: 5%;
  }
  [flex-offset-xl="10"] {
    margin-left: 10%;
  }
  [flex-offset-xl="15"] {
    margin-left: 15%;
  }
  [flex-offset-xl="20"] {
    margin-left: 20%;
  }
  [flex-offset-xl="25"] {
    margin-left: 25%;
  }
  [flex-offset-xl="30"] {
    margin-left: 30%;
  }
  [flex-offset-xl="35"] {
    margin-left: 35%;
  }
  [flex-offset-xl="40"] {
    margin-left: 40%;
  }
  [flex-offset-xl="45"] {
    margin-left: 45%;
  }
  [flex-offset-xl="50"] {
    margin-left: 50%;
  }
  [flex-offset-xl="55"] {
    margin-left: 55%;
  }
  [flex-offset-xl="60"] {
    margin-left: 60%;
  }
  [flex-offset-xl="65"] {
    margin-left: 65%;
  }
  [flex-offset-xl="70"] {
    margin-left: 70%;
  }
  [flex-offset-xl="75"] {
    margin-left: 75%;
  }
  [flex-offset-xl="80"] {
    margin-left: 80%;
  }
  [flex-offset-xl="85"] {
    margin-left: 85%;
  }
  [flex-offset-xl="90"] {
    margin-left: 90%;
  }
  [flex-offset-xl="95"] {
    margin-left: 95%;
  }
  [flex-offset-xl="33"] {
    margin-left: calc(100% / 3);
  }
  [flex-offset-xl="66"] {
    margin-left: calc(200% / 3);
  }
  [layout-align-xl],
  [layout-align-xl="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  [layout-align-xl="start"],
  [layout-align-xl="start start"],
  [layout-align-xl="start center"],
  [layout-align-xl="start end"],
  [layout-align-xl="start stretch"] {
    justify-content: flex-start;
  }
  [layout-align-xl="center"],
  [layout-align-xl="center start"],
  [layout-align-xl="center center"],
  [layout-align-xl="center end"],
  [layout-align-xl="center stretch"] {
    justify-content: center;
  }
  [layout-align-xl="end"],
  [layout-align-xl="end center"],
  [layout-align-xl="end start"],
  [layout-align-xl="end end"],
  [layout-align-xl="end stretch"] {
    justify-content: flex-end;
  }
  [layout-align-xl="space-around"],
  [layout-align-xl="space-around center"],
  [layout-align-xl="space-around start"],
  [layout-align-xl="space-around end"],
  [layout-align-xl="space-around stretch"] {
    justify-content: space-around;
  }
  [layout-align-xl="space-between"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-between stretch"] {
    justify-content: space-between;
  }
  [layout-align-xl="start start"],
  [layout-align-xl="center start"],
  [layout-align-xl="end start"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
  }
  [layout-align-xl="start center"],
  [layout-align-xl="center center"],
  [layout-align-xl="end center"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  [layout-align-xl="start center"] > *,
  [layout-align-xl="center center"] > *,
  [layout-align-xl="end center"] > *,
  [layout-align-xl="space-between center"] > *,
  [layout-align-xl="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  [layout-align-xl="start end"],
  [layout-align-xl="center end"],
  [layout-align-xl="end end"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
  }
  [layout-align-xl="start stretch"],
  [layout-align-xl="center stretch"],
  [layout-align-xl="end stretch"],
  [layout-align-xl="space-between stretch"],
  [layout-align-xl="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
  }
  [flex-xl] {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1920px) {
  [flex-xl] {
    flex: 1 1 0%;
  }
}

@media (min-width: 1920px) {
  [flex-xl-grow] {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  [flex-xl-initial] {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  [flex-xl-auto] {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  [flex-xl-none] {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="0"],
  [layout-xl="row"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="0"],
  [layout-xl="column"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="5"],
  [layout-xl="row"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="5"],
  [layout-xl="column"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="10"],
  [layout-xl="row"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="10"],
  [layout-xl="column"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="15"],
  [layout-xl="row"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="15"],
  [layout-xl="column"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="20"],
  [layout-xl="row"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="20"],
  [layout-xl="column"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="25"],
  [layout-xl="row"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="25"],
  [layout-xl="column"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="30"],
  [layout-xl="row"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="30"],
  [layout-xl="column"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="35"],
  [layout-xl="row"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="35"],
  [layout-xl="column"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="40"],
  [layout-xl="row"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="40"],
  [layout-xl="column"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="45"],
  [layout-xl="row"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="45"],
  [layout-xl="column"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="50"],
  [layout-xl="row"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="50"],
  [layout-xl="column"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="55"],
  [layout-xl="row"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="55"],
  [layout-xl="column"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="60"],
  [layout-xl="row"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="60"],
  [layout-xl="column"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="65"],
  [layout-xl="row"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="65"],
  [layout-xl="column"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="70"],
  [layout-xl="row"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="70"],
  [layout-xl="column"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="75"],
  [layout-xl="row"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="75"],
  [layout-xl="column"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="80"],
  [layout-xl="row"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="80"],
  [layout-xl="column"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="85"],
  [layout-xl="row"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="85"],
  [layout-xl="column"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="90"],
  [layout-xl="row"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="90"],
  [layout-xl="column"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="95"],
  [layout-xl="row"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="95"],
  [layout-xl="column"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="100"],
  [layout-xl="row"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="100"],
  [layout-xl="column"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="33"], [layout="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="row"] > [flex-xl="66"], [layout="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="33"], [layout="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  [layout="column"] > [flex-xl="66"], [layout="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  [layout-xl], [layout-xl="column"], [layout-xl="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout-xl="column"] {
    flex-direction: column;
  }
  [layout-xl="row"] {
    flex-direction: row;
  }
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-lg]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]) {
    display: none;
  }
  [hide-xl]:not([show-xl]):not([show-gt-lg]):not([show]) {
    display: none;
  }
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
.flex-order {
  order: 0;
}

.flex-order--20 {
  order: -20;
}

.flex-order--19 {
  order: -19;
}

.flex-order--18 {
  order: -18;
}

.flex-order--17 {
  order: -17;
}

.flex-order--16 {
  order: -16;
}

.flex-order--15 {
  order: -15;
}

.flex-order--14 {
  order: -14;
}

.flex-order--13 {
  order: -13;
}

.flex-order--12 {
  order: -12;
}

.flex-order--11 {
  order: -11;
}

.flex-order--10 {
  order: -10;
}

.flex-order--9 {
  order: -9;
}

.flex-order--8 {
  order: -8;
}

.flex-order--7 {
  order: -7;
}

.flex-order--6 {
  order: -6;
}

.flex-order--5 {
  order: -5;
}

.flex-order--4 {
  order: -4;
}

.flex-order--3 {
  order: -3;
}

.flex-order--2 {
  order: -2;
}

.flex-order--1 {
  order: -1;
}

.flex-order-0 {
  order: 0;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-3 {
  order: 3;
}

.flex-order-4 {
  order: 4;
}

.flex-order-5 {
  order: 5;
}

.flex-order-6 {
  order: 6;
}

.flex-order-7 {
  order: 7;
}

.flex-order-8 {
  order: 8;
}

.flex-order-9 {
  order: 9;
}

.flex-order-10 {
  order: 10;
}

.flex-order-11 {
  order: 11;
}

.flex-order-12 {
  order: 12;
}

.flex-order-13 {
  order: 13;
}

.flex-order-14 {
  order: 14;
}

.flex-order-15 {
  order: 15;
}

.flex-order-16 {
  order: 16;
}

.flex-order-17 {
  order: 17;
}

.flex-order-18 {
  order: 18;
}

.flex-order-19 {
  order: 19;
}

.flex-order-20 {
  order: 20;
}

.offset-0, .flex-offset-0 {
  margin-left: 0%;
}

.offset-5, .flex-offset-5 {
  margin-left: 5%;
}

.offset-10, .flex-offset-10 {
  margin-left: 10%;
}

.offset-15, .flex-offset-15 {
  margin-left: 15%;
}

.offset-20, .flex-offset-20 {
  margin-left: 20%;
}

.offset-25, .flex-offset-25 {
  margin-left: 25%;
}

.offset-30, .flex-offset-30 {
  margin-left: 30%;
}

.offset-35, .flex-offset-35 {
  margin-left: 35%;
}

.offset-40, .flex-offset-40 {
  margin-left: 40%;
}

.offset-45, .flex-offset-45 {
  margin-left: 45%;
}

.offset-50, .flex-offset-50 {
  margin-left: 50%;
}

.offset-55, .flex-offset-55 {
  margin-left: 55%;
}

.offset-60, .flex-offset-60 {
  margin-left: 60%;
}

.offset-65, .flex-offset-65 {
  margin-left: 65%;
}

.offset-70, .flex-offset-70 {
  margin-left: 70%;
}

.offset-75, .flex-offset-75 {
  margin-left: 75%;
}

.offset-80, .flex-offset-80 {
  margin-left: 80%;
}

.offset-85, .flex-offset-85 {
  margin-left: 85%;
}

.offset-90, .flex-offset-90 {
  margin-left: 90%;
}

.offset-95, .flex-offset-95 {
  margin-left: 95%;
}

.offset-33, .flex-offset-33 {
  margin-left: calc(100% / 3);
}

.offset-66, .flex-offset-66 {
  margin-left: calc(200% / 3);
}

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start;
}

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center;
}

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end;
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around;
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between;
}

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start;
}

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box;
}

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end;
}

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch;
}

.flex {
  flex: 1;
  box-sizing: border-box;
}

@media screen\0 {
  .flex {
    flex: 1 1 0%;
  }
}

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box;
}

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box;
}

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-0,
.layout-row > .flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.layout-column > .flex-0,
.layout-column > .flex-0 {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0;
}

.flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-5,
.layout-row > .flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-5,
.layout-column > .flex-5 {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-10,
.layout-row > .flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-10,
.layout-column > .flex-10 {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-15,
.layout-row > .flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-15,
.layout-column > .flex-15 {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-20,
.layout-row > .flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-20,
.layout-column > .flex-20 {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-25,
.layout-row > .flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-25,
.layout-column > .flex-25 {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-30,
.layout-row > .flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-30,
.layout-column > .flex-30 {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-35,
.layout-row > .flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-35,
.layout-column > .flex-35 {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-40,
.layout-row > .flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-40,
.layout-column > .flex-40 {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-45,
.layout-row > .flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-45,
.layout-column > .flex-45 {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-50,
.layout-row > .flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-50,
.layout-column > .flex-50 {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-55,
.layout-row > .flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-55,
.layout-column > .flex-55 {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-60,
.layout-row > .flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-60,
.layout-column > .flex-60 {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-65,
.layout-row > .flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-65,
.layout-column > .flex-65 {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-70,
.layout-row > .flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-70,
.layout-column > .flex-70 {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-75,
.layout-row > .flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-75,
.layout-column > .flex-75 {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-80,
.layout-row > .flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-80,
.layout-column > .flex-80 {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-85,
.layout-row > .flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-85,
.layout-column > .flex-85 {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-90,
.layout-row > .flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-90,
.layout-column > .flex-90 {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-95,
.layout-row > .flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-95,
.layout-column > .flex-95 {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-100,
.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-100,
.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex, .layout-row > .flex {
  min-width: 0;
}

.layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-column > .flex, .layout-column > .flex {
  min-height: 0;
}

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-column {
  flex-direction: column;
}

.layout-row {
  flex-direction: row;
}

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px;
}

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px;
}

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px;
}

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px;
}

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px;
}

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-nowrap {
  flex-wrap: nowrap;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none;
  }
  .flex-order-xs--20 {
    order: -20;
  }
  .flex-order-xs--19 {
    order: -19;
  }
  .flex-order-xs--18 {
    order: -18;
  }
  .flex-order-xs--17 {
    order: -17;
  }
  .flex-order-xs--16 {
    order: -16;
  }
  .flex-order-xs--15 {
    order: -15;
  }
  .flex-order-xs--14 {
    order: -14;
  }
  .flex-order-xs--13 {
    order: -13;
  }
  .flex-order-xs--12 {
    order: -12;
  }
  .flex-order-xs--11 {
    order: -11;
  }
  .flex-order-xs--10 {
    order: -10;
  }
  .flex-order-xs--9 {
    order: -9;
  }
  .flex-order-xs--8 {
    order: -8;
  }
  .flex-order-xs--7 {
    order: -7;
  }
  .flex-order-xs--6 {
    order: -6;
  }
  .flex-order-xs--5 {
    order: -5;
  }
  .flex-order-xs--4 {
    order: -4;
  }
  .flex-order-xs--3 {
    order: -3;
  }
  .flex-order-xs--2 {
    order: -2;
  }
  .flex-order-xs--1 {
    order: -1;
  }
  .flex-order-xs-0 {
    order: 0;
  }
  .flex-order-xs-1 {
    order: 1;
  }
  .flex-order-xs-2 {
    order: 2;
  }
  .flex-order-xs-3 {
    order: 3;
  }
  .flex-order-xs-4 {
    order: 4;
  }
  .flex-order-xs-5 {
    order: 5;
  }
  .flex-order-xs-6 {
    order: 6;
  }
  .flex-order-xs-7 {
    order: 7;
  }
  .flex-order-xs-8 {
    order: 8;
  }
  .flex-order-xs-9 {
    order: 9;
  }
  .flex-order-xs-10 {
    order: 10;
  }
  .flex-order-xs-11 {
    order: 11;
  }
  .flex-order-xs-12 {
    order: 12;
  }
  .flex-order-xs-13 {
    order: 13;
  }
  .flex-order-xs-14 {
    order: 14;
  }
  .flex-order-xs-15 {
    order: 15;
  }
  .flex-order-xs-16 {
    order: 16;
  }
  .flex-order-xs-17 {
    order: 17;
  }
  .flex-order-xs-18 {
    order: 18;
  }
  .flex-order-xs-19 {
    order: 19;
  }
  .flex-order-xs-20 {
    order: 20;
  }
  .offset-xs-0, .flex-offset-xs-0 {
    margin-left: 0%;
  }
  .offset-xs-5, .flex-offset-xs-5 {
    margin-left: 5%;
  }
  .offset-xs-10, .flex-offset-xs-10 {
    margin-left: 10%;
  }
  .offset-xs-15, .flex-offset-xs-15 {
    margin-left: 15%;
  }
  .offset-xs-20, .flex-offset-xs-20 {
    margin-left: 20%;
  }
  .offset-xs-25, .flex-offset-xs-25 {
    margin-left: 25%;
  }
  .offset-xs-30, .flex-offset-xs-30 {
    margin-left: 30%;
  }
  .offset-xs-35, .flex-offset-xs-35 {
    margin-left: 35%;
  }
  .offset-xs-40, .flex-offset-xs-40 {
    margin-left: 40%;
  }
  .offset-xs-45, .flex-offset-xs-45 {
    margin-left: 45%;
  }
  .offset-xs-50, .flex-offset-xs-50 {
    margin-left: 50%;
  }
  .offset-xs-55, .flex-offset-xs-55 {
    margin-left: 55%;
  }
  .offset-xs-60, .flex-offset-xs-60 {
    margin-left: 60%;
  }
  .offset-xs-65, .flex-offset-xs-65 {
    margin-left: 65%;
  }
  .offset-xs-70, .flex-offset-xs-70 {
    margin-left: 70%;
  }
  .offset-xs-75, .flex-offset-xs-75 {
    margin-left: 75%;
  }
  .offset-xs-80, .flex-offset-xs-80 {
    margin-left: 80%;
  }
  .offset-xs-85, .flex-offset-xs-85 {
    margin-left: 85%;
  }
  .offset-xs-90, .flex-offset-xs-90 {
    margin-left: 90%;
  }
  .offset-xs-95, .flex-offset-xs-95 {
    margin-left: 95%;
  }
  .offset-xs-33, .flex-offset-xs-33 {
    margin-left: calc(100% / 3);
  }
  .offset-xs-66, .flex-offset-xs-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center;
  }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-xs {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (max-width: 599px) {
  .flex-xs {
    flex: 1 1 0%;
  }
}

@media (max-width: 599px) {
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-0,
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-xs-0,
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-5,
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-5,
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-10,
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-10,
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-15,
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-15,
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-20,
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-20,
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-25,
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-25,
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-30,
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-30,
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-35,
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-35,
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-40,
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-40,
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-45,
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-45,
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-50,
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-50,
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-55,
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-55,
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-60,
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-60,
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-65,
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-65,
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-70,
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-70,
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-75,
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-75,
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-80,
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-80,
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-85,
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-85,
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-90,
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-90,
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-95,
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-95,
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-100,
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-100,
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33, .layout-xs-row > .flex-xs-33, .layout-xs-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66, .layout-xs-row > .flex-xs-66, .layout-xs-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-xs-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33, .layout-xs-column > .flex-xs-33, .layout-xs-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66, .layout-xs-column > .flex-xs-66, .layout-xs-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-xs-column > .flex {
    min-height: 0;
  }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-xs-column {
    flex-direction: column;
  }
  .layout-xs-row {
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20;
  }
  .flex-order-gt-xs--19 {
    order: -19;
  }
  .flex-order-gt-xs--18 {
    order: -18;
  }
  .flex-order-gt-xs--17 {
    order: -17;
  }
  .flex-order-gt-xs--16 {
    order: -16;
  }
  .flex-order-gt-xs--15 {
    order: -15;
  }
  .flex-order-gt-xs--14 {
    order: -14;
  }
  .flex-order-gt-xs--13 {
    order: -13;
  }
  .flex-order-gt-xs--12 {
    order: -12;
  }
  .flex-order-gt-xs--11 {
    order: -11;
  }
  .flex-order-gt-xs--10 {
    order: -10;
  }
  .flex-order-gt-xs--9 {
    order: -9;
  }
  .flex-order-gt-xs--8 {
    order: -8;
  }
  .flex-order-gt-xs--7 {
    order: -7;
  }
  .flex-order-gt-xs--6 {
    order: -6;
  }
  .flex-order-gt-xs--5 {
    order: -5;
  }
  .flex-order-gt-xs--4 {
    order: -4;
  }
  .flex-order-gt-xs--3 {
    order: -3;
  }
  .flex-order-gt-xs--2 {
    order: -2;
  }
  .flex-order-gt-xs--1 {
    order: -1;
  }
  .flex-order-gt-xs-0 {
    order: 0;
  }
  .flex-order-gt-xs-1 {
    order: 1;
  }
  .flex-order-gt-xs-2 {
    order: 2;
  }
  .flex-order-gt-xs-3 {
    order: 3;
  }
  .flex-order-gt-xs-4 {
    order: 4;
  }
  .flex-order-gt-xs-5 {
    order: 5;
  }
  .flex-order-gt-xs-6 {
    order: 6;
  }
  .flex-order-gt-xs-7 {
    order: 7;
  }
  .flex-order-gt-xs-8 {
    order: 8;
  }
  .flex-order-gt-xs-9 {
    order: 9;
  }
  .flex-order-gt-xs-10 {
    order: 10;
  }
  .flex-order-gt-xs-11 {
    order: 11;
  }
  .flex-order-gt-xs-12 {
    order: 12;
  }
  .flex-order-gt-xs-13 {
    order: 13;
  }
  .flex-order-gt-xs-14 {
    order: 14;
  }
  .flex-order-gt-xs-15 {
    order: 15;
  }
  .flex-order-gt-xs-16 {
    order: 16;
  }
  .flex-order-gt-xs-17 {
    order: 17;
  }
  .flex-order-gt-xs-18 {
    order: 18;
  }
  .flex-order-gt-xs-19 {
    order: 19;
  }
  .flex-order-gt-xs-20 {
    order: 20;
  }
  .offset-gt-xs-0, .flex-offset-gt-xs-0 {
    margin-left: 0%;
  }
  .offset-gt-xs-5, .flex-offset-gt-xs-5 {
    margin-left: 5%;
  }
  .offset-gt-xs-10, .flex-offset-gt-xs-10 {
    margin-left: 10%;
  }
  .offset-gt-xs-15, .flex-offset-gt-xs-15 {
    margin-left: 15%;
  }
  .offset-gt-xs-20, .flex-offset-gt-xs-20 {
    margin-left: 20%;
  }
  .offset-gt-xs-25, .flex-offset-gt-xs-25 {
    margin-left: 25%;
  }
  .offset-gt-xs-30, .flex-offset-gt-xs-30 {
    margin-left: 30%;
  }
  .offset-gt-xs-35, .flex-offset-gt-xs-35 {
    margin-left: 35%;
  }
  .offset-gt-xs-40, .flex-offset-gt-xs-40 {
    margin-left: 40%;
  }
  .offset-gt-xs-45, .flex-offset-gt-xs-45 {
    margin-left: 45%;
  }
  .offset-gt-xs-50, .flex-offset-gt-xs-50 {
    margin-left: 50%;
  }
  .offset-gt-xs-55, .flex-offset-gt-xs-55 {
    margin-left: 55%;
  }
  .offset-gt-xs-60, .flex-offset-gt-xs-60 {
    margin-left: 60%;
  }
  .offset-gt-xs-65, .flex-offset-gt-xs-65 {
    margin-left: 65%;
  }
  .offset-gt-xs-70, .flex-offset-gt-xs-70 {
    margin-left: 70%;
  }
  .offset-gt-xs-75, .flex-offset-gt-xs-75 {
    margin-left: 75%;
  }
  .offset-gt-xs-80, .flex-offset-gt-xs-80 {
    margin-left: 80%;
  }
  .offset-gt-xs-85, .flex-offset-gt-xs-85 {
    margin-left: 85%;
  }
  .offset-gt-xs-90, .flex-offset-gt-xs-90 {
    margin-left: 90%;
  }
  .offset-gt-xs-95, .flex-offset-gt-xs-95 {
    margin-left: 95%;
  }
  .offset-gt-xs-33, .flex-offset-gt-xs-33 {
    margin-left: calc(100% / 3);
  }
  .offset-gt-xs-66, .flex-offset-gt-xs-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 600px) {
  .flex-gt-xs {
    flex: 1 1 0%;
  }
}

@media (min-width: 600px) {
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-0,
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-xs-0,
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-5,
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-5,
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-10,
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-10,
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-15,
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-15,
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-20,
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-20,
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-25,
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-25,
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-30,
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-30,
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-35,
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-35,
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-40,
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-40,
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-45,
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-45,
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-50,
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-50,
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-55,
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-55,
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-60,
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-60,
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-65,
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-65,
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-70,
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-70,
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-75,
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-75,
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-80,
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-80,
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-85,
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-85,
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-90,
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-90,
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-95,
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-95,
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-100,
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-100,
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-gt-xs-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-gt-xs-column > .flex {
    min-height: 0;
  }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-xs-column {
    flex-direction: column;
  }
  .layout-gt-xs-row {
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none;
  }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none;
  }
  .flex-order-sm--20 {
    order: -20;
  }
  .flex-order-sm--19 {
    order: -19;
  }
  .flex-order-sm--18 {
    order: -18;
  }
  .flex-order-sm--17 {
    order: -17;
  }
  .flex-order-sm--16 {
    order: -16;
  }
  .flex-order-sm--15 {
    order: -15;
  }
  .flex-order-sm--14 {
    order: -14;
  }
  .flex-order-sm--13 {
    order: -13;
  }
  .flex-order-sm--12 {
    order: -12;
  }
  .flex-order-sm--11 {
    order: -11;
  }
  .flex-order-sm--10 {
    order: -10;
  }
  .flex-order-sm--9 {
    order: -9;
  }
  .flex-order-sm--8 {
    order: -8;
  }
  .flex-order-sm--7 {
    order: -7;
  }
  .flex-order-sm--6 {
    order: -6;
  }
  .flex-order-sm--5 {
    order: -5;
  }
  .flex-order-sm--4 {
    order: -4;
  }
  .flex-order-sm--3 {
    order: -3;
  }
  .flex-order-sm--2 {
    order: -2;
  }
  .flex-order-sm--1 {
    order: -1;
  }
  .flex-order-sm-0 {
    order: 0;
  }
  .flex-order-sm-1 {
    order: 1;
  }
  .flex-order-sm-2 {
    order: 2;
  }
  .flex-order-sm-3 {
    order: 3;
  }
  .flex-order-sm-4 {
    order: 4;
  }
  .flex-order-sm-5 {
    order: 5;
  }
  .flex-order-sm-6 {
    order: 6;
  }
  .flex-order-sm-7 {
    order: 7;
  }
  .flex-order-sm-8 {
    order: 8;
  }
  .flex-order-sm-9 {
    order: 9;
  }
  .flex-order-sm-10 {
    order: 10;
  }
  .flex-order-sm-11 {
    order: 11;
  }
  .flex-order-sm-12 {
    order: 12;
  }
  .flex-order-sm-13 {
    order: 13;
  }
  .flex-order-sm-14 {
    order: 14;
  }
  .flex-order-sm-15 {
    order: 15;
  }
  .flex-order-sm-16 {
    order: 16;
  }
  .flex-order-sm-17 {
    order: 17;
  }
  .flex-order-sm-18 {
    order: 18;
  }
  .flex-order-sm-19 {
    order: 19;
  }
  .flex-order-sm-20 {
    order: 20;
  }
  .offset-sm-0, .flex-offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-5, .flex-offset-sm-5 {
    margin-left: 5%;
  }
  .offset-sm-10, .flex-offset-sm-10 {
    margin-left: 10%;
  }
  .offset-sm-15, .flex-offset-sm-15 {
    margin-left: 15%;
  }
  .offset-sm-20, .flex-offset-sm-20 {
    margin-left: 20%;
  }
  .offset-sm-25, .flex-offset-sm-25 {
    margin-left: 25%;
  }
  .offset-sm-30, .flex-offset-sm-30 {
    margin-left: 30%;
  }
  .offset-sm-35, .flex-offset-sm-35 {
    margin-left: 35%;
  }
  .offset-sm-40, .flex-offset-sm-40 {
    margin-left: 40%;
  }
  .offset-sm-45, .flex-offset-sm-45 {
    margin-left: 45%;
  }
  .offset-sm-50, .flex-offset-sm-50 {
    margin-left: 50%;
  }
  .offset-sm-55, .flex-offset-sm-55 {
    margin-left: 55%;
  }
  .offset-sm-60, .flex-offset-sm-60 {
    margin-left: 60%;
  }
  .offset-sm-65, .flex-offset-sm-65 {
    margin-left: 65%;
  }
  .offset-sm-70, .flex-offset-sm-70 {
    margin-left: 70%;
  }
  .offset-sm-75, .flex-offset-sm-75 {
    margin-left: 75%;
  }
  .offset-sm-80, .flex-offset-sm-80 {
    margin-left: 80%;
  }
  .offset-sm-85, .flex-offset-sm-85 {
    margin-left: 85%;
  }
  .offset-sm-90, .flex-offset-sm-90 {
    margin-left: 90%;
  }
  .offset-sm-95, .flex-offset-sm-95 {
    margin-left: 95%;
  }
  .offset-sm-33, .flex-offset-sm-33 {
    margin-left: calc(100% / 3);
  }
  .offset-sm-66, .flex-offset-sm-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center;
  }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-sm {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 600px) and (max-width: 959px) {
  .flex-sm {
    flex: 1 1 0%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-0,
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-sm-0,
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-5,
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-5,
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-10,
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-10,
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-15,
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-15,
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-20,
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-20,
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-25,
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-25,
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-30,
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-30,
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-35,
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-35,
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-40,
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-40,
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-45,
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-45,
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-50,
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-50,
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-55,
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-55,
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-60,
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-60,
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-65,
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-65,
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-70,
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-70,
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-75,
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-75,
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-80,
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-80,
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-85,
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-85,
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-90,
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-90,
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-95,
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-95,
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-100,
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-100,
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33, .layout-sm-row > .flex-sm-33, .layout-sm-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66, .layout-sm-row > .flex-sm-66, .layout-sm-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-sm-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33, .layout-sm-column > .flex-sm-33, .layout-sm-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66, .layout-sm-column > .flex-sm-66, .layout-sm-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-sm-column > .flex {
    min-height: 0;
  }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-sm-column {
    flex-direction: column;
  }
  .layout-sm-row {
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20;
  }
  .flex-order-gt-sm--19 {
    order: -19;
  }
  .flex-order-gt-sm--18 {
    order: -18;
  }
  .flex-order-gt-sm--17 {
    order: -17;
  }
  .flex-order-gt-sm--16 {
    order: -16;
  }
  .flex-order-gt-sm--15 {
    order: -15;
  }
  .flex-order-gt-sm--14 {
    order: -14;
  }
  .flex-order-gt-sm--13 {
    order: -13;
  }
  .flex-order-gt-sm--12 {
    order: -12;
  }
  .flex-order-gt-sm--11 {
    order: -11;
  }
  .flex-order-gt-sm--10 {
    order: -10;
  }
  .flex-order-gt-sm--9 {
    order: -9;
  }
  .flex-order-gt-sm--8 {
    order: -8;
  }
  .flex-order-gt-sm--7 {
    order: -7;
  }
  .flex-order-gt-sm--6 {
    order: -6;
  }
  .flex-order-gt-sm--5 {
    order: -5;
  }
  .flex-order-gt-sm--4 {
    order: -4;
  }
  .flex-order-gt-sm--3 {
    order: -3;
  }
  .flex-order-gt-sm--2 {
    order: -2;
  }
  .flex-order-gt-sm--1 {
    order: -1;
  }
  .flex-order-gt-sm-0 {
    order: 0;
  }
  .flex-order-gt-sm-1 {
    order: 1;
  }
  .flex-order-gt-sm-2 {
    order: 2;
  }
  .flex-order-gt-sm-3 {
    order: 3;
  }
  .flex-order-gt-sm-4 {
    order: 4;
  }
  .flex-order-gt-sm-5 {
    order: 5;
  }
  .flex-order-gt-sm-6 {
    order: 6;
  }
  .flex-order-gt-sm-7 {
    order: 7;
  }
  .flex-order-gt-sm-8 {
    order: 8;
  }
  .flex-order-gt-sm-9 {
    order: 9;
  }
  .flex-order-gt-sm-10 {
    order: 10;
  }
  .flex-order-gt-sm-11 {
    order: 11;
  }
  .flex-order-gt-sm-12 {
    order: 12;
  }
  .flex-order-gt-sm-13 {
    order: 13;
  }
  .flex-order-gt-sm-14 {
    order: 14;
  }
  .flex-order-gt-sm-15 {
    order: 15;
  }
  .flex-order-gt-sm-16 {
    order: 16;
  }
  .flex-order-gt-sm-17 {
    order: 17;
  }
  .flex-order-gt-sm-18 {
    order: 18;
  }
  .flex-order-gt-sm-19 {
    order: 19;
  }
  .flex-order-gt-sm-20 {
    order: 20;
  }
  .offset-gt-sm-0, .flex-offset-gt-sm-0 {
    margin-left: 0%;
  }
  .offset-gt-sm-5, .flex-offset-gt-sm-5 {
    margin-left: 5%;
  }
  .offset-gt-sm-10, .flex-offset-gt-sm-10 {
    margin-left: 10%;
  }
  .offset-gt-sm-15, .flex-offset-gt-sm-15 {
    margin-left: 15%;
  }
  .offset-gt-sm-20, .flex-offset-gt-sm-20 {
    margin-left: 20%;
  }
  .offset-gt-sm-25, .flex-offset-gt-sm-25 {
    margin-left: 25%;
  }
  .offset-gt-sm-30, .flex-offset-gt-sm-30 {
    margin-left: 30%;
  }
  .offset-gt-sm-35, .flex-offset-gt-sm-35 {
    margin-left: 35%;
  }
  .offset-gt-sm-40, .flex-offset-gt-sm-40 {
    margin-left: 40%;
  }
  .offset-gt-sm-45, .flex-offset-gt-sm-45 {
    margin-left: 45%;
  }
  .offset-gt-sm-50, .flex-offset-gt-sm-50 {
    margin-left: 50%;
  }
  .offset-gt-sm-55, .flex-offset-gt-sm-55 {
    margin-left: 55%;
  }
  .offset-gt-sm-60, .flex-offset-gt-sm-60 {
    margin-left: 60%;
  }
  .offset-gt-sm-65, .flex-offset-gt-sm-65 {
    margin-left: 65%;
  }
  .offset-gt-sm-70, .flex-offset-gt-sm-70 {
    margin-left: 70%;
  }
  .offset-gt-sm-75, .flex-offset-gt-sm-75 {
    margin-left: 75%;
  }
  .offset-gt-sm-80, .flex-offset-gt-sm-80 {
    margin-left: 80%;
  }
  .offset-gt-sm-85, .flex-offset-gt-sm-85 {
    margin-left: 85%;
  }
  .offset-gt-sm-90, .flex-offset-gt-sm-90 {
    margin-left: 90%;
  }
  .offset-gt-sm-95, .flex-offset-gt-sm-95 {
    margin-left: 95%;
  }
  .offset-gt-sm-33, .flex-offset-gt-sm-33 {
    margin-left: calc(100% / 3);
  }
  .offset-gt-sm-66, .flex-offset-gt-sm-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 960px) {
  .flex-gt-sm {
    flex: 1 1 0%;
  }
}

@media (min-width: 960px) {
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-0,
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-sm-0,
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-5,
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-5,
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-10,
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-10,
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-15,
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-15,
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-20,
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-20,
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-25,
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-25,
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-30,
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-30,
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-35,
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-35,
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-40,
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-40,
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-45,
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-45,
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-50,
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-50,
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-55,
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-55,
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-60,
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-60,
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-65,
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-65,
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-70,
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-70,
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-75,
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-75,
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-80,
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-80,
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-85,
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-85,
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-90,
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-90,
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-95,
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-95,
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-100,
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-100,
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-gt-sm-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-gt-sm-column > .flex {
    min-height: 0;
  }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-sm-column {
    flex-direction: column;
  }
  .layout-gt-sm-row {
    flex-direction: row;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none;
  }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
  .flex-order-md--20 {
    order: -20;
  }
  .flex-order-md--19 {
    order: -19;
  }
  .flex-order-md--18 {
    order: -18;
  }
  .flex-order-md--17 {
    order: -17;
  }
  .flex-order-md--16 {
    order: -16;
  }
  .flex-order-md--15 {
    order: -15;
  }
  .flex-order-md--14 {
    order: -14;
  }
  .flex-order-md--13 {
    order: -13;
  }
  .flex-order-md--12 {
    order: -12;
  }
  .flex-order-md--11 {
    order: -11;
  }
  .flex-order-md--10 {
    order: -10;
  }
  .flex-order-md--9 {
    order: -9;
  }
  .flex-order-md--8 {
    order: -8;
  }
  .flex-order-md--7 {
    order: -7;
  }
  .flex-order-md--6 {
    order: -6;
  }
  .flex-order-md--5 {
    order: -5;
  }
  .flex-order-md--4 {
    order: -4;
  }
  .flex-order-md--3 {
    order: -3;
  }
  .flex-order-md--2 {
    order: -2;
  }
  .flex-order-md--1 {
    order: -1;
  }
  .flex-order-md-0 {
    order: 0;
  }
  .flex-order-md-1 {
    order: 1;
  }
  .flex-order-md-2 {
    order: 2;
  }
  .flex-order-md-3 {
    order: 3;
  }
  .flex-order-md-4 {
    order: 4;
  }
  .flex-order-md-5 {
    order: 5;
  }
  .flex-order-md-6 {
    order: 6;
  }
  .flex-order-md-7 {
    order: 7;
  }
  .flex-order-md-8 {
    order: 8;
  }
  .flex-order-md-9 {
    order: 9;
  }
  .flex-order-md-10 {
    order: 10;
  }
  .flex-order-md-11 {
    order: 11;
  }
  .flex-order-md-12 {
    order: 12;
  }
  .flex-order-md-13 {
    order: 13;
  }
  .flex-order-md-14 {
    order: 14;
  }
  .flex-order-md-15 {
    order: 15;
  }
  .flex-order-md-16 {
    order: 16;
  }
  .flex-order-md-17 {
    order: 17;
  }
  .flex-order-md-18 {
    order: 18;
  }
  .flex-order-md-19 {
    order: 19;
  }
  .flex-order-md-20 {
    order: 20;
  }
  .offset-md-0, .flex-offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-5, .flex-offset-md-5 {
    margin-left: 5%;
  }
  .offset-md-10, .flex-offset-md-10 {
    margin-left: 10%;
  }
  .offset-md-15, .flex-offset-md-15 {
    margin-left: 15%;
  }
  .offset-md-20, .flex-offset-md-20 {
    margin-left: 20%;
  }
  .offset-md-25, .flex-offset-md-25 {
    margin-left: 25%;
  }
  .offset-md-30, .flex-offset-md-30 {
    margin-left: 30%;
  }
  .offset-md-35, .flex-offset-md-35 {
    margin-left: 35%;
  }
  .offset-md-40, .flex-offset-md-40 {
    margin-left: 40%;
  }
  .offset-md-45, .flex-offset-md-45 {
    margin-left: 45%;
  }
  .offset-md-50, .flex-offset-md-50 {
    margin-left: 50%;
  }
  .offset-md-55, .flex-offset-md-55 {
    margin-left: 55%;
  }
  .offset-md-60, .flex-offset-md-60 {
    margin-left: 60%;
  }
  .offset-md-65, .flex-offset-md-65 {
    margin-left: 65%;
  }
  .offset-md-70, .flex-offset-md-70 {
    margin-left: 70%;
  }
  .offset-md-75, .flex-offset-md-75 {
    margin-left: 75%;
  }
  .offset-md-80, .flex-offset-md-80 {
    margin-left: 80%;
  }
  .offset-md-85, .flex-offset-md-85 {
    margin-left: 85%;
  }
  .offset-md-90, .flex-offset-md-90 {
    margin-left: 90%;
  }
  .offset-md-95, .flex-offset-md-95 {
    margin-left: 95%;
  }
  .offset-md-33, .flex-offset-md-33 {
    margin-left: calc(100% / 3);
  }
  .offset-md-66, .flex-offset-md-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center;
  }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-md {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 960px) and (max-width: 1279px) {
  .flex-md {
    flex: 1 1 0%;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-0,
  .layout-md-row > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-md-0,
  .layout-md-column > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-5,
  .layout-md-row > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-5,
  .layout-md-column > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-10,
  .layout-md-row > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-10,
  .layout-md-column > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-15,
  .layout-md-row > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-15,
  .layout-md-column > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-20,
  .layout-md-row > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-20,
  .layout-md-column > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-25,
  .layout-md-row > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-25,
  .layout-md-column > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-30,
  .layout-md-row > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-30,
  .layout-md-column > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-35,
  .layout-md-row > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-35,
  .layout-md-column > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-40,
  .layout-md-row > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-40,
  .layout-md-column > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-45,
  .layout-md-row > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-45,
  .layout-md-column > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-50,
  .layout-md-row > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-50,
  .layout-md-column > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-55,
  .layout-md-row > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-55,
  .layout-md-column > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-60,
  .layout-md-row > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-60,
  .layout-md-column > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-65,
  .layout-md-row > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-65,
  .layout-md-column > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-70,
  .layout-md-row > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-70,
  .layout-md-column > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-75,
  .layout-md-row > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-75,
  .layout-md-column > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-80,
  .layout-md-row > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-80,
  .layout-md-column > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-85,
  .layout-md-row > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-85,
  .layout-md-column > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-90,
  .layout-md-row > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-90,
  .layout-md-column > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-95,
  .layout-md-row > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-95,
  .layout-md-column > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-100,
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-100,
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33, .layout-md-row > .flex-md-33, .layout-md-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66, .layout-md-row > .flex-md-66, .layout-md-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-md-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33, .layout-md-column > .flex-md-33, .layout-md-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66, .layout-md-column > .flex-md-66, .layout-md-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-md-column > .flex {
    min-height: 0;
  }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-md-column {
    flex-direction: column;
  }
  .layout-md-row {
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20;
  }
  .flex-order-gt-md--19 {
    order: -19;
  }
  .flex-order-gt-md--18 {
    order: -18;
  }
  .flex-order-gt-md--17 {
    order: -17;
  }
  .flex-order-gt-md--16 {
    order: -16;
  }
  .flex-order-gt-md--15 {
    order: -15;
  }
  .flex-order-gt-md--14 {
    order: -14;
  }
  .flex-order-gt-md--13 {
    order: -13;
  }
  .flex-order-gt-md--12 {
    order: -12;
  }
  .flex-order-gt-md--11 {
    order: -11;
  }
  .flex-order-gt-md--10 {
    order: -10;
  }
  .flex-order-gt-md--9 {
    order: -9;
  }
  .flex-order-gt-md--8 {
    order: -8;
  }
  .flex-order-gt-md--7 {
    order: -7;
  }
  .flex-order-gt-md--6 {
    order: -6;
  }
  .flex-order-gt-md--5 {
    order: -5;
  }
  .flex-order-gt-md--4 {
    order: -4;
  }
  .flex-order-gt-md--3 {
    order: -3;
  }
  .flex-order-gt-md--2 {
    order: -2;
  }
  .flex-order-gt-md--1 {
    order: -1;
  }
  .flex-order-gt-md-0 {
    order: 0;
  }
  .flex-order-gt-md-1 {
    order: 1;
  }
  .flex-order-gt-md-2 {
    order: 2;
  }
  .flex-order-gt-md-3 {
    order: 3;
  }
  .flex-order-gt-md-4 {
    order: 4;
  }
  .flex-order-gt-md-5 {
    order: 5;
  }
  .flex-order-gt-md-6 {
    order: 6;
  }
  .flex-order-gt-md-7 {
    order: 7;
  }
  .flex-order-gt-md-8 {
    order: 8;
  }
  .flex-order-gt-md-9 {
    order: 9;
  }
  .flex-order-gt-md-10 {
    order: 10;
  }
  .flex-order-gt-md-11 {
    order: 11;
  }
  .flex-order-gt-md-12 {
    order: 12;
  }
  .flex-order-gt-md-13 {
    order: 13;
  }
  .flex-order-gt-md-14 {
    order: 14;
  }
  .flex-order-gt-md-15 {
    order: 15;
  }
  .flex-order-gt-md-16 {
    order: 16;
  }
  .flex-order-gt-md-17 {
    order: 17;
  }
  .flex-order-gt-md-18 {
    order: 18;
  }
  .flex-order-gt-md-19 {
    order: 19;
  }
  .flex-order-gt-md-20 {
    order: 20;
  }
  .offset-gt-md-0, .flex-offset-gt-md-0 {
    margin-left: 0%;
  }
  .offset-gt-md-5, .flex-offset-gt-md-5 {
    margin-left: 5%;
  }
  .offset-gt-md-10, .flex-offset-gt-md-10 {
    margin-left: 10%;
  }
  .offset-gt-md-15, .flex-offset-gt-md-15 {
    margin-left: 15%;
  }
  .offset-gt-md-20, .flex-offset-gt-md-20 {
    margin-left: 20%;
  }
  .offset-gt-md-25, .flex-offset-gt-md-25 {
    margin-left: 25%;
  }
  .offset-gt-md-30, .flex-offset-gt-md-30 {
    margin-left: 30%;
  }
  .offset-gt-md-35, .flex-offset-gt-md-35 {
    margin-left: 35%;
  }
  .offset-gt-md-40, .flex-offset-gt-md-40 {
    margin-left: 40%;
  }
  .offset-gt-md-45, .flex-offset-gt-md-45 {
    margin-left: 45%;
  }
  .offset-gt-md-50, .flex-offset-gt-md-50 {
    margin-left: 50%;
  }
  .offset-gt-md-55, .flex-offset-gt-md-55 {
    margin-left: 55%;
  }
  .offset-gt-md-60, .flex-offset-gt-md-60 {
    margin-left: 60%;
  }
  .offset-gt-md-65, .flex-offset-gt-md-65 {
    margin-left: 65%;
  }
  .offset-gt-md-70, .flex-offset-gt-md-70 {
    margin-left: 70%;
  }
  .offset-gt-md-75, .flex-offset-gt-md-75 {
    margin-left: 75%;
  }
  .offset-gt-md-80, .flex-offset-gt-md-80 {
    margin-left: 80%;
  }
  .offset-gt-md-85, .flex-offset-gt-md-85 {
    margin-left: 85%;
  }
  .offset-gt-md-90, .flex-offset-gt-md-90 {
    margin-left: 90%;
  }
  .offset-gt-md-95, .flex-offset-gt-md-95 {
    margin-left: 95%;
  }
  .offset-gt-md-33, .flex-offset-gt-md-33 {
    margin-left: calc(100% / 3);
  }
  .offset-gt-md-66, .flex-offset-gt-md-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1280px) {
  .flex-gt-md {
    flex: 1 1 0%;
  }
}

@media (min-width: 1280px) {
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-0,
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-md-0,
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-5,
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-5,
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-10,
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-10,
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-15,
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-15,
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-20,
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-20,
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-25,
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-25,
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-30,
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-30,
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-35,
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-35,
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-40,
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-40,
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-45,
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-45,
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-50,
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-50,
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-55,
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-55,
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-60,
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-60,
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-65,
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-65,
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-70,
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-70,
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-75,
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-75,
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-80,
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-80,
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-85,
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-85,
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-90,
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-90,
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-95,
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-95,
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-100,
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-100,
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-gt-md-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-gt-md-column > .flex {
    min-height: 0;
  }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-md-column {
    flex-direction: column;
  }
  .layout-gt-md-row {
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none;
  }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
  .flex-order-lg--20 {
    order: -20;
  }
  .flex-order-lg--19 {
    order: -19;
  }
  .flex-order-lg--18 {
    order: -18;
  }
  .flex-order-lg--17 {
    order: -17;
  }
  .flex-order-lg--16 {
    order: -16;
  }
  .flex-order-lg--15 {
    order: -15;
  }
  .flex-order-lg--14 {
    order: -14;
  }
  .flex-order-lg--13 {
    order: -13;
  }
  .flex-order-lg--12 {
    order: -12;
  }
  .flex-order-lg--11 {
    order: -11;
  }
  .flex-order-lg--10 {
    order: -10;
  }
  .flex-order-lg--9 {
    order: -9;
  }
  .flex-order-lg--8 {
    order: -8;
  }
  .flex-order-lg--7 {
    order: -7;
  }
  .flex-order-lg--6 {
    order: -6;
  }
  .flex-order-lg--5 {
    order: -5;
  }
  .flex-order-lg--4 {
    order: -4;
  }
  .flex-order-lg--3 {
    order: -3;
  }
  .flex-order-lg--2 {
    order: -2;
  }
  .flex-order-lg--1 {
    order: -1;
  }
  .flex-order-lg-0 {
    order: 0;
  }
  .flex-order-lg-1 {
    order: 1;
  }
  .flex-order-lg-2 {
    order: 2;
  }
  .flex-order-lg-3 {
    order: 3;
  }
  .flex-order-lg-4 {
    order: 4;
  }
  .flex-order-lg-5 {
    order: 5;
  }
  .flex-order-lg-6 {
    order: 6;
  }
  .flex-order-lg-7 {
    order: 7;
  }
  .flex-order-lg-8 {
    order: 8;
  }
  .flex-order-lg-9 {
    order: 9;
  }
  .flex-order-lg-10 {
    order: 10;
  }
  .flex-order-lg-11 {
    order: 11;
  }
  .flex-order-lg-12 {
    order: 12;
  }
  .flex-order-lg-13 {
    order: 13;
  }
  .flex-order-lg-14 {
    order: 14;
  }
  .flex-order-lg-15 {
    order: 15;
  }
  .flex-order-lg-16 {
    order: 16;
  }
  .flex-order-lg-17 {
    order: 17;
  }
  .flex-order-lg-18 {
    order: 18;
  }
  .flex-order-lg-19 {
    order: 19;
  }
  .flex-order-lg-20 {
    order: 20;
  }
  .offset-lg-0, .flex-offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-5, .flex-offset-lg-5 {
    margin-left: 5%;
  }
  .offset-lg-10, .flex-offset-lg-10 {
    margin-left: 10%;
  }
  .offset-lg-15, .flex-offset-lg-15 {
    margin-left: 15%;
  }
  .offset-lg-20, .flex-offset-lg-20 {
    margin-left: 20%;
  }
  .offset-lg-25, .flex-offset-lg-25 {
    margin-left: 25%;
  }
  .offset-lg-30, .flex-offset-lg-30 {
    margin-left: 30%;
  }
  .offset-lg-35, .flex-offset-lg-35 {
    margin-left: 35%;
  }
  .offset-lg-40, .flex-offset-lg-40 {
    margin-left: 40%;
  }
  .offset-lg-45, .flex-offset-lg-45 {
    margin-left: 45%;
  }
  .offset-lg-50, .flex-offset-lg-50 {
    margin-left: 50%;
  }
  .offset-lg-55, .flex-offset-lg-55 {
    margin-left: 55%;
  }
  .offset-lg-60, .flex-offset-lg-60 {
    margin-left: 60%;
  }
  .offset-lg-65, .flex-offset-lg-65 {
    margin-left: 65%;
  }
  .offset-lg-70, .flex-offset-lg-70 {
    margin-left: 70%;
  }
  .offset-lg-75, .flex-offset-lg-75 {
    margin-left: 75%;
  }
  .offset-lg-80, .flex-offset-lg-80 {
    margin-left: 80%;
  }
  .offset-lg-85, .flex-offset-lg-85 {
    margin-left: 85%;
  }
  .offset-lg-90, .flex-offset-lg-90 {
    margin-left: 90%;
  }
  .offset-lg-95, .flex-offset-lg-95 {
    margin-left: 95%;
  }
  .offset-lg-33, .flex-offset-lg-33 {
    margin-left: calc(100% / 3);
  }
  .offset-lg-66, .flex-offset-lg-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center;
  }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-lg {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg {
    flex: 1 1 0%;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-0,
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-lg-0,
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-5,
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-5,
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-10,
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-10,
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-15,
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-15,
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-20,
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-20,
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-25,
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-25,
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-30,
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-30,
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-35,
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-35,
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-40,
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-40,
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-45,
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-45,
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-50,
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-50,
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-55,
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-55,
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-60,
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-60,
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-65,
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-65,
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-70,
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-70,
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-75,
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-75,
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-80,
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-80,
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-85,
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-85,
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-90,
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-90,
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-95,
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-95,
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-100,
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-100,
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33, .layout-lg-row > .flex-lg-33, .layout-lg-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66, .layout-lg-row > .flex-lg-66, .layout-lg-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-lg-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33, .layout-lg-column > .flex-lg-33, .layout-lg-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66, .layout-lg-column > .flex-lg-66, .layout-lg-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-lg-column > .flex {
    min-height: 0;
  }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-lg-column {
    flex-direction: column;
  }
  .layout-lg-row {
    flex-direction: row;
  }
}

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20;
  }
  .flex-order-gt-lg--19 {
    order: -19;
  }
  .flex-order-gt-lg--18 {
    order: -18;
  }
  .flex-order-gt-lg--17 {
    order: -17;
  }
  .flex-order-gt-lg--16 {
    order: -16;
  }
  .flex-order-gt-lg--15 {
    order: -15;
  }
  .flex-order-gt-lg--14 {
    order: -14;
  }
  .flex-order-gt-lg--13 {
    order: -13;
  }
  .flex-order-gt-lg--12 {
    order: -12;
  }
  .flex-order-gt-lg--11 {
    order: -11;
  }
  .flex-order-gt-lg--10 {
    order: -10;
  }
  .flex-order-gt-lg--9 {
    order: -9;
  }
  .flex-order-gt-lg--8 {
    order: -8;
  }
  .flex-order-gt-lg--7 {
    order: -7;
  }
  .flex-order-gt-lg--6 {
    order: -6;
  }
  .flex-order-gt-lg--5 {
    order: -5;
  }
  .flex-order-gt-lg--4 {
    order: -4;
  }
  .flex-order-gt-lg--3 {
    order: -3;
  }
  .flex-order-gt-lg--2 {
    order: -2;
  }
  .flex-order-gt-lg--1 {
    order: -1;
  }
  .flex-order-gt-lg-0 {
    order: 0;
  }
  .flex-order-gt-lg-1 {
    order: 1;
  }
  .flex-order-gt-lg-2 {
    order: 2;
  }
  .flex-order-gt-lg-3 {
    order: 3;
  }
  .flex-order-gt-lg-4 {
    order: 4;
  }
  .flex-order-gt-lg-5 {
    order: 5;
  }
  .flex-order-gt-lg-6 {
    order: 6;
  }
  .flex-order-gt-lg-7 {
    order: 7;
  }
  .flex-order-gt-lg-8 {
    order: 8;
  }
  .flex-order-gt-lg-9 {
    order: 9;
  }
  .flex-order-gt-lg-10 {
    order: 10;
  }
  .flex-order-gt-lg-11 {
    order: 11;
  }
  .flex-order-gt-lg-12 {
    order: 12;
  }
  .flex-order-gt-lg-13 {
    order: 13;
  }
  .flex-order-gt-lg-14 {
    order: 14;
  }
  .flex-order-gt-lg-15 {
    order: 15;
  }
  .flex-order-gt-lg-16 {
    order: 16;
  }
  .flex-order-gt-lg-17 {
    order: 17;
  }
  .flex-order-gt-lg-18 {
    order: 18;
  }
  .flex-order-gt-lg-19 {
    order: 19;
  }
  .flex-order-gt-lg-20 {
    order: 20;
  }
  .offset-gt-lg-0, .flex-offset-gt-lg-0 {
    margin-left: 0%;
  }
  .offset-gt-lg-5, .flex-offset-gt-lg-5 {
    margin-left: 5%;
  }
  .offset-gt-lg-10, .flex-offset-gt-lg-10 {
    margin-left: 10%;
  }
  .offset-gt-lg-15, .flex-offset-gt-lg-15 {
    margin-left: 15%;
  }
  .offset-gt-lg-20, .flex-offset-gt-lg-20 {
    margin-left: 20%;
  }
  .offset-gt-lg-25, .flex-offset-gt-lg-25 {
    margin-left: 25%;
  }
  .offset-gt-lg-30, .flex-offset-gt-lg-30 {
    margin-left: 30%;
  }
  .offset-gt-lg-35, .flex-offset-gt-lg-35 {
    margin-left: 35%;
  }
  .offset-gt-lg-40, .flex-offset-gt-lg-40 {
    margin-left: 40%;
  }
  .offset-gt-lg-45, .flex-offset-gt-lg-45 {
    margin-left: 45%;
  }
  .offset-gt-lg-50, .flex-offset-gt-lg-50 {
    margin-left: 50%;
  }
  .offset-gt-lg-55, .flex-offset-gt-lg-55 {
    margin-left: 55%;
  }
  .offset-gt-lg-60, .flex-offset-gt-lg-60 {
    margin-left: 60%;
  }
  .offset-gt-lg-65, .flex-offset-gt-lg-65 {
    margin-left: 65%;
  }
  .offset-gt-lg-70, .flex-offset-gt-lg-70 {
    margin-left: 70%;
  }
  .offset-gt-lg-75, .flex-offset-gt-lg-75 {
    margin-left: 75%;
  }
  .offset-gt-lg-80, .flex-offset-gt-lg-80 {
    margin-left: 80%;
  }
  .offset-gt-lg-85, .flex-offset-gt-lg-85 {
    margin-left: 85%;
  }
  .offset-gt-lg-90, .flex-offset-gt-lg-90 {
    margin-left: 90%;
  }
  .offset-gt-lg-95, .flex-offset-gt-lg-95 {
    margin-left: 95%;
  }
  .offset-gt-lg-33, .flex-offset-gt-lg-33 {
    margin-left: calc(100% / 3);
  }
  .offset-gt-lg-66, .flex-offset-gt-lg-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center;
  }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1920px) {
  .flex-gt-lg {
    flex: 1 1 0%;
  }
}

@media (min-width: 1920px) {
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-0,
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-gt-lg-0,
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-5,
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-5,
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-10,
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-10,
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-15,
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-15,
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-20,
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-20,
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-25,
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-25,
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-30,
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-30,
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-35,
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-35,
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-40,
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-40,
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-45,
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-45,
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-50,
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-50,
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-55,
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-55,
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-60,
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-60,
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-65,
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-65,
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-70,
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-70,
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-75,
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-75,
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-80,
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-80,
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-85,
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-85,
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-90,
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-90,
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-95,
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-95,
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-100,
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-100,
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-gt-lg-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-gt-lg-column > .flex {
    min-height: 0;
  }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-gt-lg-column {
    flex-direction: column;
  }
  .layout-gt-lg-row {
    flex-direction: row;
  }
  .flex-order-xl--20 {
    order: -20;
  }
  .flex-order-xl--19 {
    order: -19;
  }
  .flex-order-xl--18 {
    order: -18;
  }
  .flex-order-xl--17 {
    order: -17;
  }
  .flex-order-xl--16 {
    order: -16;
  }
  .flex-order-xl--15 {
    order: -15;
  }
  .flex-order-xl--14 {
    order: -14;
  }
  .flex-order-xl--13 {
    order: -13;
  }
  .flex-order-xl--12 {
    order: -12;
  }
  .flex-order-xl--11 {
    order: -11;
  }
  .flex-order-xl--10 {
    order: -10;
  }
  .flex-order-xl--9 {
    order: -9;
  }
  .flex-order-xl--8 {
    order: -8;
  }
  .flex-order-xl--7 {
    order: -7;
  }
  .flex-order-xl--6 {
    order: -6;
  }
  .flex-order-xl--5 {
    order: -5;
  }
  .flex-order-xl--4 {
    order: -4;
  }
  .flex-order-xl--3 {
    order: -3;
  }
  .flex-order-xl--2 {
    order: -2;
  }
  .flex-order-xl--1 {
    order: -1;
  }
  .flex-order-xl-0 {
    order: 0;
  }
  .flex-order-xl-1 {
    order: 1;
  }
  .flex-order-xl-2 {
    order: 2;
  }
  .flex-order-xl-3 {
    order: 3;
  }
  .flex-order-xl-4 {
    order: 4;
  }
  .flex-order-xl-5 {
    order: 5;
  }
  .flex-order-xl-6 {
    order: 6;
  }
  .flex-order-xl-7 {
    order: 7;
  }
  .flex-order-xl-8 {
    order: 8;
  }
  .flex-order-xl-9 {
    order: 9;
  }
  .flex-order-xl-10 {
    order: 10;
  }
  .flex-order-xl-11 {
    order: 11;
  }
  .flex-order-xl-12 {
    order: 12;
  }
  .flex-order-xl-13 {
    order: 13;
  }
  .flex-order-xl-14 {
    order: 14;
  }
  .flex-order-xl-15 {
    order: 15;
  }
  .flex-order-xl-16 {
    order: 16;
  }
  .flex-order-xl-17 {
    order: 17;
  }
  .flex-order-xl-18 {
    order: 18;
  }
  .flex-order-xl-19 {
    order: 19;
  }
  .flex-order-xl-20 {
    order: 20;
  }
  .offset-xl-0, .flex-offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-5, .flex-offset-xl-5 {
    margin-left: 5%;
  }
  .offset-xl-10, .flex-offset-xl-10 {
    margin-left: 10%;
  }
  .offset-xl-15, .flex-offset-xl-15 {
    margin-left: 15%;
  }
  .offset-xl-20, .flex-offset-xl-20 {
    margin-left: 20%;
  }
  .offset-xl-25, .flex-offset-xl-25 {
    margin-left: 25%;
  }
  .offset-xl-30, .flex-offset-xl-30 {
    margin-left: 30%;
  }
  .offset-xl-35, .flex-offset-xl-35 {
    margin-left: 35%;
  }
  .offset-xl-40, .flex-offset-xl-40 {
    margin-left: 40%;
  }
  .offset-xl-45, .flex-offset-xl-45 {
    margin-left: 45%;
  }
  .offset-xl-50, .flex-offset-xl-50 {
    margin-left: 50%;
  }
  .offset-xl-55, .flex-offset-xl-55 {
    margin-left: 55%;
  }
  .offset-xl-60, .flex-offset-xl-60 {
    margin-left: 60%;
  }
  .offset-xl-65, .flex-offset-xl-65 {
    margin-left: 65%;
  }
  .offset-xl-70, .flex-offset-xl-70 {
    margin-left: 70%;
  }
  .offset-xl-75, .flex-offset-xl-75 {
    margin-left: 75%;
  }
  .offset-xl-80, .flex-offset-xl-80 {
    margin-left: 80%;
  }
  .offset-xl-85, .flex-offset-xl-85 {
    margin-left: 85%;
  }
  .offset-xl-90, .flex-offset-xl-90 {
    margin-left: 90%;
  }
  .offset-xl-95, .flex-offset-xl-95 {
    margin-left: 95%;
  }
  .offset-xl-33, .flex-offset-xl-33 {
    margin-left: calc(100% / 3);
  }
  .offset-xl-66, .flex-offset-xl-66 {
    margin-left: calc(200% / 3);
  }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
  }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center;
  }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end;
  }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around;
  }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between;
  }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
  .flex-xl {
    flex: 1;
    box-sizing: border-box;
  }
}

@media screen\0  and (min-width: 1920px) {
  .flex-xl {
    flex: 1 1 0%;
  }
}

@media (min-width: 1920px) {
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-0,
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .layout-column > .flex-xl-0,
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-5,
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-5,
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-10,
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-10,
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-15,
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-15,
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-20,
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-20,
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-25,
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-25,
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-30,
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-30,
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-35,
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-35,
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-40,
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-40,
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-45,
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-45,
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-50,
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-50,
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-55,
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-55,
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-60,
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-60,
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-65,
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-65,
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-70,
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-70,
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-75,
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-75,
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-80,
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-80,
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-85,
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-85,
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-90,
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-90,
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-95,
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-95,
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-100,
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-100,
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33, .layout-xl-row > .flex-xl-33, .layout-xl-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66, .layout-xl-row > .flex-xl-66, .layout-xl-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .layout-row > .flex, .layout-xl-row > .flex {
    min-width: 0;
  }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33, .layout-xl-column > .flex-xl-33, .layout-xl-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66, .layout-xl-column > .flex-xl-66, .layout-xl-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .layout-column > .flex, .layout-xl-column > .flex {
    min-height: 0;
  }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout-xl-column {
    flex-direction: column;
  }
  .layout-xl-row {
    flex-direction: row;
  }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none;
  }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
}

@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important;
  }
}

/* Angular Material  */
md-dialog.md-default-theme, md-dialog {
  border-radius: 0;
}

md-input-container {
  text-align: start;
}

md-backdrop {
  z-index: 70 !important;
}

md-toolbar {
  height: 40px;
  min-height: 40px;
}

md-bottom-sheet.md-grid {
  z-index: 999;
}

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: rgba(95, 95, 95, 0.85);
}

md-checkbox.grid-checkbox > .md-container {
  top: 85%;
  left: 7px;
}

md-dialog {
  border-radius: 0;
}

md-input-container.grid-input {
  padding: 0;
  margin: 0;
  top: 12px;
}

md-sidenav {
  z-index: 70 !important;
}

md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
  background: #3f51b5;
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
  background-color: rgba(63, 81, 181, 0.5);
}

md-switch.md-default-theme.md-checked .md-ink-ripple, md-switch.md-checked .md-ink-ripple {
  color: #3f51b5;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
  color: #3f51b5;
  background: #3f51b5;
}

md-toast {
  z-index: 99999 !important;
}

md-tooltip {
  border-radius: 0;
}

md-whiteframe {
  background: #fff;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  background-color: #eee;
}

ul {
  list-style-type: none;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.invisible {
  color: transparent;
}

.pin-bar {
  background-color: rgba(87, 91, 101, 0.5);
  width: 100%;
  color: #fff;
  padding: 5px 0;
  cursor: pointer;
}

.disable-clean {
  background: none;
  background-image: none !important;
  border-width: 0 0 1px 0 !important;
  border-style: solid !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

md-divider {
  border-top-width: 2px;
}

.decimal-align {
  width: 20%;
  text-align: right;
}

.decimal-align-bill {
  width: 70%;
  text-align: right;
}

@media screen and (max-width: 960px) {
  .decimal-align {
    width: 50%;
  }
  .decimal-align-bill {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .decimal-align {
    width: 75%;
  }
}

@media screen and (max-width: 580px) {
  .decimal-align {
    width: 100%;
  }
}

.notice-message {
  font-size: 1.2em;
  padding-bottom: 20px;
}

.small-font {
  font-size: 0.8em;
}

.menu-button {
  padding: 0 30px 0 30px !important;
}

.menu-button i {
  margin-top: 10px;
  margin-right: 5px;
}

.alert-menu {
  cursor: pointer;
}

.alert-menu:focus {
  outline: none;
}

.flex-locked-grid .k-grid-header {
  padding-right: 0 !important;
}

.flex-locked-grid .k-grid-content {
  overflow-y: auto;
}

.flex-locked-grid .k-grid-header-locked, .flex-locked-grid .k-grid-content-locked {
  width: 10% !important;
  min-width: 175px;
}

.flex-locked-grid .k-grid-header-wrap table, .flex-locked-grid .k-grid-content table {
  width: 100% !important;
  min-width: 700px;
}

footer {
  padding: 0 20px;
}

.k-editor .k-editable-area {
  padding: 20px;
}

#loading-bar .bar {
  background: rgba(255, 255, 255, 0.4);
  height: 5px;
}

.md-light-bg {
  background-color: #888;
}

.md-primary-bg {
  background-color: #2d323e;
}

.md-accent-bg {
  background-color: #3f51b5;
}

#main-content {
  background-color: #fff;
}

.inner-content {
  padding: 25px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
md-sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

md-sidenav .navigation-header {
  height: 64px;
  min-height: 64px;
  background-color: rgba(255, 255, 255, 0.05);
}

md-sidenav .navigation-header .logo .logo-text {
  color: #FFFFFF;
  margin-left: 16px;
  font-size: 16px;
}

md-sidenav .navigation-header .fold-toggle {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: rotate(0deg);
  margin: 0;
  padding: 8px;
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
}

md-sidenav:not(.md-locked-open) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px;
  }
  .ms-navigation-folded md-sidenav {
    position: absolute;
  }
  .ms-navigation-folded md-sidenav .navigation-header .fold-toggle {
    transform: rotate(180deg);
    opacity: 0;
  }
  .ms-navigation-folded.ms-navigation-folded-open md-sidenav .navigation-header .fold-toggle {
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
    opacity: 1;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) md-sidenav {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) md-sidenav .navigation-header {
    padding: 0 16px;
  }
  .ms-navigation-folded:not(.ms-navigation-folded-open) md-sidenav .navigation-header .logo-text {
    display: none;
  }
}

md-toobar h1 {
  font-size: 1.250em;
  font-weight: 400;
  margin: auto;
}

md-menu-content div > hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

md-menu-content div.last > hr {
  display: none;
}

.logo-text {
  width: 100%;
  text-align: center;
}

.profile-dropdown {
  box-sizing: border-box;
  color: currentColor;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
  outline: none;
  border: 0;
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  background: transparent;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media screen and (min-width: 960px) {
  .toolbar-right-options {
    margin-right: 250px;
  }
}

#layout-content-only {
  height: 100%;
}

#auth-layout {
  height: 100vh;
}

#auth-layout .auth-content {
  background-image: linear-gradient(rgba(70, 70, 70, 0.85), rgba(70, 70, 70, 0.85)), url("../img/Background/2.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/Background/1.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/Background/1.jpg', sizingMethod='scale')";
}

#auth-layout .auth-content .auth-inner-content {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHElEQVQIW2MMDwv/v3LVSkYGKIAzMARgKjFUAABVFQgFSoCMxAAAAABJRU5ErkJggg==) repeat;
  height: 100vh;
}

#auth-layout .auth-content .auth-inner-content div {
  padding: 2rem 0 0 2rem;
}

#auth-layout .auth-content .auth-inner-content div .logo {
  font-size: 4rem;
}

#auth-layout .auth-form {
  overflow-y: auto;
  max-width: 600px;
  padding: 0 2rem;
}

#auth-layout .auth-form button {
  width: 96%;
}

#auth-layout .auth-form .k-datepicker {
  width: 100%;
  margin-bottom: 15px;
}

#auth-layout .auth-form .k-datepicker .k-input, #auth-layout .auth-form .k-timepicker .k-input {
  background-color: #eee;
}

#auth-layout .auth-form .k-autocomplete.k-state-default, #auth-layout .auth-form .k-picker-wrap.k-state-default, #auth-layout .auth-form .k-numeric-wrap.k-state-default, #auth-layout .auth-form .k-dropdown-wrap.k-state-default {
  background-color: #eee;
  border-color: #d2d2d2;
}

#auth-layout .auth-form .k-picker-wrap.k-state-default > .k-select {
  border-color: #d2d2d2;
}

#auth-layout .auth-form .k-picker-wrap {
  border-radius: 0;
}

@media screen and (max-width: 955px) {
  #auth-layout .auth-form {
    width: 100%;
    max-width: 100%;
  }
}

.auth-options-list a {
  padding: 0 4px;
}

footer {
  background-color: #575B65;
  color: #fff;
  height: 30px;
}

footer a {
  color: #fff;
  text-decoration: none;
}

#privacyStatementContent {
  max-height: 60vh !important;
  overflow-y: auto;
  margin-bottom: 20px;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

/*----------------------------------------------------------------*/
/*  g5-navigation
/*----------------------------------------------------------------*/
.g5-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
}

.g5-navigation ul, .g5-navigation li {
  padding: 0;
  margin: 0;
}

.g5-navigation ul {
  display: block;
  overflow: hidden;
}

.g5-navigation ul li {
  position: relative;
}

.g5-navigation ul li.collapsed ul {
  display: none;
}

.g5-navigation .animate-height {
  transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.g5-navigation .g5-navigation-node .g5-navigation-item {
  display: block;
  position: relative;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
}

.g5-navigation .g5-navigation-node .g5-navigation-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button .icon {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 16px 0 0;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button span {
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0.1s;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button a {
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0.1s;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button .arrow {
  margin: 0;
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button .badge {
  position: absolute;
  top: 50%;
  right: 16px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in-out 0.1s;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button.active {
  color: #FFFFFF;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button.active .icon {
  color: #FFFFFF;
}

.g5-navigation .g5-navigation-node .g5-navigation-item > .g5-navigation-button.active .badge {
  background: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.g5-navigation .g5-navigation-node:not(.collapsed) > .g5-navigation-item > .g5-navigation-button .arrow {
  transform: rotate(90deg);
}

.g5-navigation .g5-navigation-node.group > .g5-navigation-item {
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  cursor: auto;
  background: none !important;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.g5-navigation .g5-navigation-node.group > .g5-navigation-item > .g5-navigation-button {
  height: 32px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.3);
}

.g5-navigation .g5-navigation-node:not(.group).has-children:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.12);
}

.g5-navigation .g5-navigation-node:not(.group).has-children.collapsing {
  background-color: rgba(0, 0, 0, 0.12);
}

.g5-navigation .g5-navigation-node:not(.group).has-children .has-children:not(.collapsed) {
  background: none;
}

.g5-navigation li .g5-navigation-button {
  padding-left: 24px;
}

.g5-navigation li li .g5-navigation-button {
  padding-left: 56px;
}

.g5-navigation li li li .g5-navigation-button {
  padding-left: 72px;
}

.g5-navigation li li li li .g5-navigation-button {
  padding-left: 88px;
}

.g5-navigation li li li li li .g5-navigation-button {
  padding-left: 104px;
}

.g5-navigation li.group .g5-navigation-button {
  padding-left: 24px;
}

.g5-navigation li.group li .g5-navigation-button {
  padding-left: 24px;
}

.g5-navigation li.group li li .g5-navigation-button {
  padding-left: 56px;
}

.g5-navigation li.group li li li .g5-navigation-button {
  padding-left: 72px;
}

.g5-navigation li.group li li li li .g5-navigation-button {
  padding-left: 88px;
}

#horizontal-navigation {
  background: #FFFFFF;
  z-index: 54;
}

#horizontal-navigation .g5-navigation-horizontal {
  display: flex;
  position: relative;
  user-select: none;
}

#horizontal-navigation .g5-navigation-horizontal ul, #horizontal-navigation .g5-navigation-horizontal li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal {
  display: flex;
  flex: 1;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal li {
  position: relative;
  cursor: pointer;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal li:not(.active):hover {
  background: rgba(0, 0, 0, 0.12);
}

#horizontal-navigation .g5-navigation-horizontal .horizontal > li > .g5-navigation-horizontal-item > .g5-navigation-horizontal-button {
  padding: 20px 24px;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal > li > .g5-navigation-horizontal-item > .g5-navigation-horizontal-button .arrow {
  display: none;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal > li:last-child {
  margin-right: 0;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .has-children ul {
  display: none;
  position: absolute;
  background: #FFFFFF;
  z-index: 999;
  min-width: 200px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  top: 0;
  left: 100%;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .has-children:hover > ul {
  display: block;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal > .has-children > ul {
  top: 100%;
  left: 0;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button {
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  padding: 14px 16px;
  text-decoration: none;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button .icon {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button .badge {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out 0.1s;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button .arrow {
  margin-left: 8px;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button.active {
  color: #FFFFFF;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button.active .badge {
  background: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

#horizontal-navigation .g5-navigation-horizontal .horizontal .g5-navigation-horizontal-item .g5-navigation-horizontal-button.active .icon {
  color: #FFFFFF !important;
}

@media only screen and (min-width: 960px) {
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .animate-height {
    transition: none;
  }
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .g5-navigation-node .g5-navigation-item .g5-navigation-button .icon {
    margin: 0;
  }
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .g5-navigation-node .g5-navigation-item .g5-navigation-button .title,
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .g5-navigation-node .g5-navigation-item .g5-navigation-button .arrow,
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .g5-navigation-node .g5-navigation-item .g5-navigation-button .badge {
    opacity: 0;
    visibility: hidden;
  }
  body.g5-navigation-folded:not(.g5-navigation-folded-open) .g5-navigation .g5-navigation-node.group > .g5-navigation-item .g5-navigation-button:before {
    content: '';
    display: block;
    position: relative;
    width: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  body.g5-navigation-folded:not(.g5-navigation-folded-open) #g5-navigation-fold-expander {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  body.g5-navigation-folded #g5-navigation-fold-collapser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 59;
  }
}

@media only screen and (max-width: 960px) {
  body.g5-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle {
    height: 64px;
    margin-right: 16px;
  }
  body.g5-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle .md-button {
    margin: 0;
    width: 64px;
    height: 64px;
  }
  body.g5-navigation-horizontal-mobile-menu-active #horizontal-navigation .g5-navigation-horizontal {
    display: block !important;
    background: #FFFFFF;
  }
  #horizontal-navigation {
    z-index: 60;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 0 16px 0;
    z-index: 9998;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal {
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal li:not(.active):hover {
    background: none;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal li:not(.active) .g5-navigation-horizontal-item {
    margin: 4px;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal li:not(.active) .g5-navigation-horizontal-item:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal > li {
    padding: 0 12px;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal > li > .g5-navigation-horizontal-item > .g5-navigation-horizontal-button .arrow {
    display: block;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal .has-children ul {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal .has-children:hover > ul {
    display: none;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal .has-children .expanded > .g5-navigation-horizontal-button .arrow {
    transform: rotate(90deg);
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal .has-children .expanded + ul {
    display: block;
    margin-left: 16px;
  }
  #horizontal-navigation ._g5-navigation.scss-navigation-horizontal .horizontal .g5-navigation-horizontal-item {
    min-height: 48px;
    height: 48px;
  }
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-steps span.required {
  font-size: 0.7rem;
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-steps md-input-container {
  font-size: 1rem;
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-dots span {
  width: 5px;
  height: 5px;
  margin: 0 4px;
  border-radius: 50%;
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-dots span:not(.md-accent-bg) {
  background: rgba(0, 0, 0, 0.12);
}

.ms-stepper .ms-stepper-horizontal .ms-stepper-dots span.selected {
  width: 8px;
  height: 8px;
}

tr input {
  width: 145px;
}

.editor-header {
  background-color: #3f51b5;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  border-collapse: separate;
  border-spacing: 4px;
  border-color: #e6e6e6;
  border-bottom: none;
  padding: 8px;
}

#fd5abc72fce369955a6ecf185bf6d54a {
  position: fixed;
  left: -999999em;
}

.form-label {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.55);
  margin-bottom: 4px;
}

.date-picker-label {
  padding-left: 15px !important;
  bottom: 31px !important;
}

.password-unlock {
  float: right;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 10px;
  color: rgba(0, 0, 0, 0.55);
}

.md-button {
  border-radius: 0;
}

a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

a:hover {
  color: #000;
}

.logo svg {
  height: 4em;
}

.logo.logo-white svg {
  height: 2.25em;
  padding: 5px 0 0 20px;
}

.logo.logo-white svg .cls-1 {
  fill: #fff;
}

.md-tab {
  cursor: pointer !important;
}

#searchForm {
  border: 1px solid;
  border-color: #ccc;
}

#searchForm * {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.MicrosoftMap .NavBar_DropDown {
  width: 250px;
}

.MicrosoftMap .NavBar_Container .slot {
  height: 50px;
}

.MicrosoftMap .NavBar_Container .slot .icon {
  width: 50px;
  height: 50px;
}

.MicrosoftMap .NavBar_Container .slot .pie-label, .MicrosoftMap .NavBar_Container .streetside .pie-label {
  left: 80px;
  margin-top: 5px;
}

.MicrosoftMap .NavBar_Container .slot .pie-label-description, .MicrosoftMap .NavBar_Container .streetside .pie-label-description {
  left: 80px;
  margin-top: 25px;
}

@media (min-width: 960px) {
  #tax-details > div {
    width: 30%;
    max-width: 30em;
  }
}

tr.k-state-selected {
  background-color: #4F62CE !important;
}

tr.k-state-selected.ach-disabled {
  background-color: #ff6861 !important;
}

tr.k-state-selected > td {
  border-color: #FAFAFA !important;
}

tr.k-state-selected input {
  color: #fff;
}

#paymentGrid td {
  padding: 0.2em 1em;
}

#paymentGrid tr.k-footer-template td {
  padding: 1.4em;
}

.k-grid .k-hierarchy-col {
  width: 4px;
}

.new-header {
  background-color: #EAEAEA;
}

.new-header .header-top {
  padding: 25px;
}

.new-header .header-top .title {
  font-size: 1.5em;
  font-weight: 500;
  color: #717171;
}

.header-box {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: #FAFAFA;
  color: #717171;
}

.header-box.notice {
  background: #3f51b5;
  color: #fff;
}

.header-box.alert {
  background: #ff6861;
  color: #fff;
}

.header-box input {
  margin: 0 20px;
  width: calc(100% - 60px);
  border: none;
}

.header-box input:focus, .header-box input:active {
  outline: none;
  border: none;
}

#usage .header {
  padding: 20px 30px;
  min-height: 200px;
}

#usage .header > div {
  padding-bottom: 20px;
}

#usage .year {
  margin: 2px 2px 2px 12px;
  font-size: 2em;
  color: #333;
  font-weight: 600;
}

#usage .charts {
  padding: 0 30px;
}

#print .right-border {
  border-right: 1px solid #ddd;
}

#print .print-container {
  margin: 20px;
  padding: 80px 40px;
  max-width: 1700px;
}

#print .chart {
  width: 50%;
}

#print .bill-contact {
  text-align: right;
  margin: 0;
}

#print table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
}

#print table.full-width {
  width: 100%;
}

#print table caption {
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #ddd;
  background: #efefef;
  border-bottom: none;
  margin: 0;
  padding: 5px;
}

#print table tr {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

#print table th,
#print table td {
  padding: .625em;
  text-align: center;
}

#print table.side-label tr th {
  border-right: 1px solid #ddd;
}

#print table tbody td {
  background: #fff;
}

#print table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  #print .bill-contact {
    text-align: left;
  }
  #print table.side-label, #print .chart {
    width: 100%;
  }
  #print table.fluid {
    border: 0;
  }
  #print table.fluid caption {
    font-size: 1.3em;
  }
  #print table.fluid thead {
    display: none;
  }
  #print table.fluid tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  #print table.fluid td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  #print table.fluid td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  #print table.fluid td:last-child {
    border-bottom: 0;
  }
}

#pdfexport ul {
  list-style: none;
}

#pdfexport ul:first-child {
  padding-left: 0;
}

#invoice {
  padding: 0;
  overflow: auto;
}

#invoice .invoice-container {
  padding: 64px;
}

#invoice .invoice-container .card {
  width: 1020px;
  min-width: 1020px;
  max-width: 1020px;
  padding: 88px;
  overflow: hidden;
  background: #FFFFFF;
}

#invoice .invoice-container .card .header .ids {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.54);
}

#invoice .invoice-container .card .header .ids .detail {
  width: 160px;
}

#invoice .invoice-container .card .header .ids .seller {
  margin-bottom: 80px;
}

#invoice .invoice-container .card .header .ids .seller .logo {
  width: 156px;
  height: 156px;
  font-size: 115px;
}

#invoice .invoice-container .card .header .ids .client .label {
  width: 156px;
  font-size: 24px;
  font-weight: 300;
}

#invoice .invoice-container .card .header .ids .divider {
  width: 1px;
  margin: 0 48px;
  background-color: rgba(0, 0, 0, 0.12);
  height: 144px;
}

#invoice .invoice-container .card .header .summary {
  font-size: 15px;
}

#invoice .invoice-container .card .header .summary .label {
  color: rgba(0, 0, 0, 0.54);
  text-align: right;
  padding-right: 16px;
}

#invoice .invoice-container .card .header .summary .value {
  color: black;
}

#invoice .invoice-container .card .header .summary .code {
  font-size: 35px;
  font-weight: 300;
}

#invoice .invoice-container .card .header .summary .code td {
  padding-bottom: 32px;
}

#invoice .invoice-container .card .content .invoice-table {
  margin-top: 96px;
  font-size: 15px;
}

#invoice .invoice-container .card .content .invoice-table .title {
  font-size: 17px;
}

#invoice .invoice-container .card .content .invoice-table .detail {
  margin-top: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  max-width: 360px;
}

#invoice .invoice-container .card .content .invoice-table-footer {
  margin: 32px 0 96px 0;
}

#invoice .invoice-container .card .content .invoice-table-footer tr td {
  text-align: right;
  font-size: 17px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: none;
  padding: 8px 8px;
}

#invoice .invoice-container .card .content .invoice-table-footer tr td:first-child {
  text-align: left;
}

#invoice .invoice-container .card .content .invoice-table-footer tr.discount td {
  padding-bottom: 32px;
}

#invoice .invoice-container .card .content .invoice-table-footer tr.total td {
  padding: 32px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 35px;
  font-weight: 300;
  color: black;
}

#invoice .invoice-container .card .footer .note {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 24px;
}

#invoice .invoice-container .card .footer .logo, #invoice .invoice-container .card .footer .small-note {
  -ms-flex: 0 1 auto;
}

#invoice .invoice-container .card .footer .logo {
  width: 32px;
  min-width: 32px;
  height: 32px;
  font-size: 17px;
  font-weight: 500;
  margin-right: 24px;
  border-radius: 2px;
  overflow: hidden;
}

#invoice .invoice-container .card .footer .small-note {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  line-height: 18px;
}

/* PRINT STYLES */
@media print {
  /* Invoice Specific Styles */
  #invoice .invoice-container {
    padding: 0;
  }
  #invoice .invoice-container .card {
    width: 100%;
    min-width: 0;
    background: none;
    padding: 0;
    box-shadow: none;
  }
  #invoice .invoice-container .card .header .ids .detail {
    width: 120pt;
  }
  #invoice .invoice-container .card .header .ids .seller {
    margin-bottom: 8pt;
  }
  #invoice .invoice-container .card .header .ids .seller .logo {
    width: 60pt;
    height: 60pt;
    font-size: 40pt;
  }
  #invoice .invoice-container .card .header .ids .client .label {
    width: 60pt;
    font-size: 16pt;
  }
  #invoice .invoice-container .card .header .ids .divider {
    margin: 0 12pt;
    height: 100pt;
  }
  #invoice .invoice-container .card .header .summary {
    font-size: 10pt;
  }
  #invoice .invoice-container .card .header .summary .code {
    font-size: 18pt;
  }
  #invoice .invoice-container .card .header .summary .code td {
    padding-bottom: 10pt;
  }
  #invoice .invoice-container .card .content .invoice-table {
    margin-top: 16pt;
  }
  #invoice .invoice-container .card .content .invoice-table thead tr th {
    font-size: 10pt;
    max-width: 60pt;
  }
  #invoice .invoice-container .card .content .invoice-table thead tr th:first-child {
    padding-left: 0;
  }
  #invoice .invoice-container .card .content .invoice-table thead tr th:last-child {
    padding-right: 0;
  }
  #invoice .invoice-container .card .content .invoice-table tbody tr td:first-child {
    padding-left: 0;
  }
  #invoice .invoice-container .card .content .invoice-table tbody tr td:last-child {
    padding-right: 0;
  }
  #invoice .invoice-container .card .content .invoice-table .title {
    font-size: 10pt;
  }
  #invoice .invoice-container .card .content .invoice-table .detail {
    margin-top: 4pt;
    font-size: 9pt;
    max-width: none;
  }
  #invoice .invoice-container .card .content .invoice-table-footer {
    margin: 16pt 0;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr td {
    font-size: 13pt;
    padding: 4pt 4pt;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr td:first-child {
    text-align: left;
    padding-left: 0;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr td:last-child {
    padding-right: 0;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr.discount td {
    padding-bottom: 16pt;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr.total td {
    padding: 16pt 4pt 0 4pt;
    font-size: 16pt;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr.total td:first-child {
    padding-left: 0;
  }
  #invoice .invoice-container .card .content .invoice-table-footer tr.total td:last-child {
    padding-right: 0;
  }
  #invoice .invoice-container .card .footer .note {
    font-size: 10pt;
    margin-bottom: 8pt;
  }
  #invoice .invoice-container .card .footer .logo {
    font-size: 14pt;
    margin-right: 8pt;
  }
  #invoice .invoice-container .card .footer .small-note {
    font-size: 8pt;
    line-height: normal;
  }
}

/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/
.page-layout {
  position: relative;
  overflow: hidden;
}

.page-layout .top-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
  background-image: url("../img/header-bg.png");
  background-size: cover;
}

.page-layout md-backdrop {
  z-index: 50;
}

.page-layout > .header .breadcrumb {
  margin-bottom: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.page-layout > .header .breadcrumb md-icon {
  margin: 0;
}

.page-layout > .header .breadcrumb .separator {
  margin: 0 8px;
}

.page-layout > .header .title {
  font-size: 34px;
}

.page-layout.carded {
  min-height: 100%;
  height: 100%;
}

.page-layout.carded.fullwidth.single-scroll {
  height: auto;
}

.page-layout.carded.fullwidth.single-scroll > .center .content-card .content {
  overflow: hidden;
}

.page-layout.carded.fullwidth > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 20px;
}

.page-layout.carded.fullwidth > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}

.page-layout.carded.fullwidth > .center .content-card {
  background: #fff;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.page-layout.carded.fullwidth > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-layout.carded.fullwidth > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}

.page-layout.carded.left-sidenav.single-scroll {
  height: auto;
}

.page-layout.carded.left-sidenav.single-scroll > .center .content-card .content {
  overflow: hidden;
}

.page-layout.carded.left-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  z-index: 51;
}

.page-layout.carded.left-sidenav > .sidenav .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
}

.page-layout.carded.left-sidenav > .sidenav .content {
  background: transparent;
  padding: 24px;
}

.page-layout.carded.left-sidenav > .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: transparent;
  box-shadow: none;
}

.page-layout.carded.left-sidenav > .sidenav.md-locked-open + .center {
  margin-left: 0;
}

.page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .content {
  overflow: hidden;
}

.page-layout.carded.left-sidenav > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
}

.page-layout.carded.left-sidenav > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}

.page-layout.carded.left-sidenav > .center .content-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.page-layout.carded.left-sidenav > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-layout.carded.left-sidenav > .center .content-card .toolbar .sidenav-toggle {
  height: 64px;
  line-height: 64px;
  margin: 0 !important;
  padding: 0 8px 0 0 !important;
  border-radius: 0;
}

.page-layout.carded.left-sidenav > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}

.page-layout.carded.right-sidenav.single-scroll {
  height: auto;
}

.page-layout.carded.right-sidenav.single-scroll > .center .content-card .content {
  overflow: hidden;
}

.page-layout.carded.right-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  z-index: 51;
}

.page-layout.carded.right-sidenav > .sidenav .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px 24px 24px 0;
}

.page-layout.carded.right-sidenav > .sidenav .content {
  background: transparent;
  padding: 24px 24px 24px 0;
}

.page-layout.carded.right-sidenav > .sidenav.md-locked-open {
  width: 196px;
  min-width: 196px;
  max-width: 196px;
  z-index: 2;
  background: transparent;
  box-shadow: none;
}

.page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .header {
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .content {
  overflow: hidden;
  padding: 24px;
}

.page-layout.carded.right-sidenav > .center {
  position: relative;
  z-index: 2;
  margin-left: 32px;
  margin-right: 32px;
}

.page-layout.carded.right-sidenav > .center .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px;
}

.page-layout.carded.right-sidenav > .center .content-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.page-layout.carded.right-sidenav > .center .content-card .toolbar {
  padding: 8px 24px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-layout.carded.right-sidenav > .center .content-card .toolbar .sidenav-toggle {
  height: 64px;
  line-height: 64px;
  margin: 0 !important;
  padding: 0 0 0 8px !important;
  border-radius: 0;
}

.page-layout.carded.right-sidenav > .center .content-card .content {
  padding: 24px;
  background: #FFFFFF;
}

.page-layout.simple.fullwidth {
  min-height: 100%;
}

.page-layout.simple.fullwidth > .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}

.page-layout.simple.fullwidth > .content {
  padding: 24px;
}

.page-layout.simple.left-sidenav {
  min-height: 100%;
  height: 100%;
}

.page-layout.simple.left-sidenav.single-scroll {
  height: auto;
}

.page-layout.simple.left-sidenav.single-scroll > .center {
  overflow: hidden;
}

.page-layout.simple.left-sidenav .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  padding: 24px;
  z-index: 51;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.page-layout.simple.left-sidenav .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  box-shadow: none;
  background: transparent;
}

.page-layout.simple.left-sidenav > .center {
  position: relative;
  overflow: auto;
  z-index: 3;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.page-layout.simple.left-sidenav > .center .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}

.page-layout.simple.left-sidenav > .center .content {
  padding: 24px;
  background: #FFFFFF;
}

.page-layout.simple.right-sidenav {
  min-height: 100%;
  height: 100%;
}

.page-layout.simple.right-sidenav.single-scroll {
  height: auto;
}

.page-layout.simple.right-sidenav.single-scroll > .center {
  overflow: hidden;
}

.page-layout.simple.right-sidenav > .center {
  position: relative;
  overflow: auto;
  z-index: 3;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.page-layout.simple.right-sidenav > .center .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}

.page-layout.simple.right-sidenav > .center .content {
  padding: 24px;
  background: #FFFFFF;
}

.page-layout.simple.right-sidenav > .sidenav {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  padding: 24px;
  z-index: 51;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.page-layout.simple.right-sidenav > .sidenav.md-locked-open {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  box-shadow: none;
  background: transparent;
}

.page-layout.simple.tabbed {
  min-height: 100%;
}

.page-layout.simple.tabbed > .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 24px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
}

.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 0 24px;
}

.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
  height: 56px;
}

.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
  height: 56px;
}

.page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
  padding: 16px 24px;
  text-transform: none;
}

.page-layout.simple.tabbed > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
  top: 56px;
}

.page-layout.simple.tabbed > .content > md-tabs > md-tabs-content-wrapper > md-tab-content {
  padding: 24px;
}

.page-layout.blank {
  min-height: 100%;
  padding: 24px;
}

.single-scroll .carded.fullwidth {
  height: auto;
}

.single-scroll .carded.fullwidth > .center .content-card .content {
  overflow: hidden;
}

.single-scroll .carded.left-sidenav {
  height: auto;
}

.single-scroll .carded.left-sidenav > .center .content-card .content {
  overflow: hidden;
}

.single-scroll .carded.right-sidenav {
  height: auto;
}

.single-scroll .carded.right-sidenav > .center .content-card .content {
  overflow: hidden;
}

.single-scroll .simple.left-sidenav {
  height: auto;
}

.single-scroll .simple.left-sidenav > .center {
  overflow: hidden;
}

.single-scroll .simple.right-sidenav {
  height: auto;
}

.single-scroll .simple.right-sidenav > .center {
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .page-layout .top-bg {
    height: 160px;
  }
  .page-layout.carded.right-sidenav > .center, .page-layout.carded.left-sidenav > .center, .page-layout.carded.fullwidth > .center {
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 20px;
  }
  .page-layout.carded.right-sidenav > .center .header, .page-layout.carded.left-sidenav > .center .header, .page-layout.carded.fullwidth > .center .header {
    height: 96px;
    min-height: 96px;
    max-height: 96px;
    padding: 16px;
  }
  .page-layout.carded.fullwidth {
    height: auto;
  }
  .page-layout.carded.fullwidth > .center .content-card .content {
    overflow: hidden;
  }
  .page-layout.carded.right-sidenav, .page-layout.carded.left-sidenav {
    height: auto;
  }
  .page-layout.carded.right-sidenav > .center .content-card .content, .page-layout.carded.left-sidenav > .center .content-card .content {
    overflow: hidden;
  }
  .page-layout.simple.fullwidth > .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px;
  }
  .page-layout.simple.right-sidenav > .center .header, .page-layout.simple.left-sidenav > .center .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px;
  }
  .page-layout.simple.left-sidenav, .page-layout.simple.right-sidenav {
    height: auto;
  }
  .page-layout.simple.left-sidenav > .center, .page-layout.simple.right-sidenav > .center {
    overflow: hidden;
  }
}

#mail .mail-list-pane .mail-list-item {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

#mail .mail-list-pane .mail-list-item.unread {
  background: #FFFFFF;
}

#mail .mail-list-pane .mail-list-item.unread .info .subject,
#mail .mail-list-pane .mail-list-item.unread .info .message {
  font-weight: 500;
}

#mail .mail-list-pane .mail-list-item.selected {
  background: #E3F2FD;
}

#mail .mail-list-pane .mail-list-item.checked {
  background: #FFF8E1;
}

#mail .mail-list-pane .mail-list-item .info {
  overflow: hidden;
  width: 0;
  margin: 0 8px;
}

#mail .mail-list-pane .mail-list-item .info .name {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 8px;
}

#mail .mail-list-pane .mail-list-item .info .name .avatar {
  min-width: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#mail .mail-list-pane .mail-list-item .info .name .has-attachment {
  margin-left: 8px;
  transform: rotate(90deg);
}

#mail .mail-list-pane .mail-list-item .info .message {
  color: rgba(0, 0, 0, 0.54);
}

#mail .mail-list-pane .mail-list-item .time {
  margin: 0 8px;
}

.email-sidenav {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

#mail .center .header {
  padding: 24px 0;
}

#mail .center .header .sidenav-toggle {
  margin: 0;
  width: 56px;
  height: 56px;
  background: #FFFFFF;
  border-radius: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#mail .center .header .search {
  height: 56px;
  line-height: 56px;
  padding: 18px;
  background: #FFFFFF;
}

#mail .center .header .search .icon {
  margin: 0;
}

#mail .center .header .search input {
  padding-left: 16px;
  height: 56px;
  color: rgba(0, 0, 0, 0.54);
}

#mail .center .content-card .toolbar {
  position: relative;
  padding: 8px 16px;
  border-bottom: 1px solid #dadada;
}

#mail .center .content-card .toolbar.mail-read-pane-toolbar {
  display: none;
}

#mail .center .content-card .toolbar .page-info {
  font-weight: 500;
  margin: 0 8px;
  white-space: nowrap;
}

#mail .center .content-card .content-wrapper {
  overflow: hidden;
  position: relative;
}

#mail .center .content-card .content-wrapper .mail-tabs md-tabs-wrapper {
  display: none;
}

#mail .center .content-card .content-wrapper .mail-tabs md-tabs-content-wrapper {
  top: 0;
}

#mail .center .content-card .content-wrapper .mail-tabs .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
}

#mail .center .content-card .content-wrapper .mail-tabs .content.mail-list-pane {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background: #FAFAFA;
}

#mail .center .content-card .content-wrapper .mail-tabs .content.mail-read-pane {
  padding: 0 24px;
}

@media screen and (min-width: 1280px) {
  #mail .center .content-card .content-wrapper .mail-tabs md-tabs-content-wrapper md-tab-content {
    width: 50%;
    opacity: 1;
    animation: none !important;
  }
  #mail .center .content-card .content-wrapper .mail-tabs md-tabs-content-wrapper md-tab-content * {
    visibility: visible;
    transition: none !important;
  }
}

@media screen and (max-width: 600px) {
  #mail .center .content-card .content-wrapper .mail-tabs .content {
    position: relative;
  }
}

#mail .sidenav .header .logo .logo-icon {
  margin: 0 16px 0 0;
}

#mail .sidenav .header .logo .logo-text {
  font-size: 24px;
}

#mail .sidenav .header .account {
  width: 100%;
}

#mail .sidenav .header .account .account-selection {
  margin: 0;
  padding: 0;
}

#mail .sidenav .header .account .account-selection .md-select-label {
  width: 100%;
}

#mail .sidenav .header .account .account-selection .md-select-value {
  padding-left: 0;
}

#mail .sidenav .content {
  height: 100vh;
}

#mail .sidenav .content .compose-button {
  padding: 0 24px 24px 24px;
}

#mail .sidenav .content .compose-button .md-button {
  width: 100%;
  margin: 0;
}

#mail .sidenav.md-locked-open .header {
  color: #FFFFFF;
}

#mail .sidenav.md-locked-open .header .logo {
  padding-top: 27px;
}

#mail .sidenav.md-locked-open .header .logo .logo-icon md-icon {
  color: #FFFFFF;
}

#mail .sidenav:not(.md-locked-open) .header {
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  padding-top: 24px;
}

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px;
}

.navigation-simple .item md-icon {
  margin: 10px 16px 0 0;
}

.navigation-simple .item .title {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px;
}

.navigation-simple .subheader.light {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.navigation-simple md-divider {
  margin: 8px 0;
}

#mail .mail-read-pane {
  background: #FFFFFF;
}

#mail .mail-read-pane .mail-header {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#mail .mail-read-pane .mail-header .subject {
  font-size: 17px;
  font-weight: 500;
}

#mail .mail-read-pane .mail-content {
  padding: 24px 0;
}

#mail .mail-read-pane .mail-content .to {
  color: rgba(0, 0, 0, 0.54);
}

#mail .mail-read-pane .mail-content .to .to-text {
  margin-right: 4px;
  text-transform: lowercase;
}

#mail .mail-read-pane .mail-content .info {
  padding-bottom: 16px;
}

#mail .mail-read-pane .mail-content .info .avatar {
  margin-right: 16px;
}

#mail .mail-read-pane .mail-content .info .name {
  margin-right: 8px;
  font-weight: 500;
}

#mail .mail-read-pane .mail-content .info .toggle-details {
  padding-top: 16px;
  cursor: pointer;
  font-weight: 500;
}

#mail .mail-read-pane .mail-content .info .details {
  padding-top: 8px;
}

#mail .mail-read-pane .mail-content .info .details .title {
  font-weight: 500;
  margin-right: 6px;
}

#mail .mail-read-pane .mail-content .info .details .detail {
  color: rgba(0, 0, 0, 0.54);
}

#mail .mail-read-pane .attachments {
  padding: 24px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

#mail .mail-read-pane .attachments .title {
  margin-bottom: 16px;
  font-weight: 500;
}

#mail .mail-read-pane .attachments .attachment .preview {
  width: 100px;
  margin: 0 16px 8px 0;
}

#mail .mail-read-pane .attachments .attachment .link {
  margin-bottom: 2px;
}

#mail .mail-read-pane .attachments .attachment .size {
  font-size: 11px;
}

#mail .mail-read-pane .reply {
  padding: 24px 0;
}

#mail .mail-read-pane .reply .form {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

#mail .mail-read-pane .reply .form textarea {
  width: 100% !important;
  height: 140px !important;
  border: none;
  padding: 8px;
  box-sizing: border-box;
}

#mail .mail-read-pane .reply .form .actions {
  padding: 8px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.03);
}

#mail .mail-read-pane .reply .form .actions .send-button {
  margin: 0;
  width: 64px;
  min-width: 64px;
  height: 30px;
  line-height: 30px;
  min-height: 30px;
}

#coming-soon {
  height: 100%;
  background: url("/assets/images/backgrounds/march.jpg") no-repeat;
  background-size: cover;
}

#coming-soon #coming-soon-form-wrapper {
  flex: 1 0 auto;
  padding: 32px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form {
  max-width: 700px;
  background: #FFFFFF;
  text-align: center;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top {
  width: 100%;
  padding: 32px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top .logo {
  width: 128px;
  height: 128px;
  line-height: 128px;
  font-size: 86px;
  font-weight: 500;
  margin: 32px auto;
  color: white;
  border-radius: 2px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top .title {
  font-size: 17px;
  margin-top: 16px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top .subtitle {
  margin: 16px auto 0 auto;
  text-align: center;
  max-width: 300px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top timer {
  margin: 48px 0 24px 0;
  width: 100%;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top timer > div {
  padding: 0 16px;
  text-align: center;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top timer > div .value {
  font-size: 34px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form .top timer > div .type {
  color: rgba(0, 0, 0, 0.54);
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form form {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 32px;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form form .message {
  font-weight: 500;
  margin: 8px auto 32px auto;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form form md-input-container {
  width: 320px;
  margin: 8px auto 16px auto;
}

#coming-soon #coming-soon-form-wrapper #coming-soon-form form .md-button {
  width: 220px;
  margin: 16px auto;
}

@media screen and (max-width: 600px) {
  #coming-soon #coming-soon-form-wrapper {
    padding: 16px;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form {
    width: 100%;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form .top {
    padding: 24px;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form .top timer > div {
    padding: 16px 8px;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form form {
    padding: 24px;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form form md-input-container {
    width: 100%;
  }
  #coming-soon #coming-soon-form-wrapper #coming-soon-form form .md-button {
    width: 90%;
  }
}
