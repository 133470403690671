﻿tr input {
    width: 145px;
}

.editor-header {
    background-color: rgb(63,81,181);
    color: #fff;
    border-style: solid;
    border-width: 1px;
    border-collapse: separate;
    border-spacing: 4px;
    border-color: #e6e6e6;
    border-bottom: none;
    padding: 8px;
}
