#usage {

  .header {
    padding: 20px 30px;
    min-height: 200px;
    > div {
      padding-bottom: 20px;
    }
  }

  .year {
    margin: 2px 2px 2px 12px;
    font-size: 2em;
    color: #333;
    font-weight: 600;
  }

  .charts {
    padding: 0 30px;
  }

}
