footer {
  background-color: #575B65;
  color: #fff;
  height: 30px;
  a {
    color: #fff;
    text-decoration: none;
  }
}

#privacyStatementContent {
  max-height: 60vh !important;
  overflow-y: auto;
  margin-bottom: 20px;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
