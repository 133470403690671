﻿md-toobar {
    h1 {
        font-size: 1.250em;
        font-weight: 400;
        margin: auto
    }
}

md-menu-content {
    div > hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #ccc;
        margin: 1em 0;
        padding: 0;
    }
    div.last > hr {
        display: none;
    }
}

.logo-text {
  width: 100%;
  text-align: center;
}

.profile-dropdown {
    box-sizing: border-box;
    color: currentColor;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;
    outline: none;
    border: 0;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    padding: 0 6px;
    margin: 6px 8px;
    line-height: 36px;
    background: transparent;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media screen and (min-width: 960px) {
  .toolbar-right-options {
    margin-right: 250px;
  }
}
