/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple {

    .item {
        position: relative;
        cursor: pointer;
        text-align: left;
        margin: 0;
        padding: 0 24px;
        text-transform: none;
        line-height: 48px;
        max-height: 48px;
        height: 48px;

        md-icon {
            margin: 10px 16px 0 0;
        }

        .title {
            font-size: 13px;
            font-weight: 500;
            line-height: 1;
        }
    }

    .subheader {
        font-size: 13px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        font-weight: 500;
        margin-top: 8px;

        &.light {
            border-top: 1px solid rgba(255, 255, 255, 0.12);
        }
    }

    md-divider {
        margin: 8px 0;
    }
}
