tr.k-state-selected {
    background-color: #4F62CE !important;
    &.ach-disabled {
        background-color: #ff6861 !important;
    }
    > td {
        border-color: #FAFAFA !important;
    }
    // &:hover td {
    //     background-color: #4F62CE !important;
    // }
    input {
        color: #fff;
    }
}

#paymentGrid {
  td {
    padding: 0.2em 1em
  }
  tr.k-footer-template {
    td {
      padding: 1.4em;
    }
  }
}

.k-grid .k-hierarchy-col {
  width: 4px;
}

.new-header {
  background-color: #EAEAEA;
  .header-top {
    padding: 25px;
    .title {
      font-size: 1.5em;
      font-weight: 500;
      color: #717171;
    }
  }
}

.header-box {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: #FAFAFA;
  color: #717171;
  &.notice {
    background: rgb(63, 81, 181);
    color: #fff;
  }
  &.alert {
      background: rgb(255, 104, 97);
      color: #fff;
  }
  input {
    margin: 0 20px;
    width: calc(100% - 60px);
    border: none;
    &:focus, &:active {
      outline: none;
      border: none;
    }
  }
}
