#auth-layout {
    height: 100vh;
    .auth-content {
        background-image: linear-gradient(rgba(70, 70, 70, 0.85), rgba(70, 70, 70, 0.85)), url("../img/Background/2.jpg");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/Background/1.jpg', sizingMethod='scale');
        -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/Background/1.jpg', sizingMethod='scale')";
        // -webkit-filter: blur(3px);
        // -moz-filter: blur(3px);
        // -o-filter: blur(3px);
        // -ms-filter: blur(3px);
        // filter: blur(3px);
        .auth-inner-content {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHElEQVQIW2MMDwv/v3LVSkYGKIAzMARgKjFUAABVFQgFSoCMxAAAAABJRU5ErkJggg==) repeat;
            height: 100vh;
            div {
                padding: 2rem 0 0 2rem;
                .logo {
                    font-size: 4rem;
                }
            }
        }
    }

    .auth-form {
        overflow-y: auto;
        max-width: 600px;
        padding: 0 2rem;
        button {
            width: 96%;
        }
        // Kendo DatePicker
        .k-datepicker {
            width: 100%;
            margin-bottom: 15px;
        }
        .k-datepicker .k-input, .k-timepicker .k-input {
            background-color: #eee;
        }
        .k-autocomplete.k-state-default, .k-picker-wrap.k-state-default, .k-numeric-wrap.k-state-default, .k-dropdown-wrap.k-state-default {
            background-color: #eee;
            border-color: #d2d2d2;
        }
        .k-picker-wrap.k-state-default > .k-select {
            border-color: #d2d2d2;
        }
        .k-picker-wrap {
            border-radius: 0;
        }
    }
}

@media screen and (max-width: 955px) {
    #auth-layout {
        .auth-form {
            width: 100%;
            max-width: 100%;
        }
    }
}


.auth-options-list {
    a {
        padding: 0 4px;
    }
}
