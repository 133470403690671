.MicrosoftMap .NavBar_DropDown {
    width: 250px;
}

.MicrosoftMap .NavBar_Container .slot {
    height: 50px;
}

.MicrosoftMap .NavBar_Container .slot .icon {
    width: 50px;
    height: 50px;
}

.MicrosoftMap .NavBar_Container .slot .pie-label, .MicrosoftMap .NavBar_Container .streetside .pie-label {
    left: 80px;
    margin-top: 5px;
}

.MicrosoftMap .NavBar_Container .slot .pie-label-description, .MicrosoftMap .NavBar_Container .streetside .pie-label-description {
    left: 80px;
    margin-top: 25px;
}

 @media (min-width: 960px) {
    #tax-details > div {
        width: 30%;
        max-width: 30em;
    }
}