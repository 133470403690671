.ms-stepper {

    .ms-stepper-horizontal {

        .ms-stepper-steps {
            span.required {
                font-size: 0.7rem;
            }
            md-input-container {
                font-size: 1rem;
            }
        }

        .ms-stepper-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 16px;

            span {
                width: 5px;
                height: 5px;
                margin: 0 4px;
                border-radius: 50%;

                &:not(.md-accent-bg) {
                    background: rgba(0, 0, 0, 0.12);
                }

                &.selected {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}
