// Honeypot
#fd5abc72fce369955a6ecf185bf6d54a {
  position: fixed;
  left: -999999em;
}

.form-label {
  font-size: 0.9rem;
   color: rgba(0, 0, 0, 0.55);
   margin-bottom: 4px;
}

.date-picker-label {
  padding-left: 15px !important;
  bottom: 31px !important;
}

.password-unlock {
  float: right;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 10px;
  color: rgba(0, 0, 0, 0.55);
}
